import { useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Skeleton } from "primereact/skeleton";
import { getFormattedString } from "./CommonFunctions";
import { Dialog } from "primereact/dialog";

let monthListArray = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
]


export const srBodyTemplate = (rowData, props) => { return (<><span className="p-column-title">Sr. No.</span>{props.rowIndex + 1}</>) };
export const CommonBodyForTable = (data, props) => (<> <span className="p-column-title">{props.header}</span>{data[props.field] ? data[props.field] : 'NA'} </>)
export const NumberCommonBodyForTable = (data, props) => (<> <span className="p-column-title">{props.header}</span>{data[props.field] ? data[props.field] : '0'} </>)

export const CommonBodyForFormattedStrTable = (data, props) => (<> <span className="p-column-title">{props.header}</span>{data[props.field] ? getFormattedString(data[props.field]) : 'NA'} </>)

export const DateBodyForTable = (data, props) => {
    const convertedDate = new Date(data[props.field]).toLocaleString('en-IN');
    console.log('convertedDate', convertedDate, data[props.field]);
    return (<> <span className="p-column-title">{props.header}</span>{convertedDate ? convertedDate : 'NA'} </>)
}

export const DateMonthBodyForTable = (data, props) => {
    const convertedDate = new Date(data[props.field]).toLocaleDateString();
    return (<> <span className="p-column-title">{props.header}</span>{convertedDate ? convertedDate : 'NA'} </>)
}

export const statusBodyTemplate = (rowData, props) => {
    const status = rowData[props.field]?.replaceAll("_", " ");
    return (<> <span className="p-column-title">{props.header}</span>
        {status ? (typeof (status) == 'string' ? getFormattedString(status) : status) : '-'}
    </>)
}

export const orderStatusBodyTemplate = (rowData, props) => {
    const status = rowData[props.field]?.replaceAll("ORDER_", " ");
    return (<> <span className="p-column-title">{props.header}</span>
        {status ? (typeof (status) == 'string' ? getFormattedString(status) : status) : '-'}
    </>)
}


export const BoolenBodyForTable = (rowData, props) => {
    return (
        <>
            <span className="p-column-title">{props.header}</span>
            {rowData[props.field] ? <i className="pi pi-check p-success" > </i> : <i className="pi pi-times p-error"></i>}
        </>
    )
}

export const amountBodyForTable = (data, props) => (<> <span className="p-column-title">{props.header}</span>{data[props.field] ? Number(data[props.field])?.toFixed(2) : 'NA'} </>)

export const nestedBodyForTable = (data, props) => {
    const arr = props.field.split('.')
    const status = data[arr[0]]?.[arr[1]];

    return (
        <>
            <span className="p-column-title">{props.header}</span>{status ? getFormattedString(status) : 'NA'}
        </>
    )
}

export const nestedDateBodyForTable = (data, props) => {
    const arr = props.field.split('.')
    const date = data[arr[0]]?.[arr[1]];
    const convertedDate = new Date(date).toLocaleString('en-IN');
    return (
        <>
            <span className="p-column-title">{props.header}</span>{convertedDate ? convertedDate : 'NA'}
        </>
    )
}

export const nestedDateBodyForTable1 = (data, props) => {
    const arr = props.field.split('.')
    const date = data[arr[0]]?.[arr[1]];
    // const convertedDate = new Date(date).toLocaleDateString();
    const convertedDate = `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}`
    return (
        <>
            <span className="p-column-title">{props.header}</span>{convertedDate ? convertedDate : 'NA'}
        </>
    )
}

export const brandBodyForTable = (data, props) => {
    const brandLookup = { 'TAPRI': 'Tapri Kiosk', 'TKAFFE': 'Tkaffe Kiosk', 'MERI_TAPRI': 'Tapri Kettle' };
    return (<> <span className="p-column-title">{props.header}</span>{brandLookup[data[props.field]] || ''} </>)
}


export const commonDialogFooter = (closeLabel, closeCall, okLabel, okCall, isOk = true) => (
    <>
        <Button label={closeLabel} icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={closeCall} />
        {isOk && <Button label={okLabel} icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={okCall} />}
    </>
);

export const commonFormDialogFooter = (closeLabel, closeCall, okLabel, formid, isOk = true) => (
    <>
        <Button label={closeLabel} icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={closeCall} />
        {isOk && <Button label={okLabel} icon="pi pi-check" type='submit' form={formid} className="p-button-success p-mr-2 p-mb-2" />}
    </>
);

export const commonCloseDialogFooter = (closeLabel, closeCall) => (
    <>
        <Button label={closeLabel} icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={closeCall} />
    </>
);

export const commonConfirmDialogFooter = (confirmLabel, confirmCall) => (
    <>
        <Button label={confirmLabel} icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={confirmCall} />
    </>
);

export const OverviewSkeleton = () => (
    <div className='card'>
        <div className='p-grid'>
            <div className='p-col-3'>
                <Skeleton height="50rem" className="mb-2" borderRadius="16px"></Skeleton>
            </div>
            <div className='p-col-3'>
                <Skeleton height="50rem" className="mb-2" borderRadius="16px"></Skeleton>
            </div>
            <div className='p-col-3'>
                <Skeleton height="50rem" className="mb-2" borderRadius="16px"></Skeleton>
            </div>
            <div className='p-col-3'>
                <Skeleton height="50rem" className="mb-2" borderRadius="16px"></Skeleton>
            </div>
        </div>
    </div>
)

export const MachineManagerDataTableSkeleton = () => {
    const items = Array.from({ length: 10 }, (v, i) => i);

    const bodyTemplate = () => {
        return <Skeleton height="2rem"></Skeleton>
    }

    const roundedSqBodyTemplate = () => {
        return <Skeleton height="2rem" borderRadius="50px"></Skeleton>
    }

    const circleActionBodyTemplate = () => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                </div>
            </>
        )
    }

    return (

        <div className="card">
            <div className="p-grid">
                <div className="p-col-2">
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
                <div className="p-col-8"></div>
                <div className="p-col-2" style={{ display: 'flex' }}>
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-mt-3 p-ml-2"></Skeleton>
                </div>
            </div>
            <hr></hr>
            <DataTable value={items} className="p-datatable-striped p-datatable p-component datatable-responsive">
                <Column header="Sr.No." body={bodyTemplate} style={{ width: '4rem' }}></Column>
                <Column header="CVES ID" body={roundedSqBodyTemplate}></Column>
                <Column header="Subscription ID" body={bodyTemplate}></Column>
                <Column header="Account Name" body={bodyTemplate}></Column>
                <Column header="Account ID" body={bodyTemplate}></Column>
                <Column header="Assigned under" body={bodyTemplate}></Column>
                <Column header="Stage (T/FG)" body={bodyTemplate}></Column>
                <Column header="Brand" body={bodyTemplate}></Column>
                <Column header="Operate Mode" body={bodyTemplate}></Column>
                <Column header="Model" body={bodyTemplate}></Column>
                <Column header="Version" body={bodyTemplate}></Column>
                <Column header="Canisters" body={bodyTemplate}></Column>
                <Column header="Actions" body={circleActionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
}


export const MachineOnboardDataTableSkeleton = () => {
    const items = Array.from({ length: 10 }, (v, i) => i);

    const bodyTemplate = () => {
        return <Skeleton height="2rem"></Skeleton>
    }

    const roundedSqBodyTemplate = () => {
        return <Skeleton height="2rem" borderRadius="50px"></Skeleton>
    }

    const circleActionBodyTemplate = () => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                </div>
            </>
        )
    }


    return (

        <div className="card">
            <div className="p-grid">
                <div className="p-col-1">
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
                <div className="p-col-1">
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
                <div className="p-col-8"></div>
                <div className="p-col-2" style={{ display: 'flex' }}>
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-mt-3 p-ml-2"></Skeleton>
                </div>
            </div>

            <hr></hr>

            {/* <Skeleton className="p-mb-2" height="4rem"></Skeleton> */}
            <DataTable value={items} className="p-datatable-striped p-datatable p-component datatable-responsive">
                <Column header="Sr.No." body={bodyTemplate}></Column>
                <Column header="CVES ID" body={roundedSqBodyTemplate}></Column>
                <Column header="Subscription ID" body={bodyTemplate}></Column>
                <Column header="Account Name" body={bodyTemplate}></Column>
                <Column header="Account ID" body={bodyTemplate}></Column>
                <Column header="Canister's" body={bodyTemplate}></Column>
                <Column header="Mode" body={bodyTemplate}></Column>
                <Column header="Category / Model" body={bodyTemplate}></Column>
                <Column header="Facility" body={bodyTemplate}></Column>
                <Column header="Facility ID" body={bodyTemplate}></Column>
                <Column header="KAM" body={bodyTemplate}></Column>
                <Column header="Location" body={bodyTemplate}></Column>
                <Column header="Status" body={bodyTemplate}></Column>
                <Column header="Actions" body={circleActionBodyTemplate}></Column>
            </DataTable>
        </div>

    );
}

export const UsersDataTableSkeleton = () => {
    const items = Array.from({ length: 10 }, (v, i) => i);

    const bodyTemplate = () => {
        return <Skeleton height="2rem"></Skeleton>
    }

    const profileBodyTemplate = () => {
        return <Skeleton shape="circle" size="3rem" className="p-m-1"></Skeleton>
    }

    const circleActionBodyTemplate = () => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    {/* <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton> */}
                </div>
            </>
        )
    }

    return (

        <div className="card">
            <div className="p-grid">
                <div className="p-col-1">
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
                <div className="p-col-10"></div>
                <div className="p-col-1" style={{ display: 'flex' }}>
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
            </div>

            <hr></hr>

            <DataTable value={items} className="p-datatable-striped p-datatable p-component datatable-responsive">
                <Column header="Sr.No." body={bodyTemplate} style={{ width: '6rem' }}></Column>
                <Column header="Profile" body={profileBodyTemplate} style={{ width: '8rem' }}></Column>
                <Column header="Account Name" body={bodyTemplate}></Column>
                <Column header="Name" body={bodyTemplate}></Column>
                <Column header="Account ID" body={bodyTemplate}></Column>
                <Column header="User ID" body={bodyTemplate}></Column>
                <Column header="User Type" body={bodyTemplate}></Column>
                <Column header="Contact" body={bodyTemplate}></Column>
                {/* <Column  header="Comapny Name" body={bodyTemplate}></Column> */}
                <Column header="Address" body={bodyTemplate}></Column>
                <Column header="Actions" body={circleActionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
}


export const CtvmOrdersSkeleton = () => {
    const items = Array.from({ length: 10 }, (v, i) => i);

    const bodyTemplate = () => {
        return <Skeleton height="2rem"></Skeleton>
    }

    const profileBodyTemplate = () => {
        return <Skeleton shape="circle" size="3rem" className="p-m-1"></Skeleton>
    }

    const circleActionBodyTemplate = () => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                </div>
            </>
        )
    }

    return (

        <div className="card">
            <div className="p-grid">
                {/* <div className="p-col-1"> */}
                {/* <Skeleton className="p-mb-2" height="4rem"></Skeleton> */}
                {/* </div> */}
                <div className="p-col-10"></div>
                <div className="p-col-2" style={{ display: 'flex' }}>
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-mt-3 p-ml-2"></Skeleton>
                </div>
            </div>

            <hr></hr>

            <DataTable value={items} className="p-datatable-striped p-datatable p-component datatable-responsive">
                <Column header="Sr.No." body={bodyTemplate} style={{ width: '6rem' }}></Column>
                <Column header="Order Id" body={bodyTemplate}></Column>
                <Column header="Sales Order Id" body={bodyTemplate}></Column>
                <Column header="Category/Brand" body={bodyTemplate}></Column>
                <Column header="Canister" body={bodyTemplate}></Column>
                <Column header="Comapny Name" body={bodyTemplate}></Column>
                <Column header="Lead/Account" body={bodyTemplate}></Column>
                <Column header="Sunscription Type" body={bodyTemplate}></Column>
                <Column header="User Type" body={bodyTemplate}></Column>
                <Column header="Cup Price" body={bodyTemplate}></Column>
                <Column header="Date" body={bodyTemplate}></Column>
                <Column header="Actions" body={circleActionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
}


export const useDynamicPageTitle = (pageTitle) => {
    useEffect(() => {

        const label = window.location.hash;
        console.log(window.location.hash);
        const changed = label.replace("#/", "");
        console.log(changed);
        const again = changed.replace("-", " ");
        document.title = again;
        return () => {
            document.title = 'Cherise Global | CVES Dashboard';
        };
    }, [pageTitle]);
};


export const SubscriptionPlanSkeleton = () => {
    const items = Array.from({ length: 10 }, (v, i) => i);

    const bodyTemplate = () => {
        return <Skeleton height="2rem"></Skeleton>
    }

    const circleActionBodyTemplate = () => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                </div>
            </>
        )
    }

    return (
        <div className="card">
            <div className="p-grid">
                <div className="p-col-1">
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
                <div className="p-col-10"></div>
                <div className="p-col-1" style={{ display: 'flex' }}>
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
            </div>
            <hr></hr>
            <DataTable value={items} className="p-datatable-striped p-datatable p-component datatable-responsive">
                <Column header="Sr.No." body={bodyTemplate} style={{ width: '6rem' }}></Column>
                <Column header="Subscription Name" body={bodyTemplate} style={{ width: '8rem' }}></Column>
                <Column header="Status" body={bodyTemplate}></Column>
                <Column header="Actions" body={circleActionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
}

export const OwnershipPlanSkeleton = () => {
    const items = Array.from({ length: 10 }, (v, i) => i);

    const bodyTemplate = () => {
        return <Skeleton height="2rem"></Skeleton>
    }

    const circleActionBodyTemplate = () => {
        return (
            <>
                <div className="actions" style={{ display: "flex" }}>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                    <Skeleton shape="circle" size="2rem" className="p-m-1"></Skeleton>
                </div>
            </>
        )
    }

    return (

        <div className="card">
            <div className="p-grid">
                <div className="p-col-1">
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
                <div className="p-col-10"></div>
                <div className="p-col-1" style={{ display: 'flex' }}>
                    <Skeleton className="p-mb-2" height="4rem"></Skeleton>
                </div>
            </div>

            <hr></hr>

            <DataTable value={items} className="p-datatable-striped p-datatable p-component datatable-responsive">
                <Column header="Sr.No." body={bodyTemplate} style={{ width: '6rem' }}></Column>
                <Column header="Machine Name" body={bodyTemplate} style={{ width: '8rem' }}></Column>
                <Column header="Brand" body={bodyTemplate}></Column>
                <Column header="Status" body={bodyTemplate}></Column>
                <Column header="Actions" body={circleActionBodyTemplate}></Column>
            </DataTable>
        </div>
    );
}


export const CommonConfirmationDialog = ({ dialogState, dialogFooter, hideDialog, msg }) => {

    return (
        <>
            <Dialog visible={dialogState} style={{ width: '450px' }} header="Confirm" modal footer={dialogFooter} onHide={hideDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-my-3 " style={{ fontSize: '4rem' }} /><br />
                        <b><span>{msg}</span></b><br></br>
                    </center>
                </div>
            </Dialog>
        </>
    )
}

export const LoadingComponent = () => {
    return (
        <>
            <center>
                <small>Loading...</small>
            </center>
        </>
    )
}


