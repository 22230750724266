import React, { useEffect } from 'react';
import { getFormattedAmount, getFormattedStatForAnalytics } from "../Common/CommonFunctions";

import { SubAnalytics } from '../Analytics/SubAnalytics';


export const DemoMISStats = (props) => {


    const UIComponent = (header, value, icon, loader, format = false) => {

        return (

            <div className="p-col-12 p-md-4">
                <div className="card widget-overview-box widget-overview-box-1">
                    <span className="overview-title">
                        {header}
                    </span>
                    <div className="p-d-flex p-jc-between">
                        <div className="overview-detail p-d-flex p-jc-between">
                            <div className="overview-text">
                                {
                                    loader ?
                                        <b><i className="pi pi-spin pi-spinner" /></b> :
                                        <b>{format ? getFormattedStatForAnalytics(value) : value}</b>
                                }
                            </div>
                        </div>
                    </div>
                    <i className={`${icon} side-icon`} style={{ fontSize: '2rem' }}></i>
                </div>
            </div>
        )
    }


    return (
        <>
            {UIComponent("No. Of Distributors", props?.ioStats?.partnerCount, 'pi pi-user', props?.ioStats?.partnerCount ? false : true, true)}

            {UIComponent("No of CVES Accounts", props?.ioStats?.accountCount, 'pi pi-user', props?.ioStats?.partnerCount ? false : true, true)}

            {UIComponent("No of CVES's", props?.onboardMachines?.length, 'pi pi-sitemap', props?.onboardMachines?.length ? false : true, true)}

            {UIComponent("Number Of Cups Sold (till date)", props?.totalCupCount, 'pi pi-slack', props?.totalCupCount ? false : true, true)}

            {UIComponent("Average Per Cup Sale Cost", 'INR 6.01 + GST', 'pi pi-wallet', props?.totalCupCount ? false : true)}

            {UIComponent("All Time Favourite Flavor", props?.superStarValue, 'pi pi-star-o', props?.totalCupCount ? false : true)}


            {/* <UIComponent header="No. Of Distributors" value={props?.ioStats?.partnerCount ? props?.ioStats?.partnerCount : '-'} icon="pi pi-user" loader={ props?.ioStats?.partnerCount ? false: true } /> */}
            {/* <UIComponent header="No of CVES Accounts" value={props?.ioStats?.accountCount ? props?.ioStats?.accountCount : '-'} icon="pi pi-th-large" loader={
                props?.ioStats?.accountCount ? false: true
            } />
            <UIComponent header="No of CVES's" value={props.onboardMachines?.length ? props.onboardMachines?.length : '-'} icon="pi pi-sitemap" />
            <UIComponent header="Number Of Cups Sold (till date)" value={props.totalCupCount ? props.totalCupCount : '-'} icon="pi pi-slack" />
            <UIComponent header="Average Per Cup Sale Cost" value="INR 6.01 + GST" icon="pi pi-wallet" />
            <UIComponent header="All Time Favourite Flavor" value="MADRAS COFFEE" icon="pi pi-star-o" /> */}
        </>
    )
}

