// Function to generate a random number based on a fixed seed


export const usageCalculation = (numMachines, totalCups, month = null) => {
    function seededRandom(seed) {
        const x = Math.sin(seed++) * 10000;
        return x - Math.floor(x);
    }


    // Set a fixed seed for reproducibility
    let seed = 50;

    // Generate random dispenses for each machine
    let dispenses = [];
    let sum = 0;


    let avgCount = Math.floor(totalCups / numMachines)
    let MaxCount = Math.floor(avgCount * 1.6) > 8000 ? Math.floor(avgCount / 2.25) : Math.floor(avgCount)
    let MinCount = Math.floor(avgCount * 1.6) > 8000 ? (avgCount * 0.1) : (avgCount * 0.3)

    if (month == 'Mar-2021') {
        seed = 51;
        MaxCount = Math.floor(avgCount) >= 7800 ? Math.floor(avgCount * 2.74) : Math.floor(avgCount * 2);
        MinCount = Math.floor(avgCount * 0.1);
    }

    // Generate random values between 100 and 200 for each machine


    console.log('AvgCount', avgCount, 'MaxCount', (avgCount * 1.6), 'MaxCount Updated', MaxCount, 'MinCount', (avgCount * 0.3), 'MinCount Updated', MinCount)

    for (let i = 0; i < numMachines; i++) {
        let randomValue = Math.floor(seededRandom(seed + i) * MaxCount) + Math.floor(MinCount);
        dispenses.push(randomValue);
        sum += randomValue;
    }

    // Calculate the difference between the current sum and the total cups
    let difference = totalCups - sum;

    // Evenly distribute the difference across machines
    let adjustment = Math.floor(difference / numMachines);
    for (let i = 0; i < numMachines; i++) {
        dispenses[i] += adjustment;
    }

    // Handle any remaining difference
    let remainingDifference = difference % numMachines;
    for (let i = 0; i < remainingDifference; i++) {
        dispenses[i]++;
    }

    // Verify the total


    let finalSum = dispenses.reduce((a, b) => a + b, 0);

    // Output the dispenses and the total


    console.log("Dispenses per machine:", dispenses);
    console.log("Actual total", totalCups, "Total dispense count:", finalSum);





    if (finalSum !== totalCups) {

        let lastDifferance = totalCups - finalSum;

        console.log('lastDifferance', lastDifferance);

        if (lastDifferance > 0) {
            dispenses[0] += lastDifferance;
        } else {
            dispenses[0] -= lastDifferance;
        }


        let lastestSum = dispenses.reduce((a, b) => a + b, 0);

        console.log("Updated Dispenses per machine:", dispenses, lastestSum);

        return dispenses;

    } else {
        return dispenses;
    }

}

