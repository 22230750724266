import React, { useState, useEffect, useRef, version } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';
import { ProgressBar } from 'primereact/progressbar';
import { CommonBodyForTable, DateBodyForTable, srBodyTemplate } from '../Common/CommonComponents';
import { successToast, failureToast } from '../Common/CommonFunctions';

import ApiServices from '../../service/api/ApiServices';
import { width } from '@fortawesome/free-regular-svg-icons/faAddressBook';
import e from 'cors';
import { InputNumber } from 'primereact/inputnumber';
const apiServices = new ApiServices();

// ================================================================================
// ================================================================================

export const CvesAnalytics = () => {

    const [loading, setLoading] = useState(true);
    const [cvesMisData, setCvesMisData] = useState(null);
    const [addMISReport, setAddMISReport] = useState(false);
    const [selectedMisData, setSelectedMisData] = useState({});
    const [comfirmDelete, setComfirmDelete] = useState(false);

    const [createMISReport, setCreateMISReport] = useState({
        avg_cup_price: "",
        no_of_accounts: "",
        no_of_distributors: "",
        distributor_list: [],
        max_ctvm_deployed: "",
        top_cup_consumption: "",
        highest_target_performance: "",
        max_inventory_holding: "",
    });
    const [distributorDialog, setDistributorDialog] = useState(false);

    const [singleDistributor, setsingleDistributor] = useState({ distributor_name: null, cup_percent: null });


    const toast = useRef(null);

    useEffect(() => {
        getData({})
    }, [])


    useEffect(() => {
        console.log('Create MIS', createMISReport)
    }, [createMISReport])

    useEffect(() => {
        console.log('singleDistributor', singleDistributor);
    }, [singleDistributor])

    const getData = async (data) => {
        const result = await apiServices.getMISReport();
        console.log(result?.data?.data);
        setCvesMisData(result?.data?.data || []);
        setLoading(false)
    }

    const handleChange = (e) => {
        setCreateMISReport((state) => {
            return {
                ...state,
                [e.target.id]: e.target.value
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Data', createMISReport);
    }

    const openCreateMISDialog = () => {
        setAddMISReport(true);
        setCreateMISReport({})
    }



    const confirmDelete = (data) => {
        setComfirmDelete(true)
        setSelectedMisData(data);
    }

    const deleteVersion = async () => {
        const data = {
            filename: selectedMisData.filename,
            type: selectedMisData.devicetype,
            model: selectedMisData.devicemodel
        }
        console.log(data);
        const response = await apiServices.deleteCvesVersion(data);
        console.log("response", response);
        if (response?.data?.type) {
            successToast(toast, 'Version Deleted Successfully')
            setComfirmDelete(false);
            setSelectedMisData(null);
            getData();
        } else {
            failureToast(toast, 'Failed to Delete')
            setComfirmDelete(false);
            setSelectedMisData(null);
        }
    }

    const htmlForTable = (data, props) => (
        <>
            <span className="p-column-title">{props.header}</span>
            <div dangerouslySetInnerHTML={{ __html: data[props.field] }} />
        </>
    )

    const actionBodyTemplate = (rowData) => (
        <>
            <span className="p-column-title">Action</span>
            <Button icon="pi pi-trash" title="Delete" className="p-button-rounded p-button-danger" onClick={() => { confirmDelete(rowData) }} />
        </>
    )

    const rightToolbarTemplate = () => (<Button className='p-custom-btn' label="Create MIS Record" icon="pi pi-plus-circle" iconPos="left" onClick={() => { openCreateMISDialog() }} />)

    // const leftToolbarTemplate = () => ()

    const addMISDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={() => { setAddMISReport(false) }} />
            <Button label="Submit" type="submit" form='upload-version' icon="pi pi-plus" className="p-button-success" />
        </>
    );

    const deleteDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-success p-mr-2 p-mb-2" onClick={() => { setComfirmDelete(false) }} />
            <Button label="Delete" icon="pi pi-trash" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { deleteVersion() }} />
        </>
    );

    const distributorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={() => { setDistributorDialog(false) }} />
            <Button label="Save" icon="pi pi-check" className="p-button-success p-mr-2 p-mb-2" onClick={() => { setDistributorDialog(false) }} />
        </>
    );


    const handleDistributorChange = (e) => {
        const name = e.target.name, value = e.target.value;
        setsingleDistributor((state) => {
            return {
                ...state,
                [name]: value
            }
        })

    }

    const handleDistributorSubmit = async (e) => {
        e.preventDefault();
        console.log('Distributor Submit');
        const distributor_list = [...createMISReport?.distributor_list, singleDistributor];
        distributor_list.push(singleDistributor);
        console.log('distributor_list', distributor_list);
        const percent = await distributor_list.map(x => x.cup_percent);
        const total = await percent.reduce((current, accumlator) => {
            const total = Number(current) + Number(accumlator);
            return total;
        }, 0)
        console.log('total', total);
        if (total >= 100) {
            failureToast(toast, 'Total Percent Count is Exceeding 100%.');
            return;
        } else {
            console.log('IN ELSE')
            if (total == 100 && distributor_list.length != 10) {
                failureToast(toast, 'Atleast 10 Distributor Should be Added.');
                return;
            }
            setCreateMISReport(state => {
                console.log('state', state);
                return { ...state, distributor_list }
            })
            setsingleDistributor(null);
        }


    }




    return (
        <>
            <Toast ref={toast} position='center' />
            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <DataTable value={cvesMisData}
                            dataKey="id" paginator rows={10} className="datatable-responsive"
                            emptyMessage="No data found." loading={loading}>
                            <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false} style={{ width: "5rem" }}  ></Column>

                            <Column field="avg_cup_price" header="Average Cup Price" body={CommonBodyForTable} style={{ width: "12rem" }} ></Column>

                            <Column field="no_of_accounts" header="Number Of Accounts" body={CommonBodyForTable} style={{ width: "15rem" }} ></Column>

                            <Column field="no_of_distributors" header="Number Of Distributors" body={CommonBodyForTable} style={{ width: "15rem" }} ></Column>

                            <Column field="max_ctvm_deployed" header="Maximun CTVM Deployed Account" body={CommonBodyForTable} style={{ width: "15rem" }} ></Column>

                            <Column field="top_cup_consumption" header="Top Cup Consumption Account" body={CommonBodyForTable} style={{ width: "15rem" }}></Column>

                            <Column field="highest_target_performance" header="Highest Target Performance Account" body={CommonBodyForTable} style={{ width: "15rem" }}></Column>

                            <Column field="max_inventory_holding" header="Maximum Inventory Holding Account" body={CommonBodyForTable} style={{ width: "15rem" }}></Column>

                            <Column header="Action" body={actionBodyTemplate}></Column>

                            {/* <Column field="no_of_distributors" header="Number Of Distributors" sortable body={CommonBodyForTable}></Column> */}

                        </DataTable>
                    </div>
                </div>
            </div>

            <Dialog visible={addMISReport} style={{ width: '950px' }} header='Create MIS Record' modal footer={addMISDialogFooter} onHide={() => { setAddMISReport(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={handleSubmit} id='upload-version' className="p-fluid">
                    <div className='p-grid'>

                        <div className='p-col-12'>

                            <div className="p-field">
                                <label>Average Cup Price</label>
                                <InputText type="text" name='avg_cup_price' placeholder='Please Enter Average Cup Price' maxLength={2} onChange={handleChange} autoFocus required autoComplete='off' />
                            </div>

                            <div className="p-field">
                                <label>Number Of Accounts</label>
                                <InputText type="text" name='no_of_accounts' placeholder='Please Enter Average Number Of Accounts' maxLength={4} onChange={handleChange} autoFocus required autoComplete='off' />
                            </div>

                            <div className="p-field">
                                <label>Number Of Distributors</label>
                                <InputText type="text" name='no_of_distributors' placeholder='Please Enter Number Of Distributors' maxLength={3} onChange={handleChange} autoFocus required autoComplete='off' />
                            </div>

                            <div className="p-field">
                                <label>Maximun CTVM Deployed Account</label>
                                <InputText type="text" name='max_ctvm_deployed' placeholder='Please Enter Maximun CTVM Deployed Account' onChange={handleChange} autoFocus required autoComplete='off' />
                            </div>

                            <div className="p-field">
                                <label>Top Cup Consumption Account</label>
                                <InputText type="text" name='top_cup_consumption' placeholder='Please Enter Top Cup Consumption Account' onChange={handleChange} autoFocus required autoComplete='off' />
                            </div>

                            <div className="p-field">
                                <label>Highest Target Performance Account</label>
                                <InputText type="text" name='highest_target_performance' placeholder='Please Enter Highest Target Performance Account' onChange={handleChange} autoFocus required autoComplete='off' />
                            </div>

                            <div className="p-field">
                                <label>Maximum Inventory Holding Account</label>
                                <InputText type="text" name='max_inventory_holding' placeholder='Please Enter Maximum Inventory Holding Account' onChange={handleChange} autoFocus required autoComplete='off' />
                            </div>

                            <Button onClick={() => setDistributorDialog(true)} label="Add DIstributor List" className="p-button-success p-mr-2 p-mb-2" />

                        </div>
                    </div>
                </form>
            </Dialog>

            <Dialog visible={comfirmDelete} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={() => { setComfirmDelete(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-2'><b>Are you sure you want to delete the selected Version?</b></span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={distributorDialog} style={{ width: '800px' }} header="Distributor List" modal footer={distributorDialogFooter} onHide={() => { setDistributorDialog(false) }}>
                <div className="p-grid">
                    <div className="p-field p-m-2 p-w-2">
                        <InputText type="text" name='distributor_name' placeholder='Please Enter Distributor Name' value={singleDistributor?.distributor_name || ''} style={{ width: "300px" }} onChange={handleDistributorChange} autoFocus required autoComplete='off' />
                    </div>
                    <div className="p-field p-m-2">
                        <InputText type="text" name='cup_percent' placeholder='Please Enter Cup Percentage' maxLength={2} value={singleDistributor?.cup_percent || ''} style={{ width: "300px" }} onChange={handleDistributorChange} autoFocus required autoComplete='off' />
                    </div>

                    <Button label="Add" className="p-button-success p-m-2" style={{ fontSize: '1rem', width: "100px" }} onClick={handleDistributorSubmit} />

                    <div className="p-grid crud-demo">
                        <div className="p-col-12">
                            <div className="card">
                                <DataTable value={createMISReport.distributor_list}
                                    dataKey="id" paginator rows={10} className="datatable-responsive"
                                    emptyMessage="No data found.">
                                    <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false} ></Column>
                                    <Column field="distributor_name" header="Distributor Name" body={CommonBodyForTable} exportable={false} ></Column>
                                    <Column field="cup_percent" header="Cup Percentage" body={CommonBodyForTable} exportable={false} ></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}
