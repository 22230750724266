import React from 'react';
import { Overview } from '../components/Maindashboard/Overview';
import { CvesManager } from '../components/Maindashboard/CvesManager';
import { CtvmOnBoard } from '../components/Maindashboard/CtvmOnBoard';
import { CvesOnBoard } from '../components/Maindashboard/CvesOnBoard';
import { CtvmOrders } from '../components/Maindashboard/CtvmOrders';
import { EmployeeMain } from '../components/Maindashboard/EmployeeMain';
import { UsersMain } from '../components/Users/UsersMain';
import { Inventory } from '../components/Inventory/Inventory';
import { CustomerInventory } from '../components/Inventory/CustomerInventory';
import { Performance } from '../components/Performance/Performance';
import { KAMPerformance } from '../components/Performance/KAMPerformance';
import { Transactions } from '../components/Transactions/Transactions';
import { MISReport } from '../components/MisReports/MISReport';
import { MisReportNew } from '../components/MisReports/MisReportNew';
import { DemoMISReport } from '../components/MisReports/DemoMISReport';
import { CrmAnalytics } from '../components/CRM/CrmAnalytics';
import { InvoicePage } from '../components/Billing/InvoicePage';
import { VersionControl } from '../components/Version/VersionControl';
import { AnalyticsMain } from '../components/NewAnalytics.js/AnalyticsMain';
import { Leads } from '../components/CRM/Leads';
import { Tasks } from '../components/CRM/Tasks';
import ChangeLog from '../service/ChangeLog';
import { Invoice } from '../pages/Invoice';
import { RefillOrder } from '../components/Order/RefillOrder';
import { NewMachineOrder } from '../components/Order/NewMachineOrder';
// Admin
import { DemoStats } from '../components/Admin/DemoStats';
import { FgMachines } from '../components/FinishedGood/FgMachines';
import { FgBeverage } from '../components/FinishedGood/FgBeverage';
import { InventoryCycle } from '../components/Inventory/InventoryCycle';
import { TechnomanTracking } from '../components/More/TechnomanTracking';
import { InventorySetting } from '../components/Inventory/InventorySetting';
import { SubscriptionPackages } from '../components/Inventory/SubscriptionPackages';
import { RawMaterial } from '../components/Inventory/RawMaterials';
import { AdminManager } from '../components/Admin/AdminManager';
import { VendorContacts } from '../components/Inventory/VendorContacts';
import { TicketManager } from '../components/Ticket/TicketManager';
import { TicketAnalytics } from '../components/Ticket/TicketAnalytics';
import { MyOrders } from '../components/Order/MyOrders';
import { RefillOrderDash } from '../components/Order/RefillOrderDash';
import { MachineOrderDash } from '../components/Order/MachineOrderDash';
import { AnalyticsMainDev } from '../components/NewAnalytics.js/AnalyticsMainDev';
import { FaqDetails } from '../components/More/FaqDetails';
import { OrderMaster } from '../components/Order/OrderMaster';
import { Products } from '../components/Order/Products';
import { CtvmOrder } from '../components/Order/CtvmOrder';
import { ReviewOrder } from '../components/Order/ReviewOrder';
import { TrackOrder } from '../components/Order/TrackOrder';
import { DeliveryChallan } from '../components/Admin/DeliveryChallan';
import { FogOverview } from '../components/Fog/FogOverview';
import { Notifications } from '../components/Notifications/Notifications';
import { CVESPredictiveMaintenance } from '../components/CVESMonitor/CVESPredictiveMaintenance';
import { CvesAuditSystem } from '../components/CVESMonitor/CvesAuditSystem';
import { TrainingVideos } from '../components/More/TrainingVideos';
import { CvesActions } from '../components/MachineAction/CvesActions';
import { CvesAnalytics } from '../components/More/CvesAnalytics'
import { SalesTargets } from '../components/More/SalesTargets';
import { AppVersionController } from '../components/More/AppVersionController';
import { PayMerchants } from '../components/More/PayMerchants';
import { CvesVersions } from '../components/More/CvesVersions';

import PMachines from '../components/Mern/PMachines';
import Parent from '../components/ReactPrac/Parent';
import { AnalyticsDemo } from '../components/NewAnalytics.js/AnalyticsDemo';
import { MainCategory } from '../components/support/MainCategory';
import { AllLinksContacts } from '../components/support/AllLinksContacts';
import { EmailSample } from '../components/support/EmailSample';
import { RfidSetup } from '../components/support/RfidSetup';
import { WifiConnect } from '../components/support/WifiConnect';

export const UserTypeRoutes = {

    owner: [
        { path: '/overview', component: Overview },
        { path: '/cves-manager', component: CvesManager },
        // { path: '/ctvm-sites', component: CtvmOnBoard },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/orders', component: CtvmOrders },
        { path: '/employees', component: EmployeeMain },
        { path: '/users', component: UsersMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/inventory', component: Inventory },
        { path: '/customer-inventory', component: CustomerInventory },
        { path: '/performance', component: Performance },
        { path: '/kam-performance', component: KAMPerformance },
        { path: '/transactions', component: Transactions },
        { path: '/mis-report', component: MISReport },
        { path: '/mis-reports', component: MisReportNew },
        { path: '/mis', component: DemoMISReport },
        { path: '/crm-analytics', component: CrmAnalytics },
        { path: '/billing', component: InvoicePage },
        { path: '/change-log', component: VersionControl },
        { path: '/addStats', component: DemoStats },
        { path: '/my-order', component: MyOrders },
        { path: '/fgmachines', component: FgMachines },
        { path: '/fgbeverage', component: FgBeverage },
        { path: '/inventory-cycle', component: InventoryCycle },
        { path: '/technoman-tracking', component: TechnomanTracking },
        { path: '/inventory-setting', component: InventorySetting },
        { path: '/packages', component: SubscriptionPackages },
        { path: '/raw-material', component: RawMaterial },
        { path: '/admin-manager', component: AdminManager },
        { path: '/contacts', component: VendorContacts },
        { path: '/ticket-manager', component: TicketManager },
        { path: '/ticket-analytics', component: TicketAnalytics },
        { path: '/refill-orders', component: RefillOrderDash },
        { path: '/machine-orders', component: MachineOrderDash },
        { path: '/products', component: Products },
        { path: '/ctvm-orders', component: CtvmOrder },
        { path: '/review-order', component: ReviewOrder },
        { path: '/track-order', component: TrackOrder },
        { path: '/delivery-challan', component: DeliveryChallan },
        { path: '/fog-overview', component: FogOverview },
        { path: '/faq-details', component: FaqDetails },
        { path: '/training-videos', component: TrainingVideos },
        { path: '/order-master', component: OrderMaster },
        { path: '/notifications', component: Notifications },
        { path: '/cves-predictive-maintenance', component: CVESPredictiveMaintenance },
        { path: '/audit-system', component: CvesAuditSystem },
        { path: '/cves-actions', component: CvesActions },
        { path: '/cves-analytics', component: CvesAnalytics },
        { path: '/sales-targets', component: SalesTargets },
        { path: '/app-version-control/:version_id?', component: AppVersionController },
        { path: '/merchants', component: PayMerchants },
        { path: '/support-category', component: MainCategory },
        { path: '/all-links', component: AllLinksContacts },
        { path: '/email-sample', component: EmailSample },
        { path: '/rfid-setup', component: RfidSetup },
        { path: '/wifi-connect', component: WifiConnect },
        { path: '/analytics-copy', component: AnalyticsDemo },
        { path: '/cves-versions', component: CvesVersions },
        
        { path: '/practice', component: PMachines },
        { path: '/parent', component: Parent },
    ],
    customer: [
        { path: '/overview', component: Overview },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/my-order', component: MyOrders },
        { path: '/machine-orders', component: MachineOrderDash },
        { path: '/refill-orders', component: RefillOrderDash },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/ticket-manager', component: TicketManager },
        { path: '/inventory', component: Inventory },
        { path: '/transactions', component: Transactions },
        { path: '/billing', component: InvoicePage },
        { path: '/users', component: UsersMain },
        { path: '/notifications', component: Notifications },
    ],
    facility: [
        { path: '/overview', component: Overview },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/transactions', component: Transactions },
        // { path: '/billing', component: InvoicePage },
    ],
    marketmanager: [
        { path: '/overview', component: Overview },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/users', component: UsersMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/performance', component: Performance },
        { path: '/billing', component: InvoicePage },
    ],
    accountmanager: [
        { path: '/overview', component: Overview },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/my-order', component: MyOrders },
        { path: '/refill-orders', component: RefillOrderDash },
        { path: '/machine-orders', component: MachineOrderDash },
        { path: '/users', component: UsersMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/performance', component: Performance },
        { path: '/inventory', component: Inventory },
        { path: '/billing', component: InvoicePage },
    ],
    plantmanager: [
        { path: '/overview', component: Overview },
        { path: '/cves-manager', component: CvesManager },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/orders', component: CtvmOrders },
        { path: '/employees', component: EmployeeMain },
        { path: '/inventory', component: Inventory },
    ],
    operation: [
        { path: '/overview', component: Overview },
        { path: '/cves-manager', component: CvesManager },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/orders', component: CtvmOrders },
        { path: '/refill-orders', component: RefillOrderDash },
        { path: '/my-order', component: MyOrders },
        { path: '/fgbeverage', component: FgBeverage },
        { path: '/inventory', component: Inventory },
        { path: '/inventory-cycle', component: InventoryCycle },
        { path: '/ticket-manager', component: TicketManager },
        { path: '/ticket-analytics', component: TicketAnalytics },
        { path: '/users', component: UsersMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/performance', component: Performance },
        { path: '/transactions', component: Transactions },
        { path: '/mis-report', component: MISReport },
        { path: '/notifications', component: Notifications },
    ],
    superman: [
        { path: '/overview', component: Overview },
        { path: '/cves-manager', component: CvesManager },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/orders', component: CtvmOrders },
        { path: '/refill-orders', component: RefillOrderDash },
        { path: '/my-order', component: MyOrders },
        { path: '/fgbeverage', component: FgBeverage },
        { path: '/inventory', component: Inventory },
        { path: '/inventory-cycle', component: InventoryCycle },
        { path: '/ticket-manager', component: TicketManager },
        { path: '/ticket-analytics', component: TicketAnalytics },
        { path: '/users', component: UsersMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/performance', component: Performance },
        { path: '/transactions', component: Transactions },
        { path: '/mis-report', component: MISReport },
        { path: '/notifications', component: Notifications },
    ],
    techops: [
        { path: '/overview', component: Overview },
        { path: '/cves-manager', component: CvesManager },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/orders', component: CtvmOrders },
        { path: '/refill-orders', component: RefillOrderDash },
        { path: '/my-order', component: MyOrders },
        { path: '/fgbeverage', component: FgBeverage },
        { path: '/inventory', component: Inventory },
        { path: '/inventory-cycle', component: InventoryCycle },
        { path: '/ticket-manager', component: TicketManager },
        { path: '/ticket-analytics', component: TicketAnalytics },
        { path: '/users', component: UsersMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/performance', component: Performance },
        { path: '/transactions', component: Transactions },
        { path: '/mis-report', component: MISReport },
        { path: '/notifications', component: Notifications },
    ],
    distributor: [
        { path: '/overview', component: Overview },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/users', component: UsersMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/transactions', component: Transactions },
        { path: '/billing', component: InvoicePage },
    ],
    techqc: [
        { path: '/overview', component: Overview },
        { path: '/cves-manager', component: CvesManager },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/fgmachines', component: FgMachines },
        { path: '/fgbeverage', component: FgBeverage },
        { path: '/inventory-cycle', component: InventoryCycle },
    ],
    formulation: [
        { path: '/overview', component: Overview },
        { path: '/fgbeverage', component: FgBeverage }
    ],
    dealer: [
        { path: '/overview', component: Overview }
    ],
    finance: [
        { path: '/overview', component: Overview },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/transactions', component: Transactions },
        { path: '/users', component: UsersMain },
    ],
    inventory: [
        { path: '/overview', component: Overview },
        { path: '/fgbeverage', component: FgBeverage },
        { path: '/inventory', component: Inventory },
        { path: '/inventory-cycle', component: InventoryCycle }
    ],
    viewer: [
        { path: '/overview', component: Overview },
        { path: '/cves-manager', component: CvesManager },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/employees', component: EmployeeMain },
        { path: '/orders', component: CtvmOrders },
        { path: '/refill-orders', component: RefillOrderDash },
        { path: '/machine-orders', component: MachineOrderDash },
        { path: '/ticket-manager', component: TicketManager },
        { path: '/ticket-analytics', component: TicketAnalytics },
        { path: '/analytics', component: AnalyticsMainDev },
        { path: '/performance', component: Performance },
        { path: '/kam-performance', component: KAMPerformance },
        { path: '/transactions', component: Transactions },
        { path: '/mis-report', component: MISReport },
    ],
    production: [
        { path: '/overview', component: Overview }
    ],
    invenstor: [
        { path: '/overview', component: Overview },
        // { path: '/cves-manager', component: CvesManager },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/analytics', component: AnalyticsMainDev },
    ],


    fintech: [
        { path: '/overview', component: Overview },
        { path: '/cves-onboard', component: CvesOnBoard },
        { path: '/analytics', component: AnalyticsMainDev },
    ]

}
