import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";

export const SubAnalyticalStat = (props) => {

    const [loadingTime, setloadingTime] = useState(true)

    useEffect(() => {
        setloadingTime(props.loading)
    }, [props.loading])

    return (
        <>
            <div className="p-col-12 p-md-4" onClick={props.clickFunction}>
                <div className="card widget-overview-box widget-overview-box-1">
                    <span className="overview-title">
                        {props.header}
                    </span>
                    <div className="p-jc-between">
                        <div className="overview-detail p-d-flex p-jc-between">
                            <div className="overview-text">
                                {
                                    loadingTime ?
                                        <b><i className="pi pi-spin pi-spinner" /></b> :
                                        <b>{props.value}</b>
                                }
                            </div>
                        </div>
                    </div>
                    <i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>
                </div>
            </div>

        </>
    )
}


export const SubAnalyticalStatForMachineCount = (props) => {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    return (
        <>
            <div className="p-col-12 p-md-4">
                <div className="card widget-overview-box widget-overview-box-1">
                    <span className="overview-title">
                        {props.header}
                    </span>
                    <div className="p-jc-between">
                        <div className="overview-detail p-d-flex p-jc-between">
                            <div className="overview-text p-col-12 p-p-0">
                                {
                                    loading ?
                                        <b><i className="pi pi-spin pi-spinner" /></b> :
                                        <div className="p-d-flex" style={{ justifyContent: 'space-between' }}>
                                            <b>{props.value}</b>
                                            {props.userType == 'fintech' && <Button style={{ backgroundColor: '#DAAB2A', color: 'White' }} icon="pi pi-eye" title='View Detail' label="View Details" className="p-button-rounded p-button-warning p-m-1" onClick={props.clickFunction} />}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    {props.managementCheck && <i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>}
                </div>
            </div>

        </>
    )

}


export const SubAnalyticalStatForTickets = (props) => {

    const [loadingTime, setloadingTime] = useState(true)

    useEffect(() => {
        setloadingTime(props.loading)
    }, [props.loading])

    return (
        <>

            <div className="card widget-overview-box widget-overview-box-1">
                <span className="overview-title">
                    {props.header}
                </span>
                <div className="p-jc-between">
                    <div className="overview-detail p-d-flex p-jc-between">
                        <div className="overview-text">
                            {
                                loadingTime ?
                                    <b><i className="pi pi-spin pi-spinner" /></b> :
                                    <b>{props.value}</b>
                            }
                        </div>
                    </div>
                </div>
                <i className={`${props.icon} side-icon`} style={{ fontSize: '2rem' }}></i>
            </div>


        </>
    )
}