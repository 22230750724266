import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';

//import * as serviceWorker from './serviceWorker';
// const erp_url = process?.env?.REACT_APP_ERP;

// const isLive = process?.env?.REACT_APP_ISLIVE;
// const isLive = true;
// CH-325,CH-349,CH-366,CH-370


const isLive = true;

if(isLive){ 
	const initialValue = JSON.parse(localStorage.getItem("user"));
	if(initialValue?.userid !== 'usr-4kcz067ppk1bogdzy'){
		console.log = () => {}
	}
}

ReactDOM.render(
	<HashRouter>
		<AppWrapper></AppWrapper>
	</HashRouter>,
	document.getElementById('root')
);





  


