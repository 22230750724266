import React, { useEffect, useRef, useState, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
// import Barcode from 'react-barcode'
// Common
import { CommonBodyForTable, commonDialogFooter, commonFormDialogFooter, commonCloseDialogFooter, srBodyTemplate, CommonBodyForFormattedStrTable } from "../Common/CommonComponents";
import { DateBodyForTable } from "../Common/CommonComponents";
import { CommonForDetails } from "../CRM/Leads";
import { barcodeFilterOptions } from "./Constants";
import { exportReport, failureToast, infoToast, successToast } from "../Common/CommonFunctions";
// Context
import { permissionContext } from "../../AppWrapper";
// Service
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

// ===============================================================================================================
// ===============================================================================================================

export const ProductionBatch = (props) => {

    const rolePermissions = useContext(permissionContext)

    const [rows, setRows] = useState(10);
    const [first, setFirst] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [onDemandCount, setOnDemandCount] = useState(20)
    const [onDemandIndex, setOnDemandIndex] = useState(0)
    const [sourceData, setSourceData] = useState([])
    const [onDemandId, setOnDemandId] = useState(0)
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState('')
    const [globalFilter2, setGlobalFilter2] = useState('')
    const [batchData, setBatchData] = useState(null)
    const [deleteBatchDialog, setDeleteBatchDialog] = useState(false)
    const [densityReportDialog, setDensityReportDialog] = useState(false);
    const [densityReportList, setDensityReportList] = useState([]);
    const [loading1, setLoading1] = useState(true);
    const [selectedbatch, setSelectedbatch] = useState(null)
    const [viewBatchDialog, setViewBatchDialog] = useState(false)
    const [selectedMatType, setSelectedMatType] = useState([])
    const [skuOptions, setSkuOptions] = useState(null)
    const [selectedSkuOptions, setSelectedSkuOptions] = useState([])
    const [noOfPackets, setNoOfPackets] = useState(1)
    const [totalWeight, setTotalWeight] = useState(null)
    const [dispensableCups, setDispensableCups] = useState(null)
    const [productionBatchTemplate, setProductionBatchTemplate] = useState(null)
    const [altBevTemplate, setAltBevTemplate] = useState([])
    const [productionBatchDialog, setProductionBatchDialog] = useState(false)
    const [allMaterialTypes, setAllMaterialTypes] = useState(null)
    const [batchBarcodes, setBatchBarcodes] = useState(null)
    const [filteredBatchBarcodes, setFilteredBatchBarcodes] = useState(null)
    const [selectedBarcodeFilter, setSelectedBarcodeFilter] = useState({ label: 'All', code: 'all' })
    const [addBevDialog, setAddBevDialog] = useState(false)
    const [barcodeInfoDialog, setBarcodeInfoDialog] = useState(false)
    const [searchValue, setSearchValue] = useState(null)
    const [searchCall, setSearchCall] = useState(true)
    const [canDelete, setCanDelete] = useState(false)
    const [confirmEmailDialog, setConfirmEmailDialog] = useState(false);
    const [uploaderDialog, setUploaderDialog] = useState(false);
    const [deleteReportDialog, setDeleteReportDialog] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [file, setFile] = useState()
    const dt = useRef(null)
    const toast = useRef(null)
    const [selectedBrand, setSelectedBrand] = useState({ code: "TAPRI", name: "TAPRI KIOSK" });
    const [viewSearchButton, setViewSearchButton] = useState(false);
    const [barcodeGenerateBtn, setBarcodeGenerateBtn] = useState(false);

    const brandOptions = [
        { code: "TAPRI", name: "TAPRI KIOSK" },
        { code: "TKAFFE", name: "TKAFFE KIOSK" },
        { code: "MERI_TAPRI", name: "TAPRI KETTLE" },
    ]

    useEffect(() => {
        getMaterialbatch()
        getMaterialTypes()
        // Test
        // getMaterialProducts()
    }, [])

    const getMaterialTypes = async () => {
        const response = await apiServices.getMaterialTypes();
        setAllMaterialTypes(response?.data?.mate)
        console.log(response?.data?.mate)
    }

    const getMaterialProducts = async () => {
        const response = await apiServices.getProductMaster({});
        console.log(response?.data?.data);

        const labelled = response?.data?.data?.map((x) => {
            return { ...x, materialNameLabel: `${x.materialName} / ${x.weight}` }
        })

        setAllMaterialTypes(labelled);
    }

    const getMaterialbatch = async () => {
        setLoading(true)
        const data = { count: 20, index: 0 }
        const response = await apiServices.getProductionBatch(data)
        // console.log(response?.data?.batch);
        setBatchData(response?.data?.batch)
        setSourceData(response?.data?.batch)
        setTotalRecords(response?.data?.batch.length);
        setLoading(false)
    }

    const openDeleteDialog = (rowData) => {
        setSelectedbatch(rowData)
        setDeleteBatchDialog(true)
        getBarcodes(rowData)
    }

    const openDesityReportDailog = async (rowData) => {
        setSelectedbatch(rowData);
        setDensityReportDialog(true);
        setLoading1(true);
        const response = await apiServices.getDensityReport({ batchId: rowData.batch_id })
        setDensityReportList(response?.data?.data);
        setLoading1(false)
    }

    const hideDesityReportDialog = () => {
        setSelectedbatch(null)
        setDensityReportDialog(false);
    }
    const densityReportDialogFooter = commonCloseDialogFooter('Close', hideDesityReportDialog)



    const actionDensityReportBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ display: "flex" }}>
                <Button icon="pi pi-eye" title='View Report' className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => { viewDensityReport(rowData) }} />
                {
                    rolePermissions?.fgProdBatchDensityDeleteBtn && <Button icon="pi pi-trash" title="Delete Report" className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={() => { deleteDensity(rowData) }} />
                }
            </div>
        );
    }

    const viewDensityReport = async (rowData) => {
        // console.log(rowData);
        const response = await apiServices.sendDensityReport(rowData);
        console.log("response", response);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        console.log('downloafUrl', downloadURL);
        window.open(downloadURL, '_blank');
    }



    const densityReportRightToolbarTemplate = () => {
        return (
            <>
                {
                    rolePermissions?.fgProdBatchDensityUploadBtn && <Button label="Upload Density Report" icon="pi pi-upload" className="p-button-rounded p-custom-btn p-mr-2 p-mb-2" onClick={() => { openReportUploader() }} />
                }
            </>
        )
    }

    const openReportUploader = async () => {
        setUploaderDialog(true)
    }

    const hideReportUploader = async () => {
        setUploaderDialog(false)
    }

    const uploadReportDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={hideReportUploader} />
            <Button label="Submit" type="submit" form='density-report' icon="pi pi-plus" className="p-button-success" />
        </>
    );

    function handleFileChange(event) {
        setFile(event.target.files[0])
    }

    const submitDensityReport = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('batchId', selectedbatch?.batch_id)
        data.append('batchName', selectedbatch?.batch_name)
        data.append('beverageName', selectedbatch?.batch_data?.materialName)
        data.append('skuId', selectedbatch?.batch_data?.sku_id)
        data.append('weight', selectedbatch?.batch_data?.weight)
        data.append('density', selectedbatch?.batch_data?.density)
        data.append('File', file);

        const response = await apiServices.uploadDensityReport(data);
        if (response?.data?.success) {
            successToast(toast, 'Report Uploaded Successfully');
            const response = await apiServices.getDensityReport({ batchId: selectedbatch?.batch_id })
            setDensityReportList(response?.data?.data);
        } else {
            failureToast(toast, 'Failed to Upload')
        }
        setFile(null);
        setUploaderDialog(false)
    }

    const deleteDensity = async (rowData) => {
        setSelectedReport(rowData);
        setDeleteReportDialog(true);
    }

    const hideDeleteReport = async () => {
        setDeleteReportDialog(false);
    }

    const confirmReportDelete = async () => {
        const response = await apiServices.deleteDensityReport({ _id: selectedReport?._id });
        response?.data?.success ? successToast(toast, 'Report Deleted Successfully') : failureToast(toast, 'Failed to Delete')
        setDeleteReportDialog(false);
        const result = await apiServices.getDensityReport({ batchId: selectedbatch?.batch_id })
        setDensityReportList(result?.data?.data);
        setSelectedReport(null);
    }

    const deleteReportDialogFooter = commonDialogFooter('Cancel', hideDeleteReport, 'Delete', confirmReportDelete)

    const deleteBatch = async () => {
        setDeleteBatchDialog(false)
        const response = await apiServices.deleteMaterialBatch({ batch_id: selectedbatch?.batch_id })
        response?.data?.success ? successToast(toast, 'Batch Deleted Successfully') : failureToast(toast, 'Failed to Delete')
        getMaterialbatch()
    }

    const openBatchDialog = (rowData) => {
        setViewBatchDialog(true)
        setSelectedbatch(rowData)
        getBarcodes(rowData)
        setSelectedBarcodeFilter(barcodeFilterOptions[0])
    }

    const openEdit = (rowData) => {
        // Test
        console.log(rowData);
        setSelectedbatch(rowData)
        setProductionBatchDialog(true)
        console.log(allMaterialTypes);
        const matType = allMaterialTypes.filter((x) => { return x.materialName == rowData?.batch_data?.materialName })
        console.log(matType);
        setSelectedMatType(matType[0])
        setSkuOptions(matType[0].sku)
        const skuOp = matType[0].sku?.filter((x) => { return x.item_id == rowData?.batch_data?.sku_id })
        console.log(skuOp);
        skuOp?.length > 0 && setSelectedSkuOptions(skuOp[0])
        setNoOfPackets(rowData?.batch_data?.packs)
        setTotalWeight(rowData?.batch_data?.packs * Number(rowData?.batch_data?.weight))
        const numbers = Number(rowData?.batch_data?.weight) / Number(matType[0]?.dispense_qty?.half?.beverage);
        const decimals = numbers - Math.floor(numbers);
        const finalValue = decimals.toFixed(2) > 0.90 ? Math.ceil(numbers) : Math.floor(numbers)
        setDispensableCups(finalValue)
        setProductionBatchTemplate({ ...rowData, watertype: matType[0]?.watertype })

        // setSelectedBrand
        const brand = brandOptions.find(x => x.code == rowData?.batch_data?.brand);
        // console.log("brand", brand);
        if (brand)
            setSelectedBrand(brand);
        // Updated
        // console.log(rowData);
        // setSelectedbatch(rowData)
        // setProductionBatchDialog(true)
        // console.log(allMaterialTypes);
        // const matType = allMaterialTypes.filter((x)=>{ return (x.materialName == rowData?.batch_data?.materialName && x.weight == rowData?.batch_data?.weight) })
        // console.log(matType);
        // setSelectedMatType(matType[0])
        // setSkuOptions(matType[0].sku)
        // setSelectedSkuOptions(matType[0])
        // setNoOfPackets(rowData?.batch_data?.packs)
        // setTotalWeight(rowData?.batch_data?.packs * Number(rowData?.batch_data?.weight))
        // const numbers = Number(rowData?.batch_data?.weight) / Number(matType[0]?.dispense_qty?.half?.beverage);
        // const decimals = numbers - Math.floor(numbers);
        // const finalValue = decimals.toFixed(2) > 0.90 ? Math.ceil(numbers) : Math.floor(numbers)
        // setDispensableCups(finalValue)
        // setProductionBatchTemplate({...rowData,watertype:matType[0]?.watertype})
    }

    const getBarcodes = async (data) => {
        const response = await apiServices.getBarcodesByBatch({ batch_id: data?.batch_id })
        setBatchBarcodes(response?.data?.data[0]?.batch_data?.barcodes)
        setFilteredBatchBarcodes(response.data?.data && response.data?.data[0]?.batch_data?.barcodes)
        const is = ifAdded(response.data?.data[0]?.batch_data?.barcodes)
        setCanDelete(is)
    }

    const ifAdded = (data) => {
        for (let index = 0; index < data?.length; index++) {
            if (data[index].stage != 'added') {
                return false;
            }
            return true;
        }
    }

    const openAddDialog = (rowData) => {
        setSelectedbatch(null)
        setProductionBatchDialog(true)
        setDefaultBatch()
    }

    const openBevDialog = (rowData) => {
        console.log(rowData);
        setSelectedbatch(rowData)
        setAddBevDialog(true)
        // Test
        // if(!rowData?.batch_data?.zohoId || !rowData?.batch_data?.itemName){
        //     infoToast(toast, "Zoho id or Item Name doesn't exists", "Failed")
        // }else{
        // }
    }

    const setDefaultBatch = () => {
        setProductionBatchTemplate(null)
        setSelectedMatType(null)
        setSkuOptions(null)
        setTotalWeight(null)
        setDispensableCups(null)
        setNoOfPackets(1)
        setSelectedSkuOptions(null)
        setAltBevTemplate([])
    }

    const changeProBatch = (e) => {

        if (e.target.name == 'materialName') {
            console.log(e.target.value);
            setSelectedMatType(e.target.value)
            setSkuOptions(e.target.value?.sku)

            // Test
            // setSelectedSkuOptions(e.target.value)
            // setTotalWeight(noOfPackets * Number(e.target.value?.weight))
            // const numbers = Number(e.target.value?.weight) / Number(e.target.value?.dispense_qty?.half?.beverage);
            // const decimals = numbers - Math.floor(numbers);
            // const finalValue = decimals.toFixed(2) > 0.90 ? Math.ceil(numbers) : Math.floor(numbers)
            // setDispensableCups(finalValue)
            // EndTest

            setProductionBatchTemplate(
                {
                    ...productionBatchTemplate,
                    [e.target.name]: e.target.value[e.target.name],
                    watertype: e.target.value?.watertype,
                    density: e.target.value?.density,
                    formulation: e.target.value?.formulation,
                    brand: e.target.value?.brand,
                    sku: e.target.value?.sku,
                    // Test
                    // skuName : e.target.value?.itemName,
                    // sku_id : e.target.value?.item_id,
                    // weight : e.target.value?.weight,
                    // cups : finalValue,
                    // zohoId :  e.target.value?.zohoId
                    // EndTest
                }
            )
        }

        if (e.target.name == 'skuName') {
            console.log(e.target.value);
            setSelectedSkuOptions(e.target.value)
            setTotalWeight(noOfPackets * Number(e.target.value?.weight))
            const numbers = Number(e.target.value?.weight) / Number(selectedMatType?.dispense_qty?.half?.beverage);
            const decimals = numbers - Math.floor(numbers);
            const finalValue = decimals.toFixed(2) > 0.90 ? Math.ceil(numbers) : Math.floor(numbers)
            setDispensableCups(finalValue)

            setProductionBatchTemplate(
                {
                    ...productionBatchTemplate,
                    [e.target.name]: e.target.value?.item_name,
                    sku_id: e.target.value?.item_id,
                    weight: e.target.value?.weight,
                    cups: finalValue,
                    zohoId: e.target.value?.zohoId
                }
            )
        }

        if (e.target.name == 'brand') {
            setSelectedBrand(e.target.value)
            setProductionBatchTemplate(
                {
                    ...productionBatchTemplate,
                    [e.target.name]: e.target.value?.code,
                }
            )
        }

        if (e.target.name == 'packs') {
            setNoOfPackets(e.target.value)
            setTotalWeight(Number(e.target.value) * Number(selectedSkuOptions?.weight))
            setProductionBatchTemplate({ ...productionBatchTemplate, [e.target.name]: Number(e.target.value), totalweight: Number(e.target.value) * Number(selectedSkuOptions?.weight) })
        }

        if (e.target.name == 'batch_name') {
            setProductionBatchTemplate({ ...productionBatchTemplate, [e.target.name]: e.target.value })
        }

        if (e.target.name == 'density' || e.target.name == 'formulation') {
            setProductionBatchTemplate({ ...productionBatchTemplate, [e.target.name]: Number(e.target.value) })
        }

        if (e.target.name == 'mfDate' || e.target.name == 'expDate') {
            setProductionBatchTemplate({ ...productionBatchTemplate, [e.target.name]: e.target.value })
        }
    }

    const changeAltBevBatch = (e) => {
        if (e.target.name == 'formulation') {
            const obj = { name: selectedMatType?.altBev[0], formulation: e.target.value }
            setAltBevTemplate([obj])
        }
    }

    const proBatchSubmit = async (e) => {
        e.preventDefault();
        setProductionBatchDialog(false)
        console.log("productionBatchTemplate", productionBatchTemplate)
        if (selectedbatch) {
            const data = productionBatchTemplate;
            const payload = {
                "_id": data?._id,
                "batch_id": data?.batch_id,
                "batch_name": data?.batch_name,
                "batch_type": data?.batch_type,
                "date": data?.date,
                "batch_data": {
                    "materialName": data?.batch_data?.materialName,
                    "density": data?.density || data?.batch_data?.density,
                    "formulation": data?.batch_data?.formulation,
                    "mfDate": data?.mfDate || data?.batch_data?.mfDate,
                    "expDate": data?.expDate || data?.batch_data?.expDate,
                    "watertype": data?.watertype,
                    "altBev": altBevTemplate.length > 0 ? altBevTemplate : data?.batch_data?.altBev,
                    "skuName": data?.batch_data?.skuName,
                    "sku_id": data?.batch_data?.sku_id,
                    "packs": data?.packs || data?.batch_data?.packs,
                    "weight": data?.batch_data?.weight,
                    "cups": data?.batch_data?.cups,
                    "brand": data?.batch_data?.brand
                },
                "barcodes": data?.barcodes,
                "materialName": data?.batch_data?.materialName,
                "density": data?.density || data?.batch_data?.density,
                "formulation": data?.batch_data?.formulation,
                "mfDate": data?.mfDate || data?.batch_data?.mfDate,
                "expDate": data?.expDate || data?.batch_data?.expDate,
                "skuName": data?.batch_data?.skuName,
                "sku_id": data?.batch_data?.sku_id,
                "packs": data?.packs || data?.batch_data?.packs,
                "weight": data?.batch_data?.weight,
                "cups": data?.batch_data?.cups,
                "batch_lock": false
            }

            console.log(payload);

            const response = await apiServices.updateMaterialBatch(payload)
            response?.data?.success ? successToast(toast, 'Batch Update Successfully') : failureToast(toast, 'Failed to Update')

        } else {

            const tempData = altBevTemplate?.length > 0 ? { ...productionBatchTemplate, altBev: altBevTemplate } : productionBatchTemplate;
            const defaultData = { batch_data: {}, batch_type: "productionBatch", batch_id: "MAT", altBev: [], stage: "start", packs: 1, cup: 1, itemName: selectedSkuOptions?.itemName }
            const payloadData = { ...defaultData, ...tempData }
            console.log(payloadData);
            const response = await apiServices.addMaterialBatch(payloadData)
            response?.data?.success ? successToast(toast, 'Batch Added Successfully') : failureToast(toast, 'Failed to Add')
        }

        searchValue ? searchFunction(searchValue) : getMaterialbatch();
    }

    const addBeverageSubmit = async (e) => {
        e.preventDefault()
        setAddBevDialog(false)

        const filData = allMaterialTypes?.filter((x) => { return x?.materialName == selectedbatch?.batch_data?.materialName; })
        const filData1 = filData && filData[0]?.sku?.filter((x) => { return x?.item_id == selectedbatch?.batch_data?.sku_id; })
        const finalData = { ...selectedbatch, materialArr: filData ? filData[0] : {}, skuDetail: filData1 ? filData1[0] : {} }

        // Test
        // skuName == itemName
        const data = {
            "materialName": finalData?.batch_data?.materialName,
            "batch_name": finalData?.batch_name,
            "itemName": finalData?.batch_data?.itemName,
            "weight": finalData?.batch_data?.weight,
            "mfDate": new Date(finalData?.batch_data?.mfDate).toISOString(),
            "expiryDate": new Date(finalData?.batch_data?.expDate).toISOString(),
            "dis_cup": finalData?.batch_data?.cups,
            "count": finalData?.batch_data?.packs,
            "brand": finalData?.batch_data?.brand,
            "cost": 0,
            "materialType": finalData?.materialArr?.materialType,
            "barcode": [],
            "batch": {
                "batch_name": finalData?.batch_name,
                "batch_id": finalData?.batch_id
            },
            "sku": finalData?.skuDetail,
            "zohoId": finalData?.batch_data?.zohoId
        }

        const response = await apiServices.generateBarcodes(data);
        response?.data?.success ? successToast(toast, 'Barcodes Generated Successfully') : failureToast(toast, 'Failed to add');
        searchValue ? searchFunction(searchValue) : getMaterialbatch();
    }

    const onPageChange = async (event) => {
        setOnDemandId(event.rows * event.page)
        setTimeout(() => {
            const startIndex = event.first;
            const endIndex = event.first + rows;
            setFirst(startIndex);
            setBatchData(sourceData.slice(startIndex, endIndex));
        }, 250);

        if ((event.page + 1) % 2 == 0) {
            let totalData;
            if (searchValue) {
                const data = { chars: searchValue, count: onDemandCount, index: onDemandIndex + 20 }
                const response = await apiServices.getProductionBatch(data)
                const arr = response?.data?.batch;
                totalData = [...sourceData, ...arr]
            } else {
                const data = { count: onDemandCount, index: onDemandIndex + 20 }
                const response = await apiServices.getProductionBatch(data)
                const arr = response?.data?.batch;
                totalData = [...sourceData, ...arr]
            }

            setSourceData(totalData);
            setTotalRecords(totalData.length);
            setBatchData(totalData.slice(event.first, event.first + rows));
            // setOnDemandCount(onDemandCount + 50)
            setOnDemandIndex(onDemandIndex + 20)
        }
    }

    const onSearch = (e) => {
        setSearchValue(e.target.value);
        if (e.target.value == '' || e.target.value == ' ') {
            getMaterialbatch();
            setViewSearchButton(false)
        } else {
            setViewSearchButton(true);
        }

        // if (e.target.value.length > 3) {
        //     setLoading(true)
        //     setSearchValue(e.target.value)
        //     if (e.target.value) {
        //         if (searchCall) {
        //             setFirst(0);
        //             setOnDemandIndex(0);
        //             setSearchCall(false);
        //             setTimeout(() => {
        //                 setSearchCall(true)
        //                 searchFunction(e.target.value, 20, 0)
        //             }, 2000);
        //         }
        //     } else if (e.target.value == '' || e.target.value == ' ') {
        //         getMaterialbatch()
        //     }
        // }

    }

    const handleSearch = () => {
        setLoading(true);
        setViewSearchButton(false);
        if (searchValue) {
            if (searchCall) {
                setFirst(0);
                setOnDemandIndex(0);
                setSearchCall(false);
                setTimeout(() => {
                    setSearchCall(true)
                    searchFunction(searchValue, 20, 0)
                }, 2000);
            }
        } else if (searchValue == '' || searchValue == ' ') {
            getMaterialbatch();
            setFirst(0);
            setOnDemandIndex(0);
            setSearchCall(false);
        }

    }

    const searchFunction = async (dataValue, count = onDemandCount, index = onDemandIndex) => {
        const data = { chars: dataValue.trim(), count: count, index: index }
        const response = await apiServices.getProductionBatch(data)
        setBatchData(response?.data?.batch);
        setSourceData(response?.data?.batch);
        setTotalRecords(response?.data?.batch.length);
        setLoading(false)
    }

    const exportCSV = () => { exportReport(dt) }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-right p-mr-2">
                <h5>Production Batches</h5>
            </span>
            <span className="p-input-icon-left">
                <div className="p-grid p-mt-1">
                    <div className="p-field">
                        <InputText type="search" style={{ width: '250px' }} defaultValue={searchValue} onInput={onSearch} placeholder="Click On Search..." />
                    </div>
                    {viewSearchButton && <div className="p-field">
                        {searchValue?.length > 0 && <Button className='custom-group-btn p-py-3' icon="pi pi-search" label="Search" onClick={handleSearch} disabled={loading} />}
                    </div>}
                </div>

            </span>
        </div>
    )

    const prodBatchheader = (
        <div className="table-header">
            <span className="p-input-icon-right p-mr-2"></span>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter2(e.target.value)} placeholder="Search..." />
                    <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />
                    {/* <Button  icon="pi pi-info" className='p-button-rounded p-mt-2 p-mx-2'  onClick={()=>{setBarcodeInfoDialog(true)}}/> */}
                    {/* <Button  icon="pi pi-info" className='p-button-rounded p-mt-2 p-mx-2'/> */}
                </div>
            </span>
        </div>
    )

    // const srBodyTemplate = (rowData, props) =>{ return ( <> <span className="p-column-title">{props.header}</span> {props.rowIndex + 1} </> ) }
    const onDemandidTemplate = (rowData, props) => (<><span className="p-column-title">Sr.No.</span>{props.rowIndex + 1 + onDemandId}</>)
    const batchBodyTemplate = (rowData, props) => {
        // let value;
        // if(props.field == 'downloaded'){
        //     value = rowData.batch_data[props.field] ? 'Downloaded' : 'Not Downloaded';
        // }else{
        //     value = rowData.batch_data[props.field];
        // }
        const value = props.field == 'downloaded' ? rowData.batch_data[props.field] ? 'Downloaded' : 'Not Downloaded' : rowData.batch_data[props.field];
        return (<> <span className="p-column-title">{props.header}</span> {value}</>)
    }

    const changeFilter = (e) => {
        if (e.target.value?.code == 'all') {
            setFilteredBatchBarcodes(batchBarcodes)
        } else {
            const filData = batchBarcodes?.filter((x) => { return x.stage == e.target.value?.code })
            setFilteredBatchBarcodes(filData)
        }
        setSelectedBarcodeFilter(e.target.value)
    }

    const sendEmail = async () => {
        const response = await apiServices.sendProductionBatchEmail({ "batch_id": selectedbatch?.batch_id })
        response?.data?.success ? successToast(toast, 'Email Sent Successfully') : failureToast(toast, `Failed to Sent, ${response?.data?.error}`)
        hideConfirmEmailDialog()
    }

    const hideConfirmEmailDialog = () => { setConfirmEmailDialog(false) }
    const confirmEmailFooter = commonDialogFooter('Cancel', hideConfirmEmailDialog, 'Send', sendEmail)


    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    <Button icon="pi pi-eye" title='View' className="p-button-rounded p-button-info p-mx-1" aria-label="View" onClick={() => { openBatchDialog(rowData) }} />

                    {((rolePermissions?.fgProdBatchEdit) || rolePermissions?.userType == 'owner') && <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={() => { openEdit(rowData) }} />}

                    {
                        rolePermissions?.fgProdBatchDelete &&
                        <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={() => { openDeleteDialog(rowData) }} />
                    }

                    {
                        rowData?.batch_type == 'materialBatch' ?
                            <Button icon="pi pi-check-circle" title='Added' className="p-button-rounded p-button-success p-mx-2" aria-label="Added" />
                            :
                            rolePermissions?.fgProdBatchAddActn && <Button icon="pi pi-plus" title='Add' className="p-button-rounded p-button-help p-mx-2" aria-label="Add" onClick={() => { openBevDialog(rowData) }} />
                    }

                    {
                        rolePermissions.fgProdBatchDensityBtn && <Button icon="pi pi-cloud-upload" title='Desity Report' className="p-button-rounded p-button-info p-mx-2" aria-label="Desity Report" onClick={() => { openDesityReportDailog(rowData) }} />
                    }

                </div>
            </>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <div className="p-inputgroup">
                {rolePermissions?.fgAddProdBatch && <Button className='custom-group-btn' icon="pi pi-plus" label="Add Production Batch" onClick={openAddDialog} />}
            </div>
        )
    }


    const generateRemainingBarcode = async () => {
        const filData = allMaterialTypes?.filter((x) => { return x?.materialName == selectedbatch?.batch_data?.materialName; })
        const filData1 = filData && filData[0]?.sku?.filter((x) => { return x?.item_id == selectedbatch?.batch_data?.sku_id; })
        const finalData = { ...selectedbatch, materialArr: filData ? filData[0] : {}, skuDetail: filData1 ? filData1[0] : {} }

        // Test
        // skuName == itemName
        const data = {
            "materialName": finalData?.batch_data?.materialName,
            "batch_name": finalData?.batch_name,
            "itemName": finalData?.batch_data?.itemName,
            "weight": finalData?.batch_data?.weight,
            "mfDate": new Date(finalData?.batch_data?.mfDate).toISOString(),
            "expiryDate": new Date(finalData?.batch_data?.expDate).toISOString(),
            "dis_cup": finalData?.batch_data?.cups,
            "count": Number(finalData?.batch_data?.packs) - Number(filteredBatchBarcodes?.length),
            "brand": finalData?.batch_data?.brand,
            "cost": 0,
            "materialType": finalData?.materialArr?.materialType,
            "barcode": [],
            "batch": {
                "batch_name": finalData?.batch_name,
                "batch_id": finalData?.batch_id
            },
            "sku": finalData?.skuDetail,
            "zohoId": finalData?.batch_data?.zohoId
        }
        const response = await apiServices.generateBarcodes(data);
        response?.data?.success ? successToast(toast, 'Barcodes Generated Successfully') : failureToast(toast, 'Failed to add');
        setViewBatchDialog(false)
        searchValue ? searchFunction(searchValue) : getMaterialbatch();
    }

    const leftBarcodeToolbarTemplate = () => {
        return (
            <div className="p-inputgroup">

                {(rolePermissions?.fgProdBatchBarcodeBtn && selectedbatch?.batch_data?.packs > batchBarcodes?.length) ? <Button icon="pi pi-plus" title='Generate Barcodes' className="p-button-rounded p-button-help p-mx-2" label="Generate Barcode" onClick={generateRemainingBarcode} /> : <div className="p-field">
                    <label htmlFor="Material Details">Material Details:-</label>
                </div>}
            </div>
        )
    }

    const rightBarcodeToolbarTemplate = () => {
        return (
            <div className="p-inputgroup">
                <Button className='custom-group-btn' label="Filter" />
                <Dropdown value={selectedBarcodeFilter} onChange={changeFilter} options={barcodeFilterOptions} optionLabel="label" placeholder="Select Filter" filter
                    className="p-m-0 multiselect-custom" />
                {(filteredBatchBarcodes?.length > 0 && (selectedBarcodeFilter.code == 'all' && filteredBatchBarcodes.every(elem => elem.stage == 'added'))) && <Button className='custom-group-btn p-ml-2' icon="pi pi-send" label="Send E-mail" onClick={() => { setConfirmEmailDialog(true) }} />}
            </div>
        )
    }

    // const BarcodeTemplate = (data, props) =>( <> <Barcode value={data[props.field]} /> </> )

    const hideDeletebatchDialog = () => { setDeleteBatchDialog(false) }
    const hideAddBevdialog = () => { setAddBevDialog(false) }
    const hideViewBatchDialog = () => { setViewBatchDialog(false) }

    const deleteBatchDialogFooter = commonDialogFooter('Close', hideDeletebatchDialog, 'Delete', deleteBatch, canDelete)
    const beveDialogFooter = commonFormDialogFooter('Close', hideAddBevdialog, 'Add', 'add-beverage')
    const viewBatchFooter = commonCloseDialogFooter('Close', hideViewBatchDialog)

    const batchDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={() => { setProductionBatchDialog(false) }} />
            {selectedSkuOptions?.itemName && <Button label="Submit" type="submit" form='pro-batch' icon="pi pi-plus" className="p-button-success" />}
        </>
    )



    return (
        <>
            <Toast ref={toast} position='center' />
            <Toolbar className="p-mb-4 p-toolbar" right={rightToolbarTemplate}></Toolbar>
            <DataTable ref={dt} value={batchData}
                dataKey="id" paginator rows={rows} className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Batches"
                globalFilter={globalFilter} emptyMessage="No Batch found." header={header} loading={loading} totalRecords={totalRecords} lazy first={first} onPage={onPageChange} >
                <Column field="srid" header="Sr.No." body={onDemandidTemplate} exportable={false}></Column>
                <Column field="batch_id" header="Batch Id" body={CommonBodyForTable}></Column>
                <Column field="batch_name" header="Batch Name" body={CommonBodyForTable}></Column>
                <Column field="materialName" header="Beverage Name" body={batchBodyTemplate}></Column>
                <Column field="sku_id" header="SKU Id" body={batchBodyTemplate}></Column>
                {/* <Column field="date" header="Date"  body={CommonBodyForTable}></Column> */}
                <Column field="date" header="Date & Time" body={DateBodyForTable}></Column>
                {/* <Column field="downloaded" header="Downloaded Status" body={batchBodyTemplate}  ></Column> */}
                <Column header="Actions" body={actionBodyTemplate}></Column>
            </DataTable>

            <Dialog visible={productionBatchDialog} style={{ width: '550px' }} header={`${selectedbatch ? 'Update' : 'Add'} Production Batch`} modal className="p-fluid" footer={batchDialogFooter} onHide={() => { setProductionBatchDialog(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={proBatchSubmit} id="pro-batch" className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="batch-Name">Batch Name</label>
                        <InputText id="batch-Name" name='batch_name' placeholder='Batch Name' defaultValue={selectedbatch?.batch_name} onChange={changeProBatch} autoComplete="off" autoFocus required />
                    </div>
                    <div className="p-field">
                        <label htmlFor="mat-Name">Material Name</label><br />
                        {/* materialNameLabel == materialName */}
                        {/* TEST */}
                        <Dropdown id="mat-Name" name="materialName" value={selectedMatType} onChange={changeProBatch} options={allMaterialTypes} optionLabel="materialName" placeholder="Select Material Name" filter required disabled={selectedbatch}></Dropdown>
                    </div>
                    <div className="p-field">
                        <label htmlFor="mat-Brand">Material Brand</label>
                        <Dropdown id="mat-Brand" name="brand" value={selectedBrand} onChange={changeProBatch} options={brandOptions} optionLabel="name" placeholder="Select Brand" filter required disabled={selectedbatch}></Dropdown>
                    </div>

                    {/* Test */}
                    <div className="p-field">
                        <label htmlFor="sku-Name">SKU Name</label><br />
                        <Dropdown id="sku-Name" name="skuName" value={selectedSkuOptions} onChange={changeProBatch} options={skuOptions} optionLabel="item_name" placeholder="Select SKU Name" filter required disabled={selectedbatch}></Dropdown>
                    </div>
                    {/* EndTest */}
                    <div className="p-field">
                        <label htmlFor="item-name">Item Name</label>
                        <InputText id="item-name" placeholder='Item Name' value={selectedSkuOptions?.itemName} disabled required />
                        <small className="p-error">{selectedSkuOptions?.itemName ? '' : 'Item Name is Mandatory'}</small>
                    </div>
                    <div className="p-field">
                        <label htmlFor="sku-Code">SKU Code</label>
                        <InputText id="sku-Code" name='sku_id' placeholder='SKU Code' defaultValue={selectedSkuOptions?.item_id} autoComplete="off" required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="zoho-Id">Zoho Id</label>
                        <InputText id="zoho-Id" name='zohoId' placeholder='Zoho Id' defaultValue={selectedSkuOptions?.zohoId} autoComplete="off" required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="net-Wt">Net Weight/Packet (In gms)</label>
                        <InputText id="net-Wt" placeholder='Net Wt/Pack' value={selectedSkuOptions?.weight} autoComplete="off" required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="total-Wt">Total Packets Weight (In gms)</label>
                        <InputText id="total-Wt" placeholder='Total Weight' value={totalWeight ? totalWeight : ''} autoComplete="off" required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="Des-Cup">Dispensable Cups / Packet</label>
                        <InputText id="Des-Cup" placeholder='Dispensable Cups' value={dispensableCups} autoComplete="off" required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="bev-">Premix Qty (100ml/gms)</label>
                        <InputText id="bev-" type="number" name='formulation' placeholder='Beverage (gms ml water)' defaultValue={selectedbatch?.batch_data?.formulation} onChange={changeProBatch} autoComplete="off" required disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="no-of-packs">Number of Packets</label>
                        <InputText id="no-of-packs" type='number' min='1' name='packs' placeholder='Number of Packs' defaultValue={noOfPackets} onChange={changeProBatch} autoComplete="off" onWheelCapture={(e) => { e.target.blur() }} required disabled={selectedbatch ? selectedbatch.barcodes > 0 ? true : false : false} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="density">Density</label>
                        <InputText id="density" type="number" name='density' placeholder='Density' step='0.001' min={0.001} max={0.999} defaultValue={selectedbatch?.batch_data?.density ? selectedbatch?.batch_data?.density : 0} onChange={changeProBatch} onWheelCapture={(e) => { e.target.blur() }} autoComplete="off" required />
                    </div>
                    <div className="p-field">
                        <label htmlFor="mfDate">Manufacture Date</label>
                        <InputText type='date' id="mfDate" name='mfDate' placeholder='Manufacture Date' defaultValue={selectedbatch?.batch_data?.mfDate} onChange={changeProBatch} autoComplete="off" required disabled={selectedbatch ? selectedbatch.barcodes > 0 ? true : false : false} />
                    </div>

                    <div className="p-field">
                        <label htmlFor="expDate">Expiry Date</label>
                        <InputText type='date' id="expDate" name='expDate' placeholder='Expiry Date' min={productionBatchTemplate?.mfDate} defaultValue={selectedbatch?.batch_data?.expDate} onChange={changeProBatch} autoComplete="off" required disabled={selectedbatch ? selectedbatch.barcodes > 0 ? true : false : false} />
                    </div>


                    {
                        selectedMatType?.altBev?.length > 0 &&

                        <>
                            <h5>Sister Beverage</h5>
                            <div className="p-field">
                                <label htmlFor="formu">Formulation : ({selectedMatType?.altBev && selectedMatType?.altBev[0]})</label>
                                <InputText id="formu" name='formulation' placeholder='Formulation' defaultValue={selectedbatch?.batch_data?.altBev[0]?.formulation} onChange={(e) => { changeAltBevBatch(e) }} required />
                            </div>
                        </>
                    }


                    {/* {
                        selectedMatType?.altBev?.map((x)=>{

                            return (
                                <>
                                    <div className="p-field">
                                        <label htmlFor="formu">Formulation : {x}</label>
                                        <InputText id="formu" name='formulation' placeholder='Formulation' defaultValue={''} onChange={(e)=>{changeAltBevBatch(e,x)}} required />
                                    </div>    
                                </>
                            )
                            
                        })

                    } */}

                </form>
            </Dialog>

            <Dialog visible={deleteBatchDialog} style={{ width: '450px' }} header={`Confirm ${selectedbatch?.batch_id}`} modal footer={deleteBatchDialogFooter} onHide={() => { setDeleteBatchDialog(false) }}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-4'><b>Are you sure you want to delete the selected Batch?</b></span>
                    </center>
                </div>
            </Dialog>

            <Dialog visible={viewBatchDialog} style={{ width: '900px' }} header={`Production Batch Details (${selectedbatch ? selectedbatch?.batch_id : '-'})  `} modal footer={viewBatchFooter} onHide={() => { setViewBatchDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <div className=" widget-performance">
                            <div className="content">
                                <ul>
                                    <div className='p-grid'>
                                        <CommonForDetails name="Batch Id" value={selectedbatch?.batch_id} />
                                        <CommonForDetails name="Material Name" value={selectedbatch?.batch_data?.materialName} />
                                        <CommonForDetails name="Batch Name" value={selectedbatch?.batch_name} />
                                        <CommonForDetails name="Nett Wt/Pack" value={`${selectedbatch?.batch_data?.weight / 1000} KG`} />
                                        <CommonForDetails name="SKU ID" value={selectedbatch?.batch_data?.sku_id} />
                                        <CommonForDetails name="Total Weight (Gms)" value={selectedbatch?.batch_data?.weight * selectedbatch?.batch_data?.packs} />
                                        <CommonForDetails name="Manufacture Date" value={selectedbatch?.batch_data?.mfDate} />
                                        <CommonForDetails name="No of Barcodes" value={selectedbatch?.batch_data?.packs} />
                                        <CommonForDetails name="Expiry Date" value={selectedbatch?.batch_data?.expDate} />
                                        <CommonForDetails name="Density" value={selectedbatch?.batch_data?.density} />
                                        <CommonForDetails name="Dispensable Cups" value={selectedbatch?.batch_data?.cups} />
                                        <CommonForDetails name="Beverage gms/100ml Water" value={selectedbatch?.batch_data?.formulation} />
                                        {/* <CommonForDetails name="Total Weight" value={ selectedbatch?.batch_data?.totalweight ? `${Number(selectedbatch?.batch_data?.totalweight) / 1000} KG` : 'NA' } /> */}
                                        {/* <CommonForDetails name="No of Barcodes" value={batchBarcodes?.length}/> */}
                                    </div>
                                </ul>
                            </div>
                        </div>


                        <div className="crud-demo">
                            <div className="card">
                                <Toolbar className="p-mb-4 p-toolbar" left={leftBarcodeToolbarTemplate} right={rightBarcodeToolbarTemplate}></Toolbar>
                                <DataTable ref={dt} value={filteredBatchBarcodes}
                                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Barcodess"
                                    globalFilter={globalFilter2} emptyMessage="No Barcodes found." loading={loading} header={prodBatchheader} exportFilename={`Batch Material Data`} >
                                    <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false} ></Column>
                                    <Column field="barcode" header="Barcode Id" body={CommonBodyForTable}></Column>
                                    <Column field="stage" header="Stage" body={CommonBodyForFormattedStrTable}></Column>
                                    {/* <Column field="barcode" header="Barcode" body={BarcodeTemplate} exportable={false}></Column> */}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={addBevDialog} style={{ width: '550px' }} header={`Add Beverages`} modal className="p-fluid" footer={beveDialogFooter} onHide={() => { setAddBevDialog(false) }} maximizable={true} blockScroll={true}>
                <form onSubmit={addBeverageSubmit} id="add-beverage" className="p-fluid">
                    <div className="p-field">
                        <label htmlFor="matName">Material Name</label>
                        <InputText id="matName" value={selectedbatch?.batch_data?.materialName} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="batchID">Batch</label>
                        <InputText id="batchID" value={selectedbatch?.batch_name} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="noOfBarcodes">No of Barcodes</label>
                        <InputText id="noOfBarcodes" value={selectedbatch?.batch_data?.packs} disabled />
                        <small className="p-error">Added {selectedbatch?.batch_data?.packs} of {selectedbatch?.batch_data?.packs} barcode(s)</small>
                    </div>
                    <div className="p-field">
                        <label htmlFor="sku">SKU</label>
                        <InputText id="sku" value={selectedbatch?.batch_data?.skuName} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="weight">Weight(gms)</label>
                        <InputText id="weight" value={selectedbatch?.batch_data?.weight} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="Dispensable-Cups">Dispensable Cups</label>
                        <InputText id="Dispensable-Cups" value={selectedbatch?.batch_data?.cups} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="mfDates">Manufacture Date</label>
                        <InputText id="mfDates" type="date" value={selectedbatch?.batch_data?.mfDate} disabled />
                    </div>
                    <div className="p-field">
                        <label htmlFor="exDate">Expiry Date</label>
                        <InputText id="exDate" type="date" value={selectedbatch?.batch_data?.expDate} disabled />
                    </div>

                </form>
            </Dialog>

            <Dialog visible={barcodeInfoDialog} style={{ width: '600px' }} header={`Steps to Export Barcode In Excel `} modal onHide={() => { setBarcodeInfoDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>
                <div className='p-grid'>
                    <div className="p-col-12">
                        <span className="p-m-2">1. Ensure having barcode font install in excel (Eg : 3of9 , IDAutomationHC39M Free Version)</span>
                        <br />
                        <span className="p-m-2">2. convert barcode column to text format.</span>
                        <br />
                        <span className="p-m-2">3. inside new column, apply formula like  ="*"&C2&"*" (replacing cell id instead C2)</span>
                        <br />
                        <span className="p-m-2">4. By Selecting the new column change the font to barcode font (3of9)</span>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={confirmEmailDialog} style={{ width: '450px' }} header="Confirm" modal footer={confirmEmailFooter} onHide={hideConfirmEmailDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-my-3 " style={{ fontSize: '4rem' }} /><br />
                        <b><span>Do You Want To Send the Email to Production?</span></b>  <br></br>
                    </center>
                </div>
            </Dialog>


            <Dialog visible={densityReportDialog} style={{ width: '1150px' }} header={`Density Report (${selectedbatch ? selectedbatch?.batch_id : '-'})`} modal footer={densityReportDialogFooter} onHide={hideDesityReportDialog} className='p-fluid' maximizable={true} blockScroll={true}>
                <Toolbar className="p-mb-4 p-toolbar" right={densityReportRightToolbarTemplate}></Toolbar>
                {
                    loading1 ?
                        <center><h5><i className="pi pi-spin pi-spinner" /></h5></center> :
                        <div className="p-grid crud-demo">
                            <div className="card">
                                <DataTable value={densityReportList} className="datatable-responsive" responsiveLayout="scroll" emptyMessage="No data found.">
                                    <Column field="code" header="Sr. No." body={srBodyTemplate} style={{ width: '8rem' }} ></Column>
                                    <Column field="density" header="Density" body={CommonBodyForTable} style={{ width: '8rem' }} ></Column>
                                    <Column field="originalname" header="File Name" body={fileBodyForTable} style={{ width: '12rem', overflowWrap: 'break-word' }}></Column>
                                    <Column field="createdAt" header="Uploaded At" body={dateBodyForTable} style={{ width: '12rem', overflowWrap: 'break-word' }} ></Column>
                                    <Column header="Action" body={actionDensityReportBodyTemplate} style={{ width: '8rem' }}></Column>
                                </DataTable>
                            </div>
                        </div>
                }
            </Dialog>


            <Dialog visible={uploaderDialog} style={{ width: '550px' }} header={`Upload Density Report`} modal footer={uploadReportDialogFooter} onHide={hideReportUploader} maximizable={true} blockScroll={true}>

                <form onSubmit={submitDensityReport} encType="multipart/form-data" id="density-report" className="p-fluid">

                    <div className='p-grid'>
                        <div className='p-col-12' style={{ display: 'none' }} >
                            <div className="p-field">
                                <label>Batch Id</label>
                                <InputText name='batchId' value={selectedbatch?.batch_id} required autoComplete='off' disabled={true} />
                            </div>
                        </div>

                        <div className='p-col-12' >
                            <div className="p-field">
                                <label>Batch Name</label>
                                <InputText name='batchName' value={selectedbatch?.batch_name} required autoComplete='off' disabled={true} />
                            </div>
                        </div>

                        <div className='p-col-12' >
                            <div className="p-field">
                                <label>Material Name</label>
                                <InputText name='beverageName' value={selectedbatch?.batch_data?.materialName} required autoComplete='off' disabled={true} />
                            </div>
                        </div>

                        <div className='p-col-12' >
                            <div className="p-field">
                                <label>SKU ID</label>
                                <InputText name='skuId' value={selectedbatch?.batch_data?.sku_id} required autoComplete='off' disabled={true} />
                            </div>
                        </div>

                        <div className='p-col-12' >
                            <div className="p-field">
                                <label>Density</label>
                                <InputText name='skuId' value={selectedbatch?.batch_data?.density} required autoComplete='off' disabled={true} />
                            </div>
                        </div>

                        <div className='p-col-12' >
                            <div className="p-field">
                                <label>Weight</label>
                                <InputText name='weight' value={selectedbatch?.batch_data?.weight} required autoComplete='off' disabled={true} />
                            </div>
                        </div>

                        <div className='p-col-12'>
                            <div className="p-field">
                                <label>Upload Report</label>
                                <InputText type="file" id="File" name='File' onChange={handleFileChange} accept="application/pdf" required />
                            </div>
                        </div>
                    </div>
                </form>

            </Dialog>

            <Dialog visible={deleteReportDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteReportDialogFooter} onHide={hideDesityReportDialog}>
                <div className="confirmation-content">
                    <center>
                        <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                        <span className='p-mt-2'><b>Are you sure you want to delete the selected density report?</b></span>
                    </center>
                </div>
            </Dialog>

        </>
    )
}



export const fileBodyForTable = (data, props) => (
    // console.log(data, props)
    <>
        <span className="p-column-title">{props.header}</span>{data?.file[props.field] ? data?.file[props.field] : 'NA'}
    </>
)

export const dateBodyForTable = (data, props) => (
    <>
        <span className="p-column-title">{props.header}</span>{data?.[props.field] ? new Date(data?.[props.field]).toString('YYYY-MM-dd') : 'NA'}
    </>
)
