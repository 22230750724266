import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Login } from './pages/Login';
// Theme
import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';
import AppRightMenu from './AppRightMenu';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
// newly added
import ChangeLog from './service/ChangeLog';
import { sideMenuOptions } from './components/SideMenus/sideMenuOptions';
import { managementCheck } from './service/admin/superadmin';
import { PageAccess } from './pages/PageAccess';
import { constantRoutes } from './AppConstants';
// Admin
import PrimeReact from 'primereact/api';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';
// Services
import ApiServices from './service/api/ApiServices';
import Permissions from './service/permissions/Permissions';
const apiServices = new ApiServices();
const permissions = new Permissions();
// const serviceCalls = new ServiceCalls()
// Logged
const initialValue = JSON.parse(localStorage.getItem("user"));

// ===============================================================================================
// ===============================================================================================

const App = (props) => {

    let history = useHistory();
    const [loginUserData, setLoginUserData] = useState([]);
    const [loginUserName, setLoginUserName] = useState([]);
    const [loginUserLName, setLoginUserLName] = useState([]);
    const [rightMenuActive, setRightMenuActive] = useState(false);
    const [configActive, setConfigActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [ripple, setRipple] = useState(true);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [topbarMenuActive, setTopbarMenuActive] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [pinActive, setPinActive] = useState(false);
    const [activeInlineProfile, setActiveInlineProfile] = useState(false);
    const [resetActiveIndex, setResetActiveIndex] = useState(null)
    const [version, setVersion] = useState(null);
    // const [userdata, setUserdata] = useState(null)
    const [user, setUser] = useState(null);
    const [connectionAlert, setConnectionAlert] = useState(false);

    PrimeReact.ripple = true;

    let menu;

    if (initialValue?.userType === "owner") {

        // swapnil usr-em890k81kypncp79
        // sandeep usr-4kcz06434k16hr64q
        // Snehal usr-em8907y3leqy5c8v
        // Pratik 'usr-4kcz067ppk1bogdzy', 
        // Rupesh B 'usr-em890mjolcq0j6sy'
        // || initialValue?.userid == 'usr-4kcz06434k16hr64q'

        const userCheck = ['usr-em890k81kypncp79', 'usr-4kcz06434k16hr64q', 'usr-em8907y3leqy5c8v', 'usr-4kcz067ppk1bogdzy', 'usr-em890mjolcq0j6sy']
        const check = userCheck.includes(initialValue?.userid)

        if (check) {
            menu = sideMenuOptions.developer;
        } else if (managementCheck()) {
            menu = sideMenuOptions.management;
        } else {
            menu = sideMenuOptions[initialValue?.userType]
        }

    } else if (initialValue?.userType === 'customer') {

        console.log("initialValue?.userType",  initialValue?.tabChecker);

        if (initialValue?.tabChecker?.employeeCheck && initialValue?.tabChecker?.transactionCheck) {
            menu = sideMenuOptions.customerOne;
        } else if (initialValue?.tabChecker?.employeeCheck && !initialValue?.tabChecker?.transactionCheck) {
            menu = sideMenuOptions.customerTwo;
        } else if (!initialValue?.tabChecker?.employeeCheck && initialValue?.tabChecker?.transactionCheck) {
            menu = sideMenuOptions.customerThree;
        } else {
            menu = sideMenuOptions.customerFour;
        }

    } else if (initialValue?.userType === 'facility') {

        if (initialValue?.tabChecker?.employeeCheck && initialValue?.tabChecker?.transactionCheck) {
            menu = sideMenuOptions.facilityOne;
        } else if (initialValue?.tabChecker?.employeeCheck && !initialValue?.tabChecker?.transactionCheck) {
            menu = sideMenuOptions.facilityTwo;
        } else if (!initialValue?.tabChecker?.employeeCheck && initialValue?.tabChecker?.transactionCheck) {
            menu = sideMenuOptions.facilityThree;
        } else {
            menu = sideMenuOptions.facilityFour;
        }

    } else if (initialValue?.userType === 'marketmanager' || initialValue?.userType === 'accountmanager' || initialValue?.userType === 'plantmanager' || initialValue?.userType === 'operation'
        || initialValue?.userType === 'distributor' || initialValue?.userType === 'techqc' || initialValue?.userType === 'production' || initialValue?.userType === 'formulation'
        || initialValue?.userType === 'dealer' || initialValue?.userType === 'finance' || initialValue?.userType === 'viewer' || initialValue?.userType === 'inventory' || initialValue?.userType === 'invenstor' || initialValue?.userType === 'superman' || initialValue?.userType === 'techops' || initialValue?.userType === 'facility' || initialValue?.userType === 'fintech') {
        menu = sideMenuOptions[initialValue?.userType]
    } else {
        menu = sideMenuOptions.else
    }

    let rightMenuClick;
    let configClick;
    let menuClick;
    let searchClick = false;
    let topbarItemClick;

    const getUserInfo = async () => {
        if (initialValue) {
            // console.log(initialValue)
            const response = await apiServices.userInfo(initialValue?.userid, initialValue?.idToken);
            // console.log(response?.data?.data)

            if (response?.data?.data && response?.data?.data?.isActive) {
                setUser(response?.data?.data)
                setLoginUserData(response?.data?.data?.image?.data)
                setLoginUserName(response?.data?.data?.name)
                setLoginUserLName(response?.data?.data?.lastName)
            }
            // else{
            //     localStorage.clear();
            //     window.location.reload();
            //     history.push('./');
            // }
        }
    }

    const logdata = () => {
        const ChangeLogs = new ChangeLog();
        ChangeLogs.getLogs().then((data) => setVersion(data));
    }

    useEffect(() => {
        getUserInfo()
        logdata()
        // checkUserAuthenticity()
        getPerma()
    }, []);


    useEffect(() => {
        setResetActiveIndex(true)
        setMenuActive(false)
    }, [menuMode])


    // useEffect(() => {
    //     const saved = localStorage.getItem("user");
    //     const initialValue = JSON.parse(saved);
    //     setUserdata(initialValue);
    // }, [])

    // useEffect(async () => {
    //     const res = await permissions.getPermissionsDetails()
    //     localStorage.setItem('permission', JSON.stringify(res));
    // }, [])

    useEffect(() => {
        setInterval(() => {
            checkDataConnection()
        }, 2000);
    })

    const checkDataConnection = () => {
        window?.navigator?.onLine ? setConnectionAlert(false) : setConnectionAlert(true);
    }

    const getPerma = async () => {
        const res = await permissions.getPermissionsDetails()
        // console.log("res", res);
        localStorage.setItem('permission', JSON.stringify(res));
    }

    const checkUserAuthenticity = () => {
        const twentyFourHrInMs = 24 * 60 * 60 * 1000;
        const twentyFourHoursAgo = Date.now() - twentyFourHrInMs;
        // const twentyFourHrInMs = 1 * 60 * 60 * 1000;
        // console.log(`${initialValue?.loginDate} && ${twentyFourHoursAgo} =>>> ${initialValue?.loginDate < twentyFourHoursAgo}`);
        if (initialValue?.loginDate < twentyFourHoursAgo) {
            localStorage.clear();
            history.push('./login');
            window.location.reload();
        }
    }

    checkUserAuthenticity()

    const onDocumentClick = () => {
        if (!searchClick && searchActive) { onSearchHide(); }

        if (!topbarItemClick) { setTopbarMenuActive(false) }

        if (!menuClick) {
            if (isHorizontal() || isSlim()) {
                setMenuActive(false)
                setResetActiveIndex(true)
            }

            if (overlayMenuActive || staticMenuMobileActive) {
                setOverlayMenuActive(false);
                setStaticMenuMobileActive(false)
            }

            hideOverlayMenu();
            unblockBodyScroll();
        }

        if (!rightMenuClick) { setRightMenuActive(false) }

        if (configActive && !configClick) { setConfigActive(false); }

        topbarItemClick = false;
        menuClick = false;
        configClick = false;
        rightMenuClick = false;
        searchClick = false;
    }

    const onSearchHide = () => {
        setSearchActive(false);
        searchClick = false;
    }

    const onMenuModeChange = (menuMode) => {
        setMenuMode(menuMode);
        setOverlayMenuActive(false);
    }

    const onRightMenuButtonClick = () => {
        rightMenuClick = true;
        setRightMenuActive(true)
    }

    const onRightMenuClick = () => { rightMenuClick = true; }
    const onRightMenuActiveChange = (active) => { setRightMenuActive(active); }
    const onConfigClick = () => { configClick = true; }

    const onConfigButtonClick = (event) => {
        setConfigActive(prevState => !prevState)
        configClick = true;
        event.preventDefault();
    }

    const onRippleChange = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value);
    }

    const onMenuButtonClick = (event) => {
        menuClick = true;
        if (isOverlay()) { setOverlayMenuActive(prevState => !prevState) }

        if (isDesktop()) {
            setStaticMenuDesktopInactive(prevState => !prevState)
        } else {
            setStaticMenuMobileActive(prevState => !prevState)
        }
        event.preventDefault();
    }

    const hideOverlayMenu = () => {
        setOverlayMenuActive(false)
        setStaticMenuMobileActive(false)
    }

    const onTopbarItemClick = (event) => {
        topbarItemClick = true;
        setTopbarMenuActive(prevState => !prevState)
        hideOverlayMenu();
        event.preventDefault();
    }

    const onToggleMenu = (event) => {
        menuClick = true;
        if (overlayMenuActive) { setOverlayMenuActive(false) }
        if (sidebarActive) { setSidebarStatic(prevState => !prevState) }
        event.preventDefault();
    }

    const onSidebarMouseOver = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setSidebarActive(isDesktop());
            setTimeout(() => {
                setPinActive(isDesktop())
            }, 200);
        }
    }

    const onSidebarMouseLeave = () => {
        if (menuMode === 'sidebar' && !sidebarStatic) {
            setTimeout(() => {
                setSidebarActive(false);
                setPinActive(false);
            }, 250);
        }
    }

    const onMenuClick = () => { menuClick = true; }
    const onRootMenuItemClick = () => { setMenuActive(prevState => !prevState) }

    const onChangeActiveInlineMenu = (event) => {
        setActiveInlineProfile(prevState => !prevState);
        event.preventDefault();
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            hideOverlayMenu();
            setResetActiveIndex(true);
        }

        if (!event.item.items && (isHorizontal() || isSlim())) {
            setMenuActive(false);
        }
    }

    const isHorizontal = () => { return menuMode === 'horizontal'; }
    const isSlim = () => { return menuMode === 'slim'; }
    const isOverlay = () => { return menuMode === 'overlay'; }
    const isDesktop = () => { return window.innerWidth > 991; }
    const onInputClick = () => { searchClick = true }

    const breadcrumbClick = () => {
        searchClick = true;
        setSearchActive(true);
    }

    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    const layoutClassName = classNames('layout-wrapper', {
        'layout-static': menuMode === 'static',
        'layout-overlay': menuMode === 'overlay',
        'layout-overlay-active': overlayMenuActive,
        'layout-slim': menuMode === 'slim',
        'layout-horizontal': menuMode === 'horizontal',
        'layout-active': menuActive,
        'layout-mobile-active': staticMenuMobileActive,
        'layout-sidebar': menuMode === 'sidebar',
        'layout-sidebar-static': menuMode === 'sidebar' && sidebarStatic,
        'layout-static-inactive': staticMenuDesktopInactive && menuMode === 'static',
        'p-ripple-disabled': !ripple
    });

    const colorTheme = props.colorScheme;
    const closeFooter = (<><Button label="RETRY" icon="pi pi-refresh" className="p-button-success p-mr-2 p-mb-2" onClick={() => { setConnectionAlert(false) }} /></>)

    return (
        <div className={layoutClassName} onClick={onDocumentClick}>

            {
                (initialValue != null) &&
                <>
                    <div className="layout-main">
                        <AppTopbar loginUserData={loginUserData} items={menu} menuMode={menuMode} colorScheme={props.colorScheme} menuActive={menuActive}
                            topbarMenuActive={topbarMenuActive} activeInlineProfile={activeInlineProfile} onTopbarItemClick={onTopbarItemClick} onMenuButtonClick={onMenuButtonClick}
                            onSidebarMouseOver={onSidebarMouseOver} onSidebarMouseLeave={onSidebarMouseLeave} onToggleMenu={onToggleMenu}
                            onChangeActiveInlineMenu={onChangeActiveInlineMenu} onMenuClick={onMenuClick} onMenuItemClick={onMenuItemClick}
                            onRootMenuItemClick={onRootMenuItemClick} resetActiveIndex={resetActiveIndex} />

                        <AppMenu onRightMenuButtonClick={onRightMenuButtonClick} loginUserLName={loginUserLName} loginUserName={loginUserName} loginUserData={loginUserData} model={menu} onRootMenuItemClick={onRootMenuItemClick} onMenuItemClick={onMenuItemClick} onToggleMenu={onToggleMenu} onMenuClick={onMenuClick} menuMode={menuMode}
                            colorScheme={props.colorScheme} menuActive={menuActive}
                            sidebarActive={sidebarActive} sidebarStatic={sidebarStatic} pinActive={pinActive}
                            onSidebarMouseLeave={onSidebarMouseLeave} onSidebarMouseOver={onSidebarMouseOver}
                            activeInlineProfile={activeInlineProfile} onChangeActiveInlineMenu={onChangeActiveInlineMenu} resetActiveIndex={resetActiveIndex} version={version} />

                        <AppBreadcrumb routes={constantRoutes} onMenuButtonClick={onMenuButtonClick} menuMode={menuMode}
                            onRightMenuButt onRightMenuButtonClick={onRightMenuButtonClick} onInputClick={onInputClick}
                            searchActive={searchActive} breadcrumbClick={breadcrumbClick}
                            colorScheme={props.colorScheme} changeColorScheme={props.onColorSchemeChange}
                        />
                        {/* onToggleMenu={onToggleMenu} sidebarStatic={sidebarStatic} pinActive={pinActive} */}
                        <PageAccess />
                        <AppFooter colorScheme={props.colorScheme} />
                    </div>

                    <AppRightMenu user={user} getUserInfo={getUserInfo} loginUserData={loginUserData} rightMenuActive={rightMenuActive} onRightMenuClick={onRightMenuClick} onRightMenuActiveChange={onRightMenuActiveChange} />

                    <AppConfig configActive={configActive} onConfigButtonClick={onConfigButtonClick} onConfigClick={onConfigClick} menuMode={menuMode} changeMenuMode={onMenuModeChange}
                        colorScheme={props.colorScheme} changeColorScheme={props.onColorSchemeChange} theme={props.theme} changeTheme={props.onMenuThemeChange}
                        componentTheme={props.componentTheme} changeComponentTheme={props.onComponentThemeChange}
                        ripple={ripple} onRippleChange={onRippleChange} />
                </>
            }

            {initialValue == null && <Route path="/" render={() => <Login />} />}

            <Dialog visible={connectionAlert} style={{ width: '450px' }} header="No Internet Connection" modal footer={closeFooter} onHide={(e) => { setConnectionAlert(false) }}>
                <div className="confirmation-content">
                    <center>
                        <img src='/assets/layout/images/no_internet.png' style={{ width: '250px' }} /><br /><br />
                        <b><span className='p-error'>You are offline</span></b> <br /><br />
                    </center>
                </div>
            </Dialog>

        </div>

    );

}

export default App;
