import React, { useState, useRef, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { TabView } from "primereact/tabview";
import { TabPanel } from "primereact/tabview";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
// Components
import { OnboardOrderMachine } from "./OnboardOrderMachine";
import { CommonForDetails } from "../CRM/Leads";
import { CtvmOrdersSkeleton } from "../Common/CommonComponents";
import { CommonBodyForTable, srBodyTemplate, DateBodyForTable } from "../Common/CommonComponents";
// Context
import { permissionContext } from "../../AppWrapper";
// Services
import { failureToast, successToast } from "../Common/CommonFunctions";
import ApiServices from "../../service/api/ApiServices";
const apiServices = new ApiServices()

export const CtvmOrders = () =>{

    const rolePermissions = useContext(permissionContext);

    const [globalFilter,setGlobalFilter] = useState(null)
    const [globalFilter1,setGlobalFilter1] = useState(null)
    const [loading,setLoading] = useState(true)
    const [loading1,setLoading1] = useState(true)
    const [orderData,setOrderData] = useState(null)    
    const [placedOrderData,setPlacedOrderData] = useState(null)    
    const [onBoardDialog,setOnBoardDialog] = useState(false)    
    const [viewOrderDialog,setViewOrderDialog] = useState(false)    
    const [viewPlacedOrderDialog,setViewPlacedOrderDialog] = useState(false)    
    const [selectedMachineData,setSelectedMachineData] = useState(null)    
    const [fgMachines,setFgMachines] = useState([])    
    const [allFgMachines,setAllFgMachines] = useState([])
    const [confirmDeleteDialog,setConfirmDeleteDialog] = useState(false)    
    const [onboardingLoading, setOnboardingLoading] = useState(true)
    const [multiOrderDialog, setMultiOrderDialog] = useState(false)
    const [childOrders, setChildOrders] = useState(null)
    
    const toast = useRef(null);
    const dt = useRef(null);
    const dt1 = useRef(null);

    useEffect(()=>{
        getOrders()
        getPlacedOrders()
        getFgMachines()
    },[])

    const getOrders = async() =>{
        const response = await apiServices.getCTVMOrders()
        const arr =[];
        response?.data?.data.map((x)=>{
            
            if(x.leadData.length > 0 || x.userData.length > 0){

                let data;
                if(x.leadData.length > 0){
                    
                    data = {...x,
                        ownerName : x.leadData[0].name + " " +  x.leadData[0].lastName,
                        userType:x.leadData.length > 0 && 'Lead' || x.userData.length > 0 && 'Account' 
                    }
                
                }else if(x.userData.length > 0){
                    
                    data = {...x,
                        ownerName : x.userData[0].name + " " + x.userData[0].lastName,
                        userType:x.leadData.length > 0 && 'Lead' || x.userData.length > 0 && 'Account'
                    }
                
                }else{

                    data = {...x,
                        ownerName : 'NA',
                        userType:x.leadData.length > 0 && 'Lead' || x.userData.length > 0 && 'Account'
                    }
                }
                arr.push(data)
            }
        })

        console.log(arr);
        setOrderData(arr)
        setLoading(false)
    }

    const getPlacedOrders = async() =>{
        const response = await apiServices.getPlacedCTVMOrders()
        console.log(response?.data?.data);
        setPlacedOrderData(response?.data?.data)  
        setLoading1(false) 
    }

    const getFgMachines = async()=>{
        const response = await apiServices.getFinishedgoodsCTVM()
        console.log(response?.data?.data);
        setAllFgMachines(response?.data?.data)
    } 

    const openMultiOrderDialog = (rowData) =>{
        setSelectedMachineData(rowData)
        setChildOrders(rowData)
        setMultiOrderDialog(true)
    }

    const openDialog = async(data) =>{
        console.log(data);
        setOnboardingLoading(true)
        setOnBoardDialog(true)
        setSelectedMachineData(data)
        const response = await apiServices.getFinishedgoodsCTVM()
        // console.log(response.data.data);

        let dataforFgMachines;
        if(data?.app_cat == "Beverage Vending Machine"){
            dataforFgMachines = response?.data?.data.filter((x)=>{return data.ctvmCanister == x.cans});
        }else if(data?.app_cat == "Capsule Machine"){
            dataforFgMachines = response?.data?.data.filter((x)=>{return x.app_cat == "Capsule Machine"})
        }else if(data?.app_cat == "Tapri Kettle" || data?.app_cat == "Cherise Tapri" ){
            dataforFgMachines = response?.data?.data.filter((x)=>{return x.app_cat == "Tapri Kettle" || x.app_cat == "Cherise Tapri"})   
        }

        console.log(dataforFgMachines);
        setFgMachines(dataforFgMachines)
        setOnboardingLoading(false)
    }

    const openDeleteDialog = async(data) =>{
        setConfirmDeleteDialog(true)
        setSelectedMachineData(data)
    }

    const deleteOrder = async() =>{
        setConfirmDeleteDialog(false)
        // console.log(selectedMachineData);
        const response = await apiServices.removeMachineOrder({ "orderId": selectedMachineData?.orderId })
        if(response?.data?.success){
            successToast(toast, 'Order Deleted Successfully')
            getOrders()
        }else{
            failureToast(toast, 'Failed to Delete')
        } 
    }

    
    const openViewDialog = (data) =>{
        console.log(data);
        setViewOrderDialog(true)
        setSelectedMachineData(data)
        !data.orderViewed && viewOrder(data)   
    }

    const viewOrder = (data) =>{
        setOrderView(data)
        getOrders()
    }

    const setOrderView = async(data) =>{
        const response = await apiServices.setOrderViewed({orderId:data.orderId})
        console.log(response);
    }

    const openPlacedDialog = (data) =>{
        console.log(data);
        setSelectedMachineData(data)
        setViewPlacedOrderDialog(true)
    }

    const exportCSV = () =>{ dt.current.exportCSV()}
    const exportCSV1 = () =>{ dt1.current.exportCSV()}

    const header1 = (
        <div className="table-header">
            <h6 className="p-mb-1">Orders </h6>
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV}/>
                </div>
            </span>
        </div>
    )

    const header2 = (
        <div className="table-header">
            <h6 className="p-mb-1">Placed Orders</h6>            
            <span className="p-input-icon-left">
                <div className="p-inputgroup">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter1(e.target.value)} placeholder="Search..." />
                    <Button  icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4'  onClick={exportCSV1}/>
                </div>
            </span>
        </div>
    );


    const subTypeBodyTemplate = (data, props) => {
        const name = (data[props.field] === 'permanent') ? 'Commercial' : 'Demo'
        return ( <> <span className="p-column-title">{props.header}</span> {name ? name : 'NA'} </> )
    }

    const appCatBodyTemplate = (data, props) => (
        <>
            <span className="p-column-title">{props.header}</span>
            {data?.app_cat} / {data?.brand ? data?.brand : 'NA'}
        </>
    )

    const subidBodyTemplate = (data,props) =>{
        const val = data[props.field].toString().length == 9 ? data[props.field] : '000000' + data[props.field];
        return (
            <> <span className="p-column-title">{props.header}</span> {val ? val : 'NA'} </>
        )
    }

    const typeBodyTemplate = (data, props) => {
        const type = data[props.field] == 'permanent' ? 'Commercial' : 'Demo'
        return (<> <span className="p-column-title">{props.header}</span> {type ? type : 'NA'} </> )
    }

    // const companyBodyTemplate = (data, props) =>{
    //     return (
    //         <>
    //             <span className="p-column-title">{props.header}</span>
    //             {data?.userData.length > 0 ? data?.userData[0]?.[props.field] : 'NA'}
    //         </>
    //     )
    // }

    const companyBodyTemplate = (data, props) =>{
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data?.userData.length > 0 ? data?.userData[0]?.billing_details?.company : 'NA'}
            </>
        )
    }

    const orderIdBodyTemplate = (data, props) => {
        return (
            <>
                <span className="p-column-title">{props.header}</span>
                {data[props.field]} 
                { data?.orderViewed ? null : <Badge value="New" severity="info"></Badge> }
            </>
        )
    }

    const actionBodyTemplate = (rowData) => (
        <>
            <span className="p-column-title">Actions</span>
            <div className="actions" style={{display:"flex"}}>      
                <Button icon="pi pi-eye" title='View Order' className="p-button-rounded p-button-info p-mr-2 " aria-label="View Order" onClick={()=>{openViewDialog(rowData)}} />

                {/* {
                    rolePermissions?.multiCtvmOrderOnboard &&
                    <Button icon="pi pi-list" title='Multiple on-Board Order' className="p-button-rounded p-button-success p-mr-2" aria-label="On-Board Order" onClick={()=>{openMultiOrderDialog(rowData)}} />
                } */}

                {
                    rolePermissions?.ctvmOrderOnboard &&
                    <Button icon="pi pi-plus-circle" title='On-Board Order' className="p-button-rounded p-button-success p-mr-2" aria-label="On-Board Order" onClick={()=>{openDialog(rowData)}} />
                }

                {
                    rolePermissions?.ctvmOrderDelete &&
                    <Button icon="pi pi-trash" title='Delete Order' className="p-button-rounded p-button-danger " aria-label="Delete Order" onClick={()=>{openDeleteDialog(rowData)}} />
                }


            </div>
        </>
    )

    const actionBodyTemplate1 = (rowData) =>(

        <>  
            <span className="p-column-title">Actions</span>
            <div className="actions" style={{display:"flex"}}>      
                <Button icon="pi pi-eye" title='View Order' className="p-button-rounded p-button-info p-mr-2 " aria-label="View Order" onClick={()=>{openPlacedDialog(rowData)}} />
            </div>
        </>
    )

    const viewOrderFooter = (<><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setViewOrderDialog(false)}} /></>)
    const viewPlacedOrderFooter =  (<><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setViewPlacedOrderDialog(false)}} /></>)
    const multiOrderFooter =  (<><Button label="Close" icon="pi pi-times" className="p-button-danger p-mr-2 p-mb-2" onClick={()=>{setMultiOrderDialog(false)}} /></>)

    const deleteDialogFooter = () =>{
        return (
            <>
                <Button label="Yes, Delete it." icon="pi pi-check" className=" p-button-success" onClick={()=>{deleteOrder()}}/>
                <Button label="Cancel" icon="pi pi-times" className=" p-button-danger" onClick={()=>{setConfirmDeleteDialog(false)}}  />
            </>
        )
    }

    return (

        <>
        <div className="card">
        <TabView>
        <TabPanel header="CVES ORDERS">

        {
            loading ? 
            <CtvmOrdersSkeleton/>
            :
            <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                <Toast ref={toast} position='center' />
                    <DataTable ref={dt}  value={orderData}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Orders"
                        globalFilter={globalFilter} emptyMessage="No data found." header={header1} loading={loading} exportFilename={`CVES Orders`} >
                        <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                        <Column field="orderId" header="Order Id" body={orderIdBodyTemplate} style={{ overflowWrap:'break-word' }} sortable ></Column>
                        <Column field="SDid" header="Sales Order Id" body={CommonBodyForTable} style={{ overflowWrap:'break-word' }} sortable ></Column>
                        <Column field="app_cat" header="Category / Brand" body={appCatBodyTemplate} sortable ></Column>
                        <Column field="ctvmCanister" header="Canister" body={CommonBodyForTable} sortable ></Column>
                        {/* <Column field="app_cat" header="Category" body={CommonBodyForTable} sortable ></Column> */}
                        <Column field="subscriptionName" header="Subscription Name" body={CommonBodyForTable} sortable ></Column>
                        <Column field="ownerName" header="Lead / Account" body={CommonBodyForTable} sortable ></Column>
                        <Column field="subscriptionType" header="Subscription Type" body={subTypeBodyTemplate} sortable ></Column>
                        {/* <Column field="renatlOption" header="Rental Option" body={CommonBodyForTable} sortable ></Column> */}
                        <Column field="userType" header="User Type" body={CommonBodyForTable} sortable ></Column>
                        <Column field="cupPrice" header="Cup Price" body={CommonBodyForTable} sortable ></Column>
                        <Column field="date" header="Date & Time" body={DateBodyForTable} sortable ></Column>
                        <Column header="Actions" body={actionBodyTemplate} exportable={false} style={{ width: '12rem' }}></Column>
                    </DataTable>
                </div>
            </div>
            </div>
        }

        </TabPanel>

        <TabPanel header="PLACED CVES ORDERS">
            <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                <Toast ref={toast} />
                <DataTable ref={dt1}  value={placedOrderData}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Orders"
                    globalFilter={globalFilter1} emptyMessage="No data found." header={header2} loading1={loading1} exportFilename={`Placed CVES Orders`} >
                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                    <Column field="machineId" header="CVES Id" body={CommonBodyForTable} sortable ></Column>
                    <Column field="subid" header="Subscription Id" body={subidBodyTemplate} sortable ></Column>
                    <Column field="orderId" header="Order Id" body={CommonBodyForTable} style={{ overflowWrap:'break-word' }} sortable ></Column>
                    <Column field="SDid" header="Sales Order Id" body={CommonBodyForTable} style={{ overflowWrap:'break-word' }} sortable ></Column>
                    <Column field="subscriptionType" header="SubScription Type" body={typeBodyTemplate} sortable ></Column>
                    <Column field="name" header="Account Name" body={companyBodyTemplate} sortable ></Column>
                    <Column field="cupPrice" header="Cup Price" body={CommonBodyForTable} sortable ></Column>
                    <Column field="date" header="Date" body={DateBodyForTable} sortable ></Column>
                    <Column header="Actions" body={actionBodyTemplate1} exportable={false}></Column>
                </DataTable>
                </div>
            </div>
            </div>
        </TabPanel>
        </TabView>
        </div>
        {/* getFgMachines */}
        <OnboardOrderMachine 
            onBoardDialog={onBoardDialog} 
            setOnBoardDialog={setOnBoardDialog} 
            selectedMachineData={selectedMachineData} 
            fgMachines={fgMachines} machineType={'orderMachine'} 
            getOrders={getOrders} 
            getPlacedOrders={getPlacedOrders} 
            setSelectedMachineData={setSelectedMachineData} 
            onboardingLoading={onboardingLoading}
            setOnboardingLoading={setOnboardingLoading}
        />

        <Dialog visible={viewOrderDialog} style={{ width: '650px' }} header={`Order Details (${selectedMachineData?.orderId ? selectedMachineData?.orderId : '-'})`} modal footer={viewOrderFooter}  onHide={()=>{setViewOrderDialog(false)}} className='p-fluid' maximizable={true} blockScroll={true}>
            <div className='p-grid'>
                <div className="p-col-12">
                    <div className=" widget-performance">
                        <div className="content">
                            <ul>
                            
                            <div className='p-grid'>
                            <CommonForDetails name="Subscription Category" value={selectedMachineData?.subscriptionType == 'permanent' ? 'Commercial' : 'Demo'} />
                            <CommonForDetails name="Subscription Name" value={selectedMachineData?.subscriptionName} />
                            <CommonForDetails name="Order Id" value={selectedMachineData?.orderId} />
                            <CommonForDetails name="Lead/User Id" value={selectedMachineData?.leadData?.length > 0 ? selectedMachineData?.leadData[0]?.userid : selectedMachineData?.userData[0]?.userid } />
                            <CommonForDetails name="Account Type" value={selectedMachineData?.userType} />
                            <CommonForDetails name="Tapri Package" value={selectedMachineData?.renatlOption} />
                            <CommonForDetails name="Canister Type" value={selectedMachineData?.ctvmCanister} />
                            <CommonForDetails name="Ordered Date" value={new Date(selectedMachineData?.date).toLocaleString()} />
                            <CommonForDetails name="Sales Order Id" value={selectedMachineData?.SDid ? selectedMachineData?.SDid : 'NA'} />
                            </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>

        <Dialog visible={viewPlacedOrderDialog} style={{ width: '650px' }} header={`Placed Order Details (${selectedMachineData?.orderId ? selectedMachineData?.orderId : '-'})`} modal footer={viewPlacedOrderFooter}  onHide={()=>{setViewPlacedOrderDialog(false)}} className='p-fluid' maximizable={true} blockScroll={true}>
            <div className='p-grid'>
                <div className="p-col-12">
                    <div className=" widget-performance">
                        <div className="content">
                            <ul>
                            <div className='p-grid'>
                            <CommonForDetails name="CVES Id" value={selectedMachineData?.machineId} />
                            <CommonForDetails name="Subscription Id" value={selectedMachineData?.subid?.toString().length == 9 ? selectedMachineData?.subid: '000000' + selectedMachineData?.subid} />
                            <CommonForDetails name="Order Id" value={selectedMachineData?.orderId } />
                            <CommonForDetails name="Subscription Type" value={selectedMachineData?.subscriptionType } />
                            <CommonForDetails name="Account Name" value={selectedMachineData?.userData?.length > 0 ? selectedMachineData?.userData[0].name : 'NA'} />
                            <CommonForDetails name="User Id" value={selectedMachineData?.userData?.length > 0 ? selectedMachineData?.userData[0].userid : 'NA'} />
                            <CommonForDetails name="Sales Order Id" value={selectedMachineData?.SDid} />
                            </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>

        <Dialog visible={confirmDeleteDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDialogFooter} onHide={(e)=>{setConfirmDeleteDialog(false)}}>
            <div className="confirmation-content">
                <center>
                    <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br/><br/>
                    <b><span>Are you sure you want to delete Order {selectedMachineData?.orderId} ?</span></b> <br/><br/>
                </center>
            </div>
        </Dialog>

        <Dialog visible={multiOrderDialog} style={{ width: '650px' }} header="Multiple Order Details" modal footer={multiOrderFooter} onHide={(e)=>{setMultiOrderDialog(false)}}>
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">
                <DataTable  value={null}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Orders"
                    emptyMessage="No data found." exportFilename={`Placed CVES Orders`} >
                    <Column field="srid" header="Sr.No."  body={srBodyTemplate} style={{ width: '4rem' }} exportable={false}></Column>
                    <Column field="machineId" header="CVES Id" body={CommonBodyForTable} sortable ></Column>
                    <Column field="subid" header="Subscription Id" body={subidBodyTemplate} sortable ></Column>
                    <Column field="orderId" header="Order Id" body={CommonBodyForTable} style={{ overflowWrap:'break-word' }} sortable ></Column>
                    <Column header="Actions" body={actionBodyTemplate1} exportable={false}></Column>
                </DataTable>
                </div>
            </div>
            </div>

        </Dialog>

        

        </>
    )

}