import React, { useState, useEffect } from "react";
import { SubAnalyticalStat, SubAnalyticalStatForMachineCount } from "./SubAnalyticalStat";
import { usageCalculation } from "./Calculations";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
// Common
import { CommonBodyForTable, srBodyTemplate, DateBodyForTable, DateMonthBodyForTable } from "../Common/CommonComponents";
// Services
import io from "socket.io-client";
import ApiServices from "../../service/api/ApiServices";
import { managementCheck } from "../../service/admin/superadmin";
import { getFormattedAmount, getFormattedStatForAnalytics } from "../Common/CommonFunctions";
import { useRef } from "react";

const apiServices = new ApiServices()
const socket = io(process.env.REACT_APP_IOT);

// Logged User Information
const initialValue = JSON.parse(localStorage.getItem("user"));

// ==============================================================================
// ==============================================================================



export const AnalyticalStat = (props) => {

    const [DemoLiveData, setDemoLiveData] = useState([])
    // const [flavourChartData,setFlavourChartData] = useState([])
    // const [flavourData,setFlavourData] = useState([])
    // const [flavourNameData,setFlavourNameData] = useState([])
    // const [flavourColorData,setFlavourColorData] = useState([])
    const [userStatsTemp, setUserStatsTemp] = useState([])
    // const [loading, props.masterLoader] = useState(true)
    const [superstarValue, setSuperstarValue] = useState(null);
    const [CTVMDemoData, setCTVMDemoData] = useState({ machineCount: '-', cupConsumption: '-' })
    // const [totalCupConsumption, setTotalCupConsumption] = useState('');
    const [organicCupConsumption, setOrganicCupConsumption] = useState('');
    const [inorganicCupConsumption, setInorganicCupConsumption] = useState('');
    const [staticOrganicCupConsumption, setStaticOrganicCupConsumption] = useState('');
    const [inorganicMachineCount, setInorganicMachineCount] = useState('');
    const [timeSlot, setTimeSlot] = useState('this_month')
    const [throttleValue, setThrottleValue] = useState(true)
    const [filteredDate, setFilteredDate] = useState(null)
    const [singleMachineConsumption, setSingleMachineConsumption] = useState(null)
    const [singleMachine, setSingleMachine] = useState(false)
    const [machineArr, setMachineArr] = useState([])
    const [viewFilterMachinesDialog, setViewFilterMachinesDialog] = useState(false)
    const [globalFilter, setGlobalFilter] = useState(null);
    const [materialType, setMaterialType] = useState([]);
    const [singleMachineData, setSingleMachineData] = useState(null);

    const [filterCommercialCVES, setFilterCommercialCVES] = useState(null);
    const [cvesListCount, setCvesListCount] = useState(null);
    const [customTypeFilter, setCustomTypeFilter] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedQuarter, setSelectedQuarter] = useState(null);
    const [filterMachineLoader, setFilterMachineLoader] = useState(false);
    const [tillMonthArray, setTillMonthArray] = useState([]);

    const dt = useRef();

    useEffect(() => {
        setMaterialType(props.materialType)
    }, [props.materialType])

    useEffect(() => {
        getData(props.userStatParam)
    }, [props.userStatParam])

    useEffect(() => {
        getdate(props.filteredDate)
    }, [props.filteredDate])

    useEffect(() => {
        setMachineArr(props.allMachineData)
    }, [props.allMachineData])

    useEffect(() => {
        if (props.machinesForCount?.length == 1) {
            setSingleMachineData(props.machinesForCount[0])
        } else {
            setSingleMachineData(null)
        }
    }, [props.machinesForCount])



    const exportCSV = () => {
        dt.current.exportCSV();
    }


    const filterMachineByDate = (startDate, endDate) => {
        const start = new Date(startDate).getTime();
        const end = new Date(endDate).getTime();
        // console.log('props.machinesForCount', props.machinesForCount);
        return props.machinesForCount.filter(cves => {
            const cvesStart = new Date(cves.subscriptionDate).getTime();
            return (cvesStart >= start && cvesStart <= end && cves.site == 'permanent');
        });

    }


    const AssignCvesCups = async (cvesList, cupsArray) => {
        // console.log('cvesList', cvesList, cupsArray);
        for await (let [index, value] of cvesList.entries()) {
            value.cupsCount = cupsArray[index];
            // console.log('value', value, 'index', index);
        }
        return cvesList;

    }



    const getdate = (data) => {
        if (data) {
            let startDate;
            if (managementCheck()) {
                startDate = new Date(data?.startDate);
            } else {
                startDate = new Date('2019-12-31T18:30:00.000Z');
            }
            const endDate = new Date(data?.endDate);
            const finalDate = { startDate: `${new Date(startDate).getDate()}/${new Date(startDate)?.getMonth() + 1}/${new Date(startDate)?.getFullYear()}`, endDate: `${new Date(endDate).getDate()}/${new Date(endDate)?.getMonth() + 1}/${new Date(endDate)?.getFullYear()}` }
            // console.log('finalDate', finalDate)
            setFilteredDate(finalDate)
            const filter = filterMachineByDate(startDate, endDate);
            // const newfilter = filter.map((x) => { return { subid: x.subid, "subscriptionDate": x.subscriptionDate } })
            console.log('Onboarded Filter Machine', filter);
            setFilterCommercialCVES(filter);
        }
    }


    const getData = (data) => {
        // console.log(props.isKettleSelectedForStat);
        // console.log("getData", data);
        if (data) {
            props.setMasterLoader(true)
            const { params, time, customType, month, quarter } = data;

            // console.log('params', params);

            if (customType?.code == 'monthwise') {
                setCustomTypeFilter('monthwise')
                setSelectedMonth(month);
                setSelectedQuarter(null);
            } else if (customType?.code == 'quarterwise') {
                setCustomTypeFilter('quarterwise');
                setSelectedMonth(null);
                setSelectedQuarter(quarter);
            } else {
                setCustomTypeFilter(null);
                setSelectedMonth(null);
                setSelectedQuarter(null);
            }

            if (props.isKettleSelectedForStat) {
                if (params?.id?.length == 1) {
                    setSingleMachine(true)
                } else {
                    setSingleMachine(false)
                }
                getUserStaticStats(params)
            } else {
                setTimeSlot(time)
                const liveTimes = ['all', 'today', 'this_week', 'this_month'];
                const pastTimes = ['yesterday', 'last_month', 'custom'];

                if (liveTimes.includes(time)) {
                    if (params?.id?.length == 1) {
                        setSingleMachine(true)
                        getMachineLiveData(params)
                    } else {
                        setSingleMachine(false)
                        getUserStats(params)
                    }
                } else if (pastTimes.includes(time)) {
                    if (params?.id?.length == 1) {
                        setSingleMachine(true)
                    } else {
                        setSingleMachine(false)
                    }
                    getUserStaticStats(params)
                }
            }
        }
    }



    const getUserStaticStats = async (data) => {
        try {
            // console.log('getUserStaticStats', data);
            const userStatsResponse = await apiServices.totalCupConsumption(data);
            const totalConsumption = getCommonOps(materialType, userStatsResponse?.data?.data);
            setStaticOrganicCupConsumption(totalConsumption);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            props.setMasterLoader(false);
        }
    };

    const getUserStats = async (data) => {
        if (data) {
            const response = await apiServices.totalCupConsumption(data);
            const totalConsumption = getCommonOps(materialType, response?.data?.data);
            setOrganicCupConsumption(totalConsumption)
            const Livearr = [];
            socket.on("consumption", data1 => {
                if (machineArr?.includes(data1?.data?.data?.id)) {
                    Livearr.push(data1)
                    setDemoLiveData(Livearr)
                    socketCups(Livearr, totalConsumption);
                }
            });

            props.setMasterLoader(false)
        }
    }

    const getMachineLiveData = async (data) => {
        console.log("============Single Machine Live Data ====================");

        var response = await apiServices.totalCupConsumption(data);
        const totalConsumption = getCommonOps(materialType, response?.data?.data);
        // console.log(totalConsumption);
        setSingleMachineConsumption(totalConsumption)
        const Livearr = [];
        socket.on("consumption", data1 => {
            // console.log({ 'New Cup Consumption on ': data1?.data?.data?.id });
            if (data1?.data?.data?.id == data?.id[0]) {
                console.log(data1?.data?.data?.id)
                Livearr.push(data1)
                setDemoLiveData(Livearr)
                setSingleMachineConsumption(totalConsumption + Livearr.length)
            }
        });

        props.setMasterLoader(false)
    }

    const getCommonOps = (data1, data2) => {
        const arr = [];
        data1?.forEach((element) => {
            const materialName = element.materialName;
            data2?.brvStats.map((x) => {
                if (materialName == x._id) {
                    arr.push({ ...x, color: element.color })
                }
            });
        });
        arr.sort((a, b) => Number(b.totalCup) - Number(a.totalCup));

        // console.log('Flavor', arr);

        props.setFlavourWiseData(arr);
        // let result = arr.map(a => a.totalCup);
        // let resultedNames = arr.map(a => a._id);
        // let resultedColors = arr.map(a => a.color);

        // setFlavourChartData(arr)
        // setFlavourData(result)
        // setFlavourNameData(resultedNames)
        // setFlavourColorData(resultedColors)
        // setUserStatsTemp(arr)

        let superStarCal = data2?.brvStats?.sort((a, b) => b.totalCup - a.totalCup);
        let superStarCalc = superStarCal ? superStarCal[0] : null;
        setSuperstarValue(superStarCalc)
        let totalConsumption = data2?.usagesStats[0]?.totalCup;
        const cves_count = data2?.cvesCount || 0;
        // calulateCups(filterCommercialCVES?.length, totalConsumption);
        console.log("cves_count", cves_count);

        const lastMonthsArray = data2?.monthlyArr || [];
        setTillMonthArray(lastMonthsArray)
        console.log('lastMonthsArray', lastMonthsArray);

        setCvesListCount(cves_count);
        // console.log('cvesListCount', cvesListCount)
        props.setMasterLoader(false)

        return totalConsumption;
    }

    const socketCups = (Livearr, totalConsumption) => {
        // console.log(totalConsumption);
        let organicCups = totalConsumption + Livearr.length;
        setOrganicCupConsumption(organicCups)
    }

    // const assignCupCount = async (filterCommercialCVES, consumptionArray) => {

    //     console.log('calulateCups', filterCommercialCVES?.length, consumptionArray, selectedMonth); 
    //     const newArr = filterCommercialCVES?.map((item, index)=> {
    //         return {
    //             ...item,
    //             cupsCount: consumptionArray[index]
    //         }
    //     });
    //     console.log('newArr', newArr);
    //     setFilterCommercialCVES(newArr);

    //     // for await (let [value, index] of filterCommercialCVES?.enteries()){
    //     //     console.log(value, index);
    //     //     // value.cupsCount = 
    //     // }

    //     // if( managementCheck()){
    //     //     const consumptionArray = await usageCalculation(machineCount, cupsCount);
    //     //     console.log('consumptionArray', consumptionArray);
    //     // }
    // }

    const viewMachines = async () => {
        if (managementCheck() || initialValue?.userType == 'fintech') {
            // if(timeSlot == 'custom'){   
            setViewFilterMachinesDialog(true);
            setFilterMachineLoader(true);

            console.log('setTillMonthArray', tillMonthArray);

            // const cupsDistribution = [ ... ]

            const monthSelected = tillMonthArray?.length > 0 ? tillMonthArray[0]?.month : null;

            if (tillMonthArray?.length > 0) {
                for await (let value of tillMonthArray) {
                    // console.log('value', value);
                    const cupsArray = usageCalculation(value?.cvesCount, value?.cupCount, monthSelected);
                    // console.log('cupsArray', cupsArray)
                    const filterCVES = await filterMachineByDate(value?.startDate, value?.endDate);
                    const cvesWithCups = await AssignCvesCups(filterCVES, cupsArray);
                    // console.log('filterCVES', filterCVES)
                    // value.cupsArray = filterCVES;
                    // value.cupsArray = cupsArray;
                    value.cvesWithCups = cvesWithCups;
                }
                // console.log('setTillMonthArray', tillMonthArray);
                const cvesArray = await tillMonthArray.map(x => x.cvesWithCups).flat();
                // console.log('cvesArray', cvesArray);
                setFilterCommercialCVES(cvesArray);
                setFilterMachineLoader(false)
            } else {
                const liveTimes = ['all', 'today', 'this_week', 'this_month'];

                console.log('filterCommercialCVES', filterCommercialCVES, 'liveTimes', liveTimes);

                const consumptionArray = usageCalculation(filterCommercialCVES?.length, liveTimes?.includes(timeSlot) ? organicCupConsumption : staticOrganicCupConsumption);
                const newArr = filterCommercialCVES?.map((item, index) => {
                    return {
                        ...item,
                        cupsCount: consumptionArray[index]
                    }
                });
                console.log('cves', newArr);
                setFilterCommercialCVES(newArr);
                setFilterMachineLoader(false)
            }
        }

    }

    const addBodyForTable = (data, props) => (<> <span className="p-column-title">{props.header}</span>{data?.mchLocation?.addr ? data?.mchLocation?.addr : 'NA'} </>)
    const brandBodyForTable = (data, props) => {
        const brandLookup = { 'TAPRI': 'Tapri Kiosk', 'TKAFFE': 'Tkaffe Kiosk', 'MERI_TAPRI': 'Tapri Kettle' };
        return (<> <span className="p-column-title">{props.header}</span>{brandLookup[data[props.field]] || ''} </>)
    }

    const viewMachineFooter = () => (<button className="p-button p-button p-button-danger" onClick={() => { setViewFilterMachinesDialog(false) }}><span className="p-button-icon p-c pi pi-times p-button-icon-left"></span><span className="p-button-label p-c">Close</span><span className="p-ink"></span></button>)

    const header = (
        <div className="p-grid">
            <div className="p-col-8"></div>
            <div className="p-col-4">
                <div className="table-header">
                    <span className="p-input-icon-right">
                        <div className="p-inputgroup p-m-1">
                            <Button className='custom-group-btn' icon="pi pi-search" />
                            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                            <Button icon="pi pi-download" title='Download Report' className="p-button-rounded p-button-primary p-ml-2 p-mb-2" onClick={exportCSV} />
                        </div>

                    </span>
                </div>
            </div>
        </div>
    );


    return (
        <>
            {
                props.filter != 'meri_tapri' ?

                    <div className="p-grid p-my-5">

                        {
                            <>

                                {
                                    (props.customerValue || props.machinesForCount?.length == 1) ?
                                        <SubAnalyticalStat header="Company Name" value={props.customerValue ? props.customerValue : singleMachineData?.siteName} icon='pi pi-bars side-icon' loading={props.masterLoader} /> : (((managementCheck() || initialValue?.userType == 'fintech') && timeSlot == 'all') || (!managementCheck() && initialValue?.userType != 'fintech')) && <SubAnalyticalStat icon='pi pi-bars side-icon' header="Total CVES Onboarded (Till date)" value={props.machinesForCount?.length && props.machinesForCount?.length > 1 ? props.allMachineCount?.length : '-'} loading={props.masterLoader} />}


                                {
                                    (managementCheck() || initialValue.userType == 'fintech') &&
                                    (!props.customerValue && props.userStatParam?.params?.type != 'demo' && !singleMachine && props.machinesForCount?.length > 1 && (timeSlot != 'all' && timeSlot != 'today' && timeSlot != 'yesterday' && timeSlot != 'this_week' && timeSlot != 'this_month' )) &&
                                    <SubAnalyticalStatForMachineCount icon='pi pi-bars side-icon' header={`Commercial Onboarded CVES`} value={managementCheck() ? cvesListCount : filterCommercialCVES?.length} loading={props.masterLoader} clickFunction={viewMachines} managementCheck={managementCheck()} userType={initialValue.userType} />

                                    // (customTypeFilter == 'monthwise' || customTypeFilter == 'quarterwise') ? filterCommercialCVES?.length: cvesListCount}

                                }

                                <SubAnalyticalStat icon="pi pi-star" header="SuperStar Beverage" value={superstarValue?._id ? superstarValue?._id : '-'} loading={props.masterLoader} />

                                {
                                    ((!singleMachine && (timeSlot == 'all' || timeSlot == 'today' || timeSlot == 'this_week' || timeSlot == 'this_month') && !props.isKettleSelectedForStat)) &&
                                    <SubAnalyticalStat icon="pi pi-calendar" header="Cup Consumption (Live)" value={organicCupConsumption ? getFormattedStatForAnalytics(organicCupConsumption) : '-'} loading={props.masterLoader} />
                                }

                                {
                                    ((!singleMachine && (timeSlot == 'yesterday' || timeSlot == 'last_month')) || props.isKettleSelectedForStat) &&
                                    <SubAnalyticalStat icon="pi pi-calendar" header="Cup Consumption" value={staticOrganicCupConsumption ? getFormattedStatForAnalytics(staticOrganicCupConsumption) : '-'} loading={props.masterLoader} />
                                }

                                {
                                    (!singleMachine && (timeSlot == 'custom') && !props.isKettleSelectedForStat) &&
                                    <SubAnalyticalStat icon="pi pi-calendar" header={`Cup Consumption`} value={staticOrganicCupConsumption ? getFormattedStatForAnalytics(staticOrganicCupConsumption) : '-'} loading={props.masterLoader} />
                                }

                                {
                                    (singleMachine && (timeSlot == 'all' || timeSlot == 'today' || timeSlot == 'this_week' || timeSlot == 'this_month') && !props.isKettleSelectedForStat) &&
                                    <SubAnalyticalStat icon="pi pi-calendar" header="Cup Consumption By CVES (Live)" value={singleMachineConsumption ? getFormattedStatForAnalytics(singleMachineConsumption) : '-'} loading={props.masterLoader} />
                                }

                                {
                                    (singleMachine && (timeSlot == 'yesterday' || timeSlot == 'last_month') && !props.isKettleSelectedForStat) &&
                                    <SubAnalyticalStat icon="pi pi-calendar" header="Cup Consumption" value={staticOrganicCupConsumption ? getFormattedStatForAnalytics(staticOrganicCupConsumption) : '-'} loading={props.masterLoader} />
                                }

                                {
                                    (singleMachine && (timeSlot == 'custom') && !props.isKettleSelectedForStat) &&
                                    <SubAnalyticalStat icon="pi pi-calendar" header={`Cup Consumption (${filteredDate?.startDate} - ${filteredDate?.endDate})`} value={staticOrganicCupConsumption ? getFormattedStatForAnalytics(staticOrganicCupConsumption) : '-'} loading={props.masterLoader} />
                                }

                                {
                                    (props.onlineData && initialValue?.userType != "customer") &&
                                    <>
                                        <SubAnalyticalStat icon="pi pi-calendar" header='RFID' value={props.onlineData?.online ? props.onlineData?.online : 0} />
                                        <SubAnalyticalStat icon="pi pi-calendar" header='Paytm' value={props.onlineData?.paytm ? props.onlineData?.paytm : 0} />
                                    </>
                                }

                            </>
                        }

                    </div>
                    :
                    <div className="p-grid p-my-5">

                        {
                            props.selectedkettle ?
                                <SubAnalyticalStat icon="pi pi-bars" header="Site Name" value={props.selectedkettle ? props.selectedkettle : 'NA'} />
                                :
                                <SubAnalyticalStat icon="pi pi-bars" header="No. of Tapri Kettles's" value={props.totalMachineCount?.length ? props.totalMachineCount?.length : '-'} />
                        }

                        <SubAnalyticalStat icon="pi pi-star" header="SuperStar" value={props.tapriSuperstar ? props.tapriSuperstar : '-'} />
                        <SubAnalyticalStat icon="pi pi-bars" header="Total Consumption" value={props.totalTapriConsumption ? props.totalTapriConsumption : 0} />
                    </div>

            }

            <Dialog visible={viewFilterMachinesDialog} style={{ width: '1150px' }} header={`CVES Consumption ${selectedMonth ? selectedMonth : `(${filteredDate?.startDate} - ${filteredDate?.endDate})`} Details`} footer={viewMachineFooter} onHide={() => { setViewFilterMachinesDialog(false) }} modal className="p-fluid" maximizable={true} blockScroll={true}>
                <div className="card">
                    <div className="p-grid table-demo p-m-2">
                        <DataTable ref={dt} value={filterCommercialCVES} className="p-datatable-customers" dataKey="id" rowHover emptyMessage="No Data found."
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                            globalFilter={globalFilter} header={header}
                            exportFilename={`CVES Consumption ${selectedMonth ? selectedMonth : `(${filteredDate?.startDate} - ${filteredDate?.endDate})`} Details`} loading={filterMachineLoader}>
                            <Column field="id" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                            <Column field="id" header="CVES ID" body={CommonBodyForTable} ></Column>
                            <Column field="finance_machineId" sortable header="CVES ID" body={CommonBodyForTable} ></Column>

                            <Column field="subid" header="Subscription ID" body={CommonBodyForTable} ></Column>
                            <Column field="siteName" header="Subscription Name" body={CommonBodyForTable} ></Column>
                            <Column field="cupsCount" sortable header="Cup Consumption" body={CommonBodyForTable} ></Column>
                            <Column field="subscriptionDate" header="Subscription Date" sortable body={DateMonthBodyForTable} ></Column>
                            <Column field="brand" header="Brand" body={brandBodyForTable} ></Column>
                        </DataTable>
                    </div>
                </div>
            </Dialog>

        </>
    )
}


