import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";
import classNames from 'classnames';
import { CommonBodyForFormattedStrTable, CommonBodyForTable, amountBodyForTable, srBodyTemplate, statusBodyTemplate } from '../Common/CommonComponents';
import { commonCloseDialogFooter } from '../Common/CommonComponents';
import { CommonForDetails } from './OngoingOrders';
import { imageBodyTemplate } from './OngoingOrders';
import { DetailedOrder } from './OngoingOrders';
import ApiServices from '../../service/api/ApiServices';
import { getFormattedString } from '../Common/CommonFunctions';

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

const apiServices = new ApiServices();

// =====================================================================
// =====================================================================

export const RefillOrdersData = (props) => {

    const [refillOrderData, setRefillOrderData] = useState([])
    const [detailedOrderDialog, setDetailedOrderDialog] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState(false)
    const [allMaterials, setAllMaterials] = useState(false)
    const [creditLimitValue, setCreditLimitValue] = useState(null)
    
    useEffect(() => {
        getData()
    }, [])

    const getData = async() =>{
        let data = []
        const response = await apiServices.getMyOrders({ "index": 0});
        console.log(response?.data?.response_obj?.ongoing);
        const ongoing = response?.data?.response_obj?.ongoing || []; // added or condition by ND
        const completed = response?.data?.response_obj?.completed || []; // added or condition by ND
        data = [...ongoing,...completed];
        setRefillOrderData(data)
        // setRefillOrderData(ongoing)
    }

    const openOrderDetailed = (rowData) =>{
        console.log(rowData);
        setDetailedOrderDialog(true)
        setSelectedOrder(rowData)
        getMat(rowData)
    }

    const getMat = (data) =>{
        const res =  data?.combos?.map((x)=>{
            const data = props.allMaterials.filter((y)=>{ return x.id == y._id})
            if(data?.length > 0){
                return {...x, materialName : data[0]?.title, image : data[0]?.image }
            }else{
                return {...x, materialName : 'NA', image : ''}
            }
        })
        setAllMaterials(res)
    }

    const getCreditLimit = async(data) =>{
        const response = await apiServices.getCreditLimit(data)
        setCreditLimitValue(response?.data?.response_obj?.Pending_Payment_Amount)
    }
    
    const orderActionTemplate = (rowData) =>{
        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{display:"flex"}}>
                    <Button icon="pi pi-eye" title='View Order Details' className="p-button-rounded p-button-primary p-mr-2 p-mb-2" onClick={()=>{openOrderDetailed(rowData)}}  />
                </div>
            </>
        )
    }

    const statusBodyTemplate1 = (rowData, props) => {
        const status = rowData[props.field]?.replaceAll("ORDER_", " ");
        return ( <> <span className="p-column-title">{props.header}</span>{status ? getFormattedString(status) : '-' }</>)
    }

    return (
        <>
            <div className="p-grid crud-demo">
                <div className="p-col-12">  
                    <div className="card">
                        <DataTable value={refillOrderData} paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive" responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} data" emptyMessage="No data found.">
                            <Column field="srno" header="Sr.No." sortable body={srBodyTemplate} style={{width:'8rem'}}></Column>
                            { initialValue?.userType != 'customer' && <Column field="company_name" header="Account Name" sortable body={CommonBodyForTable} ></Column> }
                            <Column field="order_no" header="Order Id" sortable body={CommonBodyForTable} style={{ overflowWrap:'break-word' }} ></Column>
                            <Column field="payment_mode" header="Payment Mode" sortable body={statusBodyTemplate} ></Column>
                            {/* <Column field="payment_status" header="Payment Status" sortable body={CommonBodyForFormattedStrTable} ></Column> */}
                            <Column field="status" header="Order Status" sortable body={statusBodyTemplate1} ></Column>
                            <Column field="total_price" header="Total Amount" sortable body={amountBodyForTable} ></Column>
                            <Column field="createdAt" header="Created Date & Time" sortable body={CommonBodyForTable} ></Column>
                            <Column header="View Details" body={orderActionTemplate}></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <DetailedOrder 
                detailedOrderDialog={detailedOrderDialog} 
                setDetailedOrderDialog={setDetailedOrderDialog} 
                selectedOrder={selectedOrder} 
                allMaterials={allMaterials} 
                type='refill'
            />

        </>
    )
}
