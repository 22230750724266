import React, { useState, useEffect, useRef, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { InputSwitch } from 'primereact/inputswitch';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Error } from '../ErrorPage/Error';
import { Galleria } from 'primereact/galleria';
// Contants
import { applicationCategoryItems, applicationCategoryItems1 } from '../Common/Constants';
import { responsiveOptions, customerTypeOptions, billingPatternOptions, invoiceCycleOptions, maintenancePackages, maintainenceterms, typeOfDeposite, dropdownItems, demoPurposeDropdownItems, despensingOptionDropdownList, cupRechargeDropdownList, paymentDefault, multiselectValues, packageDropdownList, statusOptions } from './Constants';
import { pantryInfoSchema, pantryAuthSchema, pantryAutorizationTem, onboardMainTemp, supermans, paidBeverageSetting, corefacilityTemp, facilityTemplate, facAddresstemp, facBillingDetails } from './Constants';
// Common
import { srBodyTemplate, CommonBodyForTable, commonCloseDialogFooter, commonDialogFooter, commonFormDialogFooter, nestedBodyForTable, nestedDateBodyForTable1 } from '../Common/CommonComponents';
import { successToast, failureToast } from '../Common/CommonFunctions';
//Components 
import { AddLead } from '../Users/AddLead';
import { AddFacility } from '../Users/AddFacility';
import { MachineOnboardDataTableSkeleton } from '../Common/CommonComponents';
// Services
import ApiServices from '../../service/api/ApiServices';
import { managementCheck } from '../../service/admin/superadmin';
import { TabView } from 'primereact/tabview';
import { TabPanel } from 'primereact/tabview';
import { permissionContext } from '../../AppWrapper';
import { Chips } from 'primereact/chips';

const initialValue = JSON.parse(localStorage.getItem("user"));
const apiServices = new ApiServices();

export const CtvmOnBoard = (props) => {

    const rolePermissions = useContext(permissionContext);

    const [loading, setLoading] = useState(true);
    const [useid, setUserid] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [facilityDialog, setFacilityDialog] = useState(false);
    const [pantryDialog, setPantryDialog] = useState(false);
    const [switchDialog, setSwitchDialog] = useState(false);
    const [uploaderDialog, setUploaderDialog] = useState(false);
    const [agreementDialog, setAgreementDialog] = useState(false);
    const [demoDialog, setDemoDialog] = useState(true);
    const [commercialDialog, setCommercialDialog] = useState(false);
    const [deletePantryDialog, setDeletePantryDialog] = useState(false);
    const [deleteCVESDialog, setDeleteCVESDialog] = useState(false);
    const [deletePantryIndex, setDeletePantryIndex] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [subStatus, setSubStatus] = useState(true);
    const [multiselectValue, setMultiselectValue] = useState({ name: 'All' });
    // const [multiselectValue, setMultiselectValue] = useState({ name: 'Commercial' });
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [globalFilter1, setGlobalFilter1] = useState('');
    const [loading1, setLoading1] = useState(true);
    const [packageItem, setPackageItem] = useState(null);
    const [machine, setMachine] = useState(null);
    const [selectedrechargeOp, setSelectedrechargeOp] = useState(null);
    const [subcatItem, setSubcatItem] = useState({ name: 'Demo', code: 'Demo' });
    const [machineDropdownItem, setMachineDropdownItem] = useState(null);
    const [demoPurposeItem, setDemoPurposeItem] = useState(null);
    const [subscriptionDe, setSubscriptionDe] = useState(null);
    const [defaultWtrOffset, setDefaultWtrOffset] = useState(0);
    const [allFac, setAllFac] = useState(null);
    const [CVESMachines, setCVESMachines] = useState(null);

    const [onboardedMasterData, setOnboardedMasterData] = useState(null);
    const [onboardMachines, setOnboardMachines] = useState(null);
    const [fgMachines, setFgMachines] = useState(null);
    const [fgMachinesToOnboard, setFgMachinesToOnboard] = useState([]);
    const [editMachineData, setEditMachineData] = useState(null);
    const [userReduced, setUserReduced] = useState(null);
    const [statusConfirmDialog, setStatusConfirmDialog] = useState(false);
    const [errorDialog, setErrorDialog] = useState(null);
    const [selectedMachineForM, setSelectedMachineForM] = useState(null)
    const [marketingUsers, setMarketingUsers] = useState(null)
    const [selectedMuser, setSelectedMuser] = useState(null);
    const [keyAccManagers, setKeyAccManagers] = useState(null)
    const [selectedKAM, setSelectedKAM] = useState(null);
    const [taprimans, setTaprimans] = useState(null)
    const [selectedTapriman, setSelectedTapriman] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [billingPattern, setBillingPattern] = useState(null);
    const [invoiceCycle, setInvoiceCycle] = useState(null);
    const [account, setAccount] = useState(false);
    const [formAction, setFormAction] = useState(false);
    const [accounts, setAccounts] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedAcc, setSelectedAcc] = useState(false);
    const [company, setCompany] = useState(null);
    const [pantryBoyDetails, setPantryBoyDetails] = useState(null);
    //PantryBoy
    const [addBoyDialog, setAddBoyDialog] = useState(false);
    const [pantryBoyInfo, setPantryBoyInfo] = useState(pantryInfoSchema);
    const [pantryBoyAuthInfo, setPantryBoyAuthInfo] = useState(pantryAuthSchema);
    const [pantryAuthInfo, setPantryAuthInfo] = useState(pantryAutorizationTem);
    const [editBoyData, setEditBoyData] = useState(null);
    //Switch
    const [switchMachine, setSwitchMachine] = useState(null);
    const [switchCheckStatus, setSwitchCheckStatus] = useState(true);
    //Agreement
    const [agreementList, setAgreementList] = useState(null);
    //Lead
    const [leads, setLeads] = useState(false);
    const [allLeads, setAllLeads] = useState(null);
    const [selectedLeads, setSelectedLeads] = useState(null);
    const [selectedLds, setSelectedLds] = useState(false);
    //Facility
    // const [facility, setFacility] = useState([{facname:'none'}]);
    const [facility, setFacility] = useState([]);
    const [facilityItem, setFacilityItem] = useState(null);
    //prizing
    const [prizing, setPrizing] = useState(null);
    //onboard
    const [onboardTemp, setOnboardTemp] = useState(onboardMainTemp);
    const [onboardSupTem, setOnboardSupTemp] = useState(null);
    const [onboardPkgTem, setOnboardPkgTemp] = useState(null);
    // packageInfoTemp
    const [maintainenceItem, setMaintainenceItem] = useState(null);
    const [mainTermItem, setMainTermItem] = useState(null);
    const [dispensingItem, setDispensingItem] = useState(null);
    const [paidOptions, setPaidOptions] = useState(paidBeverageSetting);
    const [paytm, setPaytm] = useState(null);
    const [onlineRfidStatus, setOnlineRfidStatus] = useState(false);
    const [depositeType, setDepositeType] = useState(null);
    //Facility
    const [coreFacilityTemp, setCoreFacilityTemp] = useState(corefacilityTemp);
    const [facAddTemplate, setFacAddTemplate] = useState(null);
    const [savedFacility, setSavedfacility] = useState(null);
    //Agreement
    const [onStatusChange, setOnStatusChange] = useState(false);
    const [allAgreements, setAllAgreements] = useState(null);
    // Leads
    const [leadDialog, setLeadDialog] = useState(false);
    //Edit Onboard
    const [editmachineDialog, setEditmachineDialog] = useState(false)
    const [editPaymode, setEditPaymode] = useState(null)
    const [paymentValue, setPaymentValue] = useState([{ name: 'Default', code: 'default' }])
    const [editLoading, setEditLoading] = useState(false)
    // OwnerShip
    const [ownerShipDetails, setOwnerShipDetails] = useState(null)
    // Setting
    const [settingDialog, setSettingDialog] = useState(false)
    const [consumptionCheck, setConsumptionCheck] = useState(false)
    const [cardsCheck, setCardsCheck] = useState(false)
    const [counterCheck, setCounterCheck] = useState(false)
    const [pendingCheck, setPendingCheck] = useState(false)
    const [selectedCat, setSelectedCat] = useState(null)
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [machinePayload, setMachinePayload] = useState(null)
    const [kycDialog, setKycDialog] = useState(false)
    const [images, setImages] = useState(null);
    // status
    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0])
    const [verifyCustomerDialog, setVerifyCustomerDialog] = useState(false)
    const [isOtpClicked, setIsOtpClicked] = useState(false)
    // 
    const [verifyTemp, setVerifyTemp] = useState(null)
    const [deleteFlag, setDeleteFlag] = useState(false)

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getMachines()
        setUserid(initialValue)
        getLeads()

        // getFacilities()
        // getFac('ld-4ai17uql57ur8i4')
    }, [])


    const itemTemplate = (item) => { return (<><img src={item} alt={item?.alt} style={{ width: '100%', display: 'block' }} /></>) }
    const thumbnailTemplate = (item) => { return (<><img src={item} alt={item?.alt} style={{ display: 'block' }} /></>) }
    const bodyTemplate = (data, props) => (<><span className="p-column-title">{props.header}</span>{data[props.field]}</>)

    const getMachines = async (data) => {

        const payload = data ? data : machinePayload;

        let newPayload, newArr = [];


        if (initialValue?.userType == 'fintech') {
            const response = await apiServices.getFintechCves(newPayload);
            newArr = response?.data?.data && [...response?.data?.data];
        } else {
            if (managementCheck()) {
                newPayload = { ...payload, brand: ['TAPRI', 'TKAFFE', 'MERI_TAPRI'] }
            } else {
                newPayload = payload;
            }

            const response = await apiServices.findMachines(newPayload);
            // console.log(facResponse?.data?.data);


            if (managementCheck()) {
                const ioResponse = await apiServices.getIoMachines();
                console.log(ioResponse?.data?.data);
                newArr = [...response?.data?.data, ...ioResponse?.data?.data];

            } else {
                newArr = response?.data?.data && [...response?.data?.data];
            }

        }

        const facResponse = await apiServices.getActiveFacility();

        // console.log(newArr);
        const response1 = await apiServices.getUserReduced();
        // console.log(response1?.data?.data)
        const response2 = await apiServices.agreementsList();
        setAllAgreements(response2?.data?.data)

        let iniArr = []
        newArr?.map((x) => {

            response1?.data?.data.filter((y) => {
                if (x.customer == y.userid) {
                    const lastDigit = y.name?.toString().slice(-1);
                    const n = lastDigit.charCodeAt(0) - 65;
                    const ad = n % 2 ? x.cans + ' Lane' : x.cans + ' Lane '
                    // const installDate = x?.subscription?.startDate ? new Date(x?.subscription?.startDate) : 'NA'

                    const date = x?.subscription?.startDate ? new Date(x?.subscription?.startDate) : null;
                    const installDate = `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}/${new Date(date).getFullYear()}`

                    iniArr.push(
                        {
                            ...x,
                            accountName: y.name,
                            companyName: y?.billing_details?.company || x.siteName,
                            facilityId: x?.subscription?.facilitydata?.facid || 'NA',
                            facilityAddr: y?.addr?.addr,
                            city: y?.addr?.city?.name,
                            state: y?.addr?.state?.name || 'NA',
                            canLabel: ad,
                            installationDate: installDate,
                            stageLabel: x.subscription?.package?.site == 'permanent' ? 'Commercial' : 'Demo',
                            customerCno: y?.cno
                        }
                    )
                }
            })
        })

        const arr1 = [];
        iniArr.map((x) => {
            const data = facResponse?.data?.data?.filter((y) => { return y.facid == x.facilityId; })
            if (data?.length > 0) {
                arr1.push({ ...x, facLocation: data[0].address?.city?.name, facilityName: data[0].facname })
            } else {
                arr1.push({ ...x, facLocation: 'NA', facilityName: 'NA' })
            }
        })

        const arrWithKAM = []

        arr1.map((x) => {
            const acc = x?.superman?.accountmanager;
            const one = response1?.data?.data.filter((y) => { return y.userid == acc })
            if (one.length > 0) {
                arrWithKAM.push({ ...x, keyAccManager: one[0].name + ' ' + one[0].lastName })
            } else {
                arrWithKAM.push({ ...x, keyAccManager: 'NA' })
            }
        })

        const arr = []
        arrWithKAM.map((x) => {
            const checkUsername = obj => obj.id === x.id;
            const data = response2?.data?.data.some(checkUsername)
            arr.push({ ...x, agreementStatus: data })
            // subScriptionStatus : x?.subscription?.status
        })

        const onBoardmachines = arr?.filter((m) => { return m.datastore.stage === "finishedgoods" && m.subscription && (m.subscription.package?.site === "demo" || m.subscription?.package?.site === "permanent") && m.siteName != "" })
        const finishedGoodMachines = arr?.filter((m) => { return m.datastore.stage === "finishedgoods" && m.siteName === "" })
        setCVESMachines(onBoardmachines)
        // console.log(onBoardmachines);


        // const rupeshData = onBoardmachines.map((x)=>{
        //     return {
        //         companyName : x.companyName,
        //         Customer_Account_ID : x.Customer_Account_ID,
        //         keyAccManager : x.keyAccManager,
        //         accountmanagerId : x.superman?.accountmanager,
        //         customer : x.customer
        //     }
        // })

        // console.log(rupeshData);


        if (multiselectValue.name === 'All') {
            setOnboardMachines(onBoardmachines)
            setOnboardedMasterData(onBoardmachines)
        } else if (multiselectValue.name === 'Demo') {
            const demoM = onBoardmachines.filter((m) => { return m.subscription?.package?.site === "demo" && m.siteName != "" })
            setOnboardMachines(demoM)
            setOnboardedMasterData(demoM)
        } else if (multiselectValue.name === 'Commercial') {
            const commercialM = onBoardmachines.filter((x) => { return x.subscription?.package.site === "permanent" && x.siteName != "" })
            setOnboardMachines(commercialM)
            setOnboardedMasterData(commercialM)
        }

        setFgMachines(finishedGoodMachines)
        response1?.is_error && setErrorDialog({ error: response1?.is_error, msg: response1?.msg });
        setUserReduced(response1?.data?.data)
        const pantry = onBoardmachines?.filter((m) => { return m.id == selectedMachineForM?.id })
        setPantryBoyDetails(pantry ? pantry[0]?.admins : null)
        setLoading(false)
    }

    const getFGMachinesForOB = async (data) => {
        const response = await apiServices.findMachines(data);
        const finishedGoodMachines = response?.data?.data?.filter((m) => { return m.datastore.stage == "finishedgoods" && m.siteName == "" })
        setFgMachinesToOnboard(finishedGoodMachines)
    }



    const getTabsAllowed = (data) => {

        if (initialValue?.userType === 'customer') {
            return initialValue?.brand?.includes(data);
        } else {
            return false;
        }
    }

    const getFacilities = async () => {
        const facResponse = await apiServices.getallFacility();
        console.log(facResponse.data.data);
        const facilityOptions = facResponse.data.data;
        setAllFac(facilityOptions)
    }

    const changeAddBoard = (e) => {

        if (e.target.name === 'siteName') {
            let oneTemplate = { ...onboardTemp, [e.target.name]: e.target.value }
            setOnboardTemp(oneTemplate)
        }

        if (e.target.name === 'demositelocation' || e.target.name === 'demoduration' || e.target.name === 'expcupconsumption' || e.target.name === 'ctvm_cost' || e.target.name === 'depositamt' || e.target.name === 'ctvmCupCost') {
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.target.value })
        }

        if (e.target.name === 'demopurpose') {
            setDemoPurposeItem(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.target.value.code })
        }

        if (e.target.name === 'company') {
            let oneTemplate = { ...onboardTemp, billing_details: { [e.target.name]: e.target.value } }
            setOnboardTemp(oneTemplate)
        }

        if (e.target.name === 'select-lead') {
            setSelectedLeads(e.value);
            setSelectedLds(true);
            setOnboardTemp({ ...onboardTemp, customer: e.value?.userid })
        }

        if (e.target.name === 'dis-option') {
            setDispensingItem(e.value)
            setPaidOptions({ ...paidBeverageSetting, free: true })
        }

        if (e.target.name === 'onlineRfid') {
            setOnlineRfidStatus(e.value)
            setPaidOptions({ ...paidOptions, [e.target.name]: e.value })
        }

        if (e.target.name === 'dispPassword') {
            setPaidOptions({ ...paidOptions, [e.target.name]: e.target.value })
        }

        if (e.target.name === 'recharge-op') {
            setSelectedrechargeOp(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, paymentterms: e.value.code })
        }

        if (e.target.name === 'target_cups') {
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: Number(e.target.value) })
        }

        if (e.target.name === 'rentaloption') {
            setPackageItem(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, rentaloption: e.value.code })
        }

        if (e.target.name === 'packageName') {

            setMaintainenceItem(e.value)

            const durationMap = { 'monthly': 30, 'quarterly': 90, 'halfyearly': 180, 'yearly': 360 };

            if (durationMap?.hasOwnProperty(e.target.value.code)) {
                const duration = durationMap[e.target.value.code];
                setOnboardPkgTemp({
                    ...onboardPkgTem,
                    maintenance: {
                        [e.target.name]: e.target.value.code,
                        duration: duration
                    }
                })
            }

            // if(e.target.value.code === 'monthly'){
            //     setOnboardPkgTemp({...onboardPkgTem,maintenance:{[e.target.name] : e.target.value.code,duration: 30}})
            // }else if(e.target.value.code === 'quarterly'){
            //     setOnboardPkgTemp({...onboardPkgTem,maintenance:{[e.target.name] : e.target.value.code,duration: 90}})
            // }else if(e.target.value.code === 'halfyearly'){
            //     setOnboardPkgTemp({...onboardPkgTem,maintenance:{[e.target.name] : e.target.value.code,duration: 180}})
            // }else if(e.target.value.code === 'yearly'){  
            //     setOnboardPkgTemp({...onboardPkgTem,maintenance:{[e.target.name] : e.target.value.code,duration: 360}})        
            // }      
        }

        if (e.target.name === 'paymentterms') {
            setMainTermItem(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, maintenance: { ...onboardPkgTem.maintenance, [e.target.name]: e.target.value.code } })
        }

        if (e.target.name === 'cost') {
            setOnboardPkgTemp({ ...onboardPkgTem, maintenance: { ...onboardPkgTem.maintenance, [e.target.name]: e.target.value } })
        }

        if (e.target.name === 'deposit') {
            setDepositeType(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.value.code })
        }

        if (e.target.name === 'billing_pattern') {
            setBillingPattern(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.value.code })
        }

        if (e.target.name === 'invoiceCycle') {
            setInvoiceCycle(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, invoiceCycle: e.value.code })
        }

        if (e.target.name === 'extperiod') {
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: parseInt(e.target.value) })
        }

        if (e.target.name === 'rfid' || e.target.name === 'nfc' || e.target.name === 'fingerprint' || e.target.name === 'qr' || e.target.name === 'onlinePayment' || e.target.name === 'password') {
            console.log(e.target.checked);
            let paidTemplate = { ...paidOptions, [e.target.name]: e.target.checked, free: false, online: true }
            setPaidOptions(paidTemplate)
        }

        if (e.target.name === 'allPaidOp') {
            let paidTemplate = { ...paidOptions, rfid: e.target.checked, nfc: false, fingerprint: e.target.checked, qr: e.target.checked, onlinePayment: e.target.checked, password: e.target.checked, all: e.target.checked }
            setPaidOptions(paidTemplate)
        }
    }

    // EDITDASH
    const changeUpdateBoard = (e) => {

        if (e.target.name === 'demositelocation' || e.target.name === 'demoduration' || e.target.name === 'expcupconsumption' || e.target.name === 'ctvm_cost') {
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.target.value })
        }

        if (e.target.name === 'demopurpose') {
            setDemoPurposeItem(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.target.value.code })
        }

        if (e.target.name === 'dispense_option') {
            setDispensingItem(e.value)
            setPaidOptions({ ...paidBeverageSetting, free: true })
        }

        if (e.target.name === 'rfid' || e.target.name === 'nfc' || e.target.name === 'fingerprint' || e.target.name === 'qr' || e.target.name === 'online' || e.target.name === 'password') {
            let paidTemplate = { ...editPaymode, [e.target.name]: e.target.checked, free: false }
            setEditPaymode(paidTemplate)
        }

        if (e.target.name === 'all') {
            let paidTemplate = { ...editPaymode, rfid: e.target.checked, nfc: e.target.checked, fingerprint: e.target.checked, qr: e.target.checked, online: e.target.checked, onlinePayment: e.target.checked, password: e.target.checked, all: e.target.checked }
            setEditPaymode(paidTemplate)
        }

        if (e.target.name === 'paymentterms') {
            setSelectedrechargeOp(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.value.code })
        }

        if (e.target.name === 'rentaloption') {
            setPackageItem(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.value.code })
        }

        if (e.target.name === 'packageName') {

            setMaintainenceItem(e.value)
            if (e.target.value.code === 'monthly') {
                setOnboardPkgTemp({ ...onboardPkgTem, maintenance: { [e.target.name]: e.target.value.code, duration: 30 } })
            } else if (e.target.value.code === 'quarterly') {
                setOnboardPkgTemp({ ...onboardPkgTem, maintenance: { [e.target.name]: e.target.value.code, duration: 90 } })
            } else if (e.target.value.code === 'halfyearly') {
                setOnboardPkgTemp({ ...onboardPkgTem, maintenance: { [e.target.name]: e.target.value.code, duration: 180 } })
            } else if (e.target.value.code === 'yearly') {
                setOnboardPkgTemp({ ...onboardPkgTem, maintenance: { [e.target.name]: e.target.value.code, duration: 360 } })
            }
        }

        if (e.target.name === 'paymentterms') {
            setMainTermItem(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, maintenance: { ...onboardPkgTem?.maintenance, [e.target.name]: e.target.value.code } })
        }

        if (e.target.name === 'deposit') {
            setDepositeType(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, deposit: e.value.code })
        }

        if (e.target.name === 'billing_pattern') {
            setBillingPattern(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.value.code })
        }

        if (e.target.name === 'invoiceCycle') {
            setInvoiceCycle(e.value)
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.value.code })
        }

        if (e.target.name === 'ctvmCupCost') {
            setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: { ctvmfullcup: Number(e.target.value), ctvmhalfcup: Number(e.target.value) } })
        }

        if (e.target.name === 'can1' || e.target.name === 'can2' || e.target.name === 'can3' || e.target.name === 'can4') {
            setOnboardPkgTemp({ ...onboardPkgTem, canWiseCost: { ...onboardPkgTem?.canWiseCost, [e.target.name]: Number(e.target.value) } })
        }

        e.target.name === 'siteName' && setOnboardTemp({ ...onboardTemp, [e.target.name]: e.target.value })
        e.target.name === 'facility_name' && setFacilityItem(e.value)
        e.target.name === 'onlineRfid' && setEditPaymode({ ...editPaymode, [e.target.name]: e.value })
        e.target.name === 'dispPassword' && setEditPaymode({ ...editPaymode, [e.target.name]: e.target.value })
        e.target.name === 'cupCost' && setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: { fullcup: Number(e.target.value), halfcup: Number(e.target.value) } })
        e.target.name === 'target_cups' && setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: Number(e.target.value) })
        e.target.name === 'cost' && setOnboardPkgTemp({ ...onboardPkgTem, maintenance: { ...onboardPkgTem?.maintenance, [e.target.name]: e.target.value } })
        e.target.name === 'depositamt' && setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: e.target.value })
        e.target.name === 'extperiod' && setOnboardPkgTemp({ ...onboardPkgTem, [e.target.name]: parseInt(e.target.value) })

    }

    const catergorySelection = (e) => {
        setSubcatItem(e.value);
        setMachine(null)
        setDemoDialog(!demoDialog);
        setCommercialDialog(!commercialDialog);
    }

    const changingStatus = (rowData) => {
        console.log(rowData);
        console.log(rowData.subscription.status);
        setSubStatus(rowData.subscription.status)
        setSelectedMachineForM(rowData)

        if (initialValue.userType === 'owner') {
            setStatusConfirmDialog(true)
        } else {

            if (rowData.brand == 'MERI_TAPRI') {
                setIsOtpClicked(false)
                setVerifyCustomerDialog(true)
            } else {
                setStatusConfirmDialog(true)
            }
        }
    }

    const updatePantryStatus = async (e, rowData, index) => {
        const data = { flag: 1, id: selectedMachineForM.id, index: index, info: { ...rowData, blocked: e.value } }
        const response = await apiServices.addAdmin(data);
        response.status == 200 ? successToast(toast, 'Pantry Boy Status Changed Successfully') : failureToast(toast, 'Failed to Update')
        getMachines()
    }

    const changeCup = (e) => { setOnboardPkgTemp({ ...onboardPkgTem, fullcup: e.value, halfcup: e.value }) }

    const onChangeStage = (e) => {
        setMultiselectValue(e.target.value)
        setSelectedStatus(statusOptions[0]);

        switch (e.target.value.name) {
            case 'All':
                setOnboardMachines(CVESMachines)
                setOnboardedMasterData(CVESMachines)
                break;
            case 'Demo':
                const demoM = CVESMachines.filter((m) => { return m.subscription?.package?.site == "demo" && m.siteName != "" })
                console.log(demoM);
                setOnboardMachines(demoM)
                setOnboardedMasterData(demoM)
                break;
            case 'Commercial':
                const commercialM = CVESMachines.filter((x) => { return x.subscription?.package.site == "permanent" && x.siteName != "" })
                console.log(commercialM);
                setOnboardMachines(commercialM)
                setOnboardedMasterData(commercialM)
                break;
            default:
                setOnboardMachines(CVESMachines)
                setOnboardedMasterData(CVESMachines)
        }
    }

    const onChangeStatus = (e) => {

        setSelectedStatus(e.target.value);

        if (e.target.value.code == 'all') {
            setOnboardMachines(onboardedMasterData)
        } else if (e.target.value.code == 'Active') {
            const data = onboardedMasterData.filter((x) => { return x.subscription.status == e.target.value.code || x.subscription.status == e.target.value.code.toUpperCase() || x.subscription.status == 'Demo' });
            setOnboardMachines(data)
        } else {
            const data = onboardedMasterData.filter((x) => { return x.subscription.status == e.target.value.code });
            setOnboardMachines(data)
        }
    }

    const clearStates = () => {
        setDemoPurposeItem(null)
        setMachine(null)
        setSelectedTapriman(null)
        setSelectedCustomer(null)
        setSelectedAccount(null)
        setSelectedLeads(null)
        setFacilityItem(null)
        setDispensingItem(null)
        setPaytm(null)
        setPackageItem(null)
        setBillingPattern(null)
        setInvoiceCycle(null)
        setSelectedMuser(null)
        setSelectedKAM(null)
        setSavedfacility(null)
    }

    const openNew = async () => {
        setSubcatItem({ name: 'Demo', code: 'Demo' })
        setDemoDialog(true)
        setCommercialDialog(false)
        setPrizing(null)
        setFormAction(false)
        setEditMachineData(null)
        setSubmitted(false);
        setSelectedCat(null)
        setFgMachinesToOnboard([])
        setAccounts(null)
        setAccount(false)
        setProductDialog(true);
        // setFacility([{facname:'none'}])
        setFacility([])
        setFormAction(false)
        const response = await apiServices.getUsersByType({ "d_id": ["self"], "userType": "marketmanager" });
        var mark_user = response?.data?.data.map((x) => { return { ...x, fullName: x.name + ' ' + x.lastName } });
        setMarketingUsers(mark_user);
        clearStates()
    }

    const openFacility = async () => { setFacilityDialog(true) }
    const openAgreeUploader = async () => { setUploaderDialog(true) }

    const openNewPantry = (rowData) => {
        setPantryDialog(true);
        setSelectedMachineForM(rowData)
        console.log(rowData);
        console.log(rowData?.admins);
        setPantryBoyDetails(rowData?.admins)
        const reduced = userReduced?.filter((m) => { return m?.userid == rowData?.customer })
        setSubscriptionDe(reduced)
    }

    const openSwitchDialog = (rowData) => {
        setSwitchDialog(true);
        setSelectedMachineForM(rowData)
    }

    const openAgreement = async (rowData) => {
        console.log(rowData);
        setLoading1(true)
        setAgreementDialog(true);
        setSelectedMachineForM(rowData)
        const response = await apiServices.getAgreements({ id: rowData.id, subid: rowData.subid });
        setTimeout(() => {
            setAgreementList(response.data.data)
            setLoading1(false)
        }, 1000);
        const reduced = userReduced?.filter((m) => { return m?.userid == rowData?.customer })
        setSubscriptionDe(reduced)
    }

    const hideDialog1 = () => {
        setSubmitted(false);
        setPantryDialog(false);
    }

    const hideSwitchDialog = () => {
        setSwitchDialog(false);
        setMachineDropdownItem(null);
        setSwitchDefault()
        setSwitchMachine(null)
    }

    const setSwitchDefault = () => {
        setConsumptionCheck(false);
        setCardsCheck(false);
        setCounterCheck(false);
        setPendingCheck(false);
    }

    const openAddBoyDialog = () => {
        setAddBoyDialog(true)
        setEditBoyData(null)
        setPantryBoyAuthInfo(pantryAuthSchema);
        setPantryAuthInfo(pantryAutorizationTem);
    }

    const viewAgreement = async (rowData) => {
        const response = await apiServices.sendAgreement(rowData);
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const downloadURL = window.URL.createObjectURL(blob);
        window.open(downloadURL, '_blank');
    }

    const openEditBoyDialog = (rowData, i) => {
        console.log(rowData);
        setEditBoyData({ ...rowData, index: i })
        setAddBoyDialog(true)
        setPantryBoyInfo(rowData)
        setPantryBoyAuthInfo(rowData?.authorization?.material)
        setPantryAuthInfo(rowData?.authorization)
    }

    {/* EDITDASH */ }
    const editmachine = async (machine) => {
        console.log(machine);
        setAccount(false)
        setEditLoading(true)
        setOnboardTemp(null)
        setEditMachineData(null)
        setEditmachineDialog(true)
        setFormAction(true)
        setOnboardSupTemp(null)
        setEditPaymode(machine?.mchmode?.paymode)

        setOnboardPkgTemp({ canWiseCost: machine?.subscription?.package?.canWiseCost })

        const paytmAcc = await apiServices.getPaytmAcc({ account: machine.customer });
        const op = paytmAcc?.data?.data?.length > 0 ? [{ name: 'Default', code: 'default' }, ...paytmAcc?.data?.data] : [{ name: 'Default', code: 'default' }]
        setPaymentValue(op)
        setPaytm(machine?.subscription?.package?.paytm ? machine?.subscription?.package?.paytm : { name: 'Default', code: 'default' })
        const cust = userReduced?.filter(val => val.userid == machine.customer)
        const facRes = await apiServices.getFacility({ userid: cust[0]?.userid });

        const facArr = [];
        facRes?.data?.data.map((x) => {
            if (x.facid == machine?.subscription?.facilitydata?.facid) {
                facArr.push(x)
            }
        })

        console.log(facArr);
        setFacilityItem(facArr.length > 0 ? facArr[0] : { facname: 'none' })
        // setFacility([{facname:'none'},...facRes.data.data])
        setFacility([...facRes.data.data])

        setEditMachineData({ ...machine, acc_name: cust[0].name })

        if (machine?.subscription?.package?.site == "permanent") {
            setSubcatItem({ name: 'Commercial', code: 'Commercial' })
            setCommercialDialog(true)
            setDemoDialog(false)
        } else {
            setSubcatItem({ name: 'Demo', code: 'Demo' })
            setCommercialDialog(false)
            setDemoDialog(true)

            if (machine?.subscription?.package?.demopurpose == 'generalLead') {
                setDemoPurposeItem({ name: 'General Lead', code: 'generalLead' })
            } else if (machine?.subscription?.package?.demopurpose == 'investerPitch') {
                setDemoPurposeItem({ name: 'Invester Pitch', code: 'investerPitch' })
            } else if (machine?.subscription?.package?.demopurpose == 'specialEvent') {
                setDemoPurposeItem({ name: 'Special event', code: 'specialEvent' })
            } else {
                setDemoPurposeItem(null)
            }
        }

        const response = await apiServices.getUsersByType({ "d_id": ["self"], "userType": "marketmanager" });
        const mark_user = response?.data?.data.map((x) => { return { ...x, fullName: x.name + ' ' + x.lastName } })
        const selectedMa = mark_user.filter((v) => { return v.userid == machine?.superman?.marketmanager })
        setMarketingUsers(mark_user)
        setSelectedMuser(selectedMa[0])
        const response1 = await apiServices.getUsersByType({ marketmanager: selectedMa[0].userid, userType: "accountmanager" });
        console.log('accountmanager', response1);

        const accData = response1.data.data.map((x) => { return { ...x, displayName: `${x.name} ${x.lastName}` } })
        setKeyAccManagers(accData)
        // setKeyAccManagers(response1?.data?.data)
        const selectedMa1 = accData.filter((v) => { return v.userid == machine?.superman?.accountmanager })
        setSelectedKAM(selectedMa1[0])
        const response2 = await apiServices.getUsersByType({ userType: "superman" });
        // const response2 = await apiServices.getUsersByType({accountmanager: selectedMa1[0]?.userid,userType: "superman"});
        const tapri = response2?.data?.data.map((x) => { return { ...x, fullName: x.name + ' ' + x.lastName } });
        setTaprimans(tapri)
        const selectedTap = tapri.filter((v) => { return v.userid == machine?.superman?.superman?.userid })
        setSelectedTapriman(selectedTap[0])
        machine?.mchmode?.paymode?.free ? setDispensingItem({ name: 'Free', code: 'free' }) : setDispensingItem({ name: 'Paid', code: 'paid' })
        machine?.subscription?.package?.paymentterms == 'postpaid' ? setSelectedrechargeOp({ name: 'Postpaid', code: 'postpaid' }) : setSelectedrechargeOp({ name: 'Prepaid', code: 'prepaid' })
        const pkg = packageDropdownList.filter((m) => { return m.code == machine?.subscription?.package?.rentaloption })
        setPackageItem(pkg[0])

        if (machine?.subscription?.package?.rentaloption == 'maintenance' || machine?.subscription?.package?.rentaloption == 'maintenance/deposit') {

            const value = machine?.subscription?.package?.maintenance?.packageName;

            if (value == 'monthly') {
                setMaintainenceItem({ name: 'Monthly', code: 'monthly' })
            } else if (value == 'quarterly') {
                setMaintainenceItem({ name: 'Quarterly', code: 'quarterly' })
            } else if (value == 'halfyearly') {
                setMaintainenceItem({ name: 'HalfYearly', code: 'halfyearly' })
            } else if (value == 'yearly') {
                setMaintainenceItem({ name: 'Yearly', code: 'yearly' })
            }

            const value1 = machine?.subscription?.package?.maintenance?.paymentterms;

            if (value1 == 'postpaid') {
                setMainTermItem({ name: 'Postpaid', code: 'postpaid' })
            } else if (value1 == 'prepaid') {
                setMainTermItem({ name: 'Prepaid', code: 'prepaid' })
            }
        }

        const deposit = typeOfDeposite.filter((m) => { return m.code == machine?.subscription?.package?.deposit })
        setDepositeType(deposit[0])
        const billingPattern = billingPatternOptions.filter((m) => { return m.code == machine?.subscription?.package?.billing_pattern })
        setBillingPattern(billingPattern[0])

        if (machine?.subscription?.package?.billing_pattern == 'postconsumption') {

            if (machine?.subscription?.package?.invoiceCycle == 'month_end') {
                setInvoiceCycle({ name: 'Every Month End', code: 'month_end' })
            } else if (machine?.subscription?.package?.invoiceCycle == 'monthly') {
                setInvoiceCycle({ name: 'Monthly (30 Days)', code: 'monthly' })
            } else if (machine?.subscription?.package?.invoiceCycle == 'weekly') {
                setInvoiceCycle({ name: 'Weekly (7 Days)', code: 'weekly' })
            }
        }

        const response3 = await apiServices.getModelPricing({ model: machine.model, type: machine.type });
        setPrizing(response3?.data?.data)
        setEditLoading(false)
    }

    const confirmDeleteCVES = (rowData) => {
        setSelectedMachineForM(rowData)
        console.log(rowData);
        setDeleteCVESDialog(true);
    }

    const confirmDeletePantry = (rowData, index) => {
        setDeletePantryDialog(true)
        setDeletePantryIndex(index);
    }

    const editOnBoardMain = (e) => {
        let setName = { ...editMachineData, [e.target.name]: e.target.value }
        setEditMachineData(setName)
    }

    const selectCategory = async (e) => {
        console.log(e.target.value);
        setSelectedCat(e.target.value)
        if (e.target.value.code == 'capsule') {
            getFGMachinesForOB({ app_cat: e.target.value.name })
        } else if (e.target.value.code == 'tapriKettle') {
            // getFGMachinesForOB({app_cat: e.target.value.code})
            getFGMachinesForOB({ app_cat: e.target.value.name })
        } else {
            getFGMachinesForOB({})
        }
    }

    const selectMachine = async (e) => {
        setMachine(e.value)
        const response = await apiServices.getModelPricing({ model: e.value.model, type: e.value.type });
        setPrizing(response?.data?.data)
        let pack;

        if (subcatItem.code == 'Demo') {
            pack = e.value?.subscription?.package;
            delete pack?.ctvmfullcup;
            delete pack?.ctvmhalfcup;
            setOnboardPkgTemp({ ...pack, site: 'demo', ...onboardPkgTem })
            // setOnboardPkgTemp({...pack,site:'demo'})
        } else {
            pack = e.value?.subscription?.package;
            delete pack?.demoduration;
            delete pack?.demopurpose;
            delete pack?.demositelocation;
            setOnboardPkgTemp({ ...pack, site: 'permanent', ...onboardPkgTem })
            // setOnboardPkgTemp({...pack,site:'permanent'})
        }
    }

    const changeMarketer = async (e) => {
        setSelectedMuser(e.value)
        const response = await apiServices.getUsersByType({ marketmanager: e.value.userid, userType: "accountmanager" });
        const accData = response.data.data.map((x) => { return { ...x, displayName: `${x.name} ${x.lastName}` } })
        setKeyAccManagers(accData)
        setOnboardSupTemp({ ...onboardSupTem, marketmanager: e.value.userid })
    }

    const changeKeyAM = async (e) => {
        console.log(e.value);
        setSelectedKAM(e.value)
        const response = await apiServices.getUsersByType({ userType: "superman" });
        const tapri = response?.data?.data.map((x) => { return { ...x, fullName: x.name + ' ' + x.lastName } });
        console.log(tapri);
        setTaprimans(tapri)
        setOnboardSupTemp({ ...onboardSupTem, accountmanager: e.value.userid })
    }

    const changeTapri = async (e) => {
        setSelectedTapriman(e.value)
        setOnboardSupTemp({
            ...onboardSupTem, superman: {
                name: e.value.name,
                cno: e.value.cno,
                userid: e.value.userid
            }
        })
    }

    const changeCustomer = async (e) => {
        setSelectedCustomer(e.value)
        if (e.value.name == 'Account') {
            setAccount(true)
            setLeads(false)
            const accounts = userReduced.filter(val => val.userType == 'customer');
            console.log(accounts);

            let arr = []
            for (let i = 0; i < accounts.length; i++) {
                let temp = CVESMachines.filter((value) => { return value.customer == accounts[i].userid });
                console.log(temp);
                let index = temp.findIndex((value) => { return value.subscription.package.site == "permanent" })
                if (index < 0 && temp.length > 0) {
                    let customer = { ...accounts[i], value: accounts[i].userid, label: accounts[i]?.name + " " + accounts[i]?.lastName + "(Demo)" };
                    arr.push(customer)
                } else if (index >= 0) {
                    let customer = { ...accounts[i], value: accounts[i].userid, label: accounts[i].name + " " + accounts[i].lastName };
                    arr.push(customer)
                }
            }

            setAccounts(arr)

            // TESTCHANGE
            // const filArr = accounts.map((x)=>{ return {...x, value: x?.userid, label: x?.name + " " + x?.lastName} });
            // setAccounts(filArr);

            setOnboardTemp({ ...onboardTemp, lead: false })

        } else if (e.value.name == 'Lead') {
            // getLeads()
            setLeads(true)
            setAccount(false)
            setOnboardTemp({ ...onboardTemp, lead: true })
        }
    }

    const getLeads = async () => {
        const response = await apiServices.getCustomerData1();
        console.log(response?.data?.data);
        setAllLeads(response?.data?.data)
    }

    const hideCVESDialog = () => { setDeleteCVESDialog(false) }

    const deleteCVES = async () => {
        setLoading(true)
        const text = selectedMachineForM.subid.toString()

        if (text.startsWith("2") && text.length == 9) {
            const response = await apiServices.deleteSubscription(selectedMachineForM);
            if (response.data.success) {
                successToast(toast, 'CVES Machine Deleted Successfully')
                // getMachines()
                removeCVESFromDataTable(selectedMachineForM)
                setLoading(false)
            } else {
                failureToast(toast, 'Failed to Delete')
            }

        } else {

            const response = await apiServices.deleteSite(selectedMachineForM);
            if (response.data.type == 'success') {
                successToast(toast, 'CVES Machine Deleted Successfully')
                // getMachines()
                removeCVESFromDataTable(selectedMachineForM)
                setLoading(false)
            } else {
                failureToast(toast, 'Failed to Delete')
            }
        }

        setDeleteCVESDialog(false)
        setSettingDialog(false)
    }

    const removeCVESFromDataTable = (x) => {
        onboardMachines.splice(onboardMachines.findIndex(a => a.id === x.id), 1)
        console.log(onboardMachines);
        setOnboardMachines([...onboardMachines]);
    }

    const changeFac = (e) => {
        setFacilityItem(e.value)
        setCoreFacilityTemp({ ...coreFacilityTemp, [e.target.name]: e.target.value })
    }

    const changeAddFac = (e) => {
        const data = e.value;
        setFacilityItem(data)

        if (Object.keys(data).length == 1) {
            const facility = [{ address: {}, billing_default: {}, billing_details: {}, admin_default: {}, admins: [] }]
            setFacAddTemplate(facility)
        } else {

            const facility = [{
                facname: data.facname,
                address: {
                    address: data.address.address,
                    country: typeof data.address.country == 'string' ? JSON.parse(data.address.country) : data.address.country,
                    state: typeof data.address.state == 'string' ? JSON.parse(data.address.state) : data.address.state,
                    city: typeof data.address.city == 'string' ? JSON.parse(data.address.city) : data.address.city
                },
                billing_default: data.billing_default,
                billing_details: {
                    locality: data.billing_details.locality,
                    gstnumber: data.billing_details.gstNumber,
                    cmpname: data.billing_details.company,
                    addr: data.billing_details.addr,
                    country: typeof data.billing_details.country == 'string' ? JSON.parse(data.billing_details.country) : data.billing_details.country,
                    state: typeof data.billing_details.state == 'string' ? JSON.parse(data.billing_details.state) : data.billing_details.state,
                    city: typeof data.billing_details.city == 'string' ? JSON.parse(data.billing_details.city) : data.billing_details.city
                },
                admin_default: data.admin_default,
                admins: data.admins
            }]

            setFacAddTemplate(facility)
        }
    }

    const getFac = async (data, data1) => {
        if (data1) {
            console.log(data1);
            // setFacility([{facname:'none'},{facname:data1?.facility[0]?.facname}] )
            setFacility([{ facname: data1?.facility[0]?.facname }])

            // facility[0].facname
            // const response = await apiServices.getFacility({userid: data});
            // console.log(response.data.data);
            // console.log([{facname:'none'},...response.data.data,data1]);
        } else {
            const response = await apiServices.getFacility({ userid: data });
            console.log(response?.data?.data);
            // response?.data?.data && setFacility([{facname:'none'},...response?.data?.data])
            response?.data?.data && setFacility([...response?.data?.data])
        }
    }

    const changeAccountD = async (e) => {
        console.log(e.value);
        let temp = accounts.filter((value) => { return value.userid == e.value });
        console.log(temp);
        setSelectedAccount(e.value)
        setSelectedAcc(true)
        setCompany(temp[0])
        getFac(e.value)
        setOnboardTemp({ ...onboardTemp, customer: temp[0]?.userid, billing_details: temp[0]?.billing_details })
        const response1 = await apiServices.getPaytmAcc({ account: temp[0]?.userid });
        setPaymentValue([...paymentValue, ...response1.data.data])
        const response2 = await apiServices.getuserById({ userid: temp[0]?.userid })
        setOwnerShipDetails(response2?.data?.data[0])
    }

    const saveSwitchMachine = async (e) => {
        e.preventDefault();
        setSwitchDialog(false)
        setSwitchMachine(null)
        if (switchMachine?.connectionMode != "disConnected" || selectedMachineForM?.app_cat == "Tapri Kettle") {
            const data = { id: selectedMachineForM?.id, new_id: switchMachine?.id, customer: selectedMachineForM?.customer, owner: initialValue?.userid }
            console.log(data);
            const response = await apiServices.switchMachine(data);
            if (response?.data?.type !== 'error') {
                successToast(toast, 'CVES Machine Switched Successfully')
                getMachines()
            } else {
                failureToast(toast, response?.data?.msg || 'Failed to Switch')
            }
        }
    }

    const updateMachinStatus = async () => {
        setStatusConfirmDialog(false)
        const text = selectedMachineForM.subid.toString()
        console.log(text.startsWith("1"))
        let response;
        if (text.startsWith("2") && text.length == 9) {
            const data = { id: selectedMachineForM.id, status: selectedMachineForM.subscription.status == 'Active' ? 'Inactive' : 'Active', site: selectedMachineForM?.subscription?.package?.site }
            response = await apiServices.updateSubscriptionStatusTest(data);
        } else {
            const data = { id: selectedMachineForM.id, status: (selectedMachineForM.subscription.status == 'Active' || selectedMachineForM.subscription.status == 'Demo') ? 'Inactive' : 'Active', site: selectedMachineForM?.subscription?.package?.site }
            response = await apiServices.updateSubscriptionStatus(data);
        }

        if (response?.data?.type) {
            successToast(toast, 'CVES Subscription Status Updated Successfully')
            setLoading(true)
            getMachines()
        } else {
            failureToast(toast, 'Failed to Update')
        }
    }

    const deletePantryBoy = async () => {
        const response = await apiServices.deletePantryBoy({ id: selectedMachineForM.id, index: deletePantryIndex });
        response?.data?.type == 'success' ? successToast(toast, 'Pantry Boy has deleted') : failureToast(toast, 'Failed to Delete')
        getMachines()
        setDeletePantryDialog(false)
    }

    {/* ADDDASH */ }
    const onboardSubmit = async (e) => {
        e.preventDefault();
        setProductDialog(false)
        console.log(onboardPkgTem)
        const addedBy = { name: useid.name, customer: useid.userid, userType: useid.userType }
        const main = { ...onboardTemp, machine: machine?.id, d_id: ["self"] };
        const facilitydata = facilityItem?.facname == 'none' ? { facname: 'none' } : { facid: facilityItem?.facid, facname: facilityItem?.facname };
        const facility = facilityItem?.facname == 'none' ? [] : null
        const mchmode = { ...machine?.mchmode, paymode: paidOptions, wtr_offset: Number(defaultWtrOffset), wtr_offsetFlag: false }
        const packgetem = subcatItem.code == 'Demo' ? { ...onboardPkgTem, maintenance: {} } : onboardPkgTem;
        const data1 = { ...main, facilitydata: facilitydata, mchmode: mchmode, package: packgetem, superman: onboardSupTem, facility }
        const data2 = paytm ? { ...data1, paytm: paytm } : { ...data1 }
        const data = selectedCustomer.name == 'Lead' ? { ...data2, facility: facAddTemplate } : { ...data2 }
        console.log(data);

        const response = await apiServices.addNewSite(data);
        response?.data?.type == "success" ? successToast(toast, 'CVES Added Successfully') : failureToast(toast, 'Failed to Add')
        getMachines();
    }

    // EDITDASH
    const updateOnboardSubmit = async (e) => {
        e.preventDefault();
        setEditmachineDialog(false)
        console.log("===========UPDATE============");
        console.log(editPaymode)

        var params = {
            editbydetails: { name: useid.name, customer: useid.userid, userType: useid.userType },
            site: editMachineData.subscription?.package?.site,
            subid: editMachineData.subid,
            siteName: onboardTemp?.siteName ? onboardTemp?.siteName : editMachineData.siteName,
            id: editMachineData.id,
            // superman: editMachineData.superman,
            superman: { ...editMachineData?.superman, ...onboardSupTem },
            cupcost: (onboardPkgTem?.cupCost === undefined) ? { fullcup: editMachineData.subscription.package.fullcup, halfcup: editMachineData.subscription.package.halfcup } : onboardPkgTem?.cupCost,
            ctvmcupcost: (onboardPkgTem?.ctvmCupCost === undefined) ? { ctvmfullcup: editMachineData.subscription.package.ctvmfullcup, ctvmhalfcup: editMachineData.subscription.package.ctvmhalfcup } : onboardPkgTem?.ctvmCupCost,
            mchmode: dispensingItem?.code == 'paid' ? { ...editMachineData?.mchmode, paymode: { free: false, nfc: editPaymode.nfc, rfid: editPaymode.rfid, fingerprint: editPaymode.fingerprint, qr: editPaymode.qr, onlineRfid: editPaymode.onlineRfid, online: editPaymode.online, password: editPaymode.password, dispPassword: editPaymode?.dispPassword } } : { ...editMachineData.mchmode, paymode: { free: true } },
            // mchmode : dispensingItem?.code == 'paid'?{ paymode: { free: false, nfc: editPaymode.nfc, rfid: editPaymode.rfid, fingerprint: editPaymode.fingerprint, qr: editPaymode.qr, onlineRfid: editPaymode.onlineRfid, online: editPaymode.online , password : editPaymode.password } }:{ paymode: { free: true } } ,
            extperiod: onboardPkgTem?.extperiod ? onboardPkgTem?.extperiod : editMachineData.subscription.package.extperiod,
            billing_pattern: editMachineData.subscription.package.billing_pattern,
            rentaloption: onboardPkgTem?.rentaloption ? onboardPkgTem?.rentaloption : editMachineData.subscription.package.rentaloption,
            invoiceCycle: onboardPkgTem?.invoiceCycle ? onboardPkgTem?.invoiceCycle : editMachineData.subscription.package.invoiceCycle,
            target_cups: onboardPkgTem?.target_cups ? onboardPkgTem?.target_cups : editMachineData.subscription.package.target_cups,
            expcupconsumption: onboardPkgTem?.expcupconsumption ? onboardPkgTem?.expcupconsumption : editMachineData?.subscription?.package?.expcupconsumption,
            paymentterms: editMachineData.subscription.package.paymentterms,
            prepaidCups: editMachineData.subscription.package.prepaidCups ? editMachineData.subscription.package.prepaidCups : 0,
            facilitydata: facilityItem ? facilityItem : { facname: "none" },
            canWiseCost: onboardPkgTem?.canWiseCost
        }

        if (packageItem?.code == 'maintenance') {
            var data = { ...params, maintenancePlan: { ...editMachineData?.subscription?.package?.maintenance, ...onboardPkgTem?.maintenance } }
        } else if (packageItem?.code == "deposit") {
            var data = { ...params, maintenancePlan: '', deposit: depositeType?.code, depositamt: onboardPkgTem?.depositamt ? onboardPkgTem?.depositamt : editMachineData?.subscription?.package?.depositamt }
        } else if (packageItem?.code == "maintenance/deposit") {
            var data = { ...params, maintenancePlan: { ...editMachineData?.subscription?.package?.maintenance, ...onboardPkgTem?.maintenance }, deposit: editMachineData?.subscription?.package?.deposit, depositamt: onboardPkgTem?.depositamt ? onboardPkgTem?.depositamt : editMachineData?.subscription?.package?.depositamt }
        }
        // else {
        //     var data = {...params,maintenancePlan:packageItem?.code,deposit:editMachineData?.subscription?.package?.deposit,depositamt:onboardPkgTem?.depositamt ? onboardPkgTem?.depositamt : editMachineData?.subscription?.package?.depositamt}
        // }

        // if (editPaymode?.rfid && dispensingItem?.code == 'paid') {
        //   var data = {...params,maintenancePlan:packageItem?.code,paytm:paytm,deposit:editMachineData?.subscription?.package?.deposit,depositamt:onboardPkgTem?.depositamt ? onboardPkgTem?.depositamt : editMachineData?.subscription?.package?.depositamt}
        // }

        if (editMachineData.subscription.package.site == 'demo') {
            const demositelocation = onboardPkgTem?.demositelocation ? onboardPkgTem?.demositelocation : editMachineData.subscription.package.demositelocation
            const demopurpose = onboardPkgTem?.demopurpose ? onboardPkgTem?.demopurpose : editMachineData.subscription.package.demopurpose
            const demoduration = onboardPkgTem?.demoduration ? onboardPkgTem?.demoduration : editMachineData.subscription.package.demoduration
            var data = { ...params, demositelocation: demositelocation, demopurpose: demopurpose, demoduration: demoduration }
        }

        if (paytm?.name != 'Default') {
            var data = { ...params, paytm: paytm }
        }

        const text = editMachineData.subid.toString()

        console.log(data);

        if (text.startsWith("2") && text.length == 9) {
            const finalData = { ...data, customer: editMachineData.customer, deposit: "", depositamt: "" }
            console.log(finalData);
            const response = await apiServices.renameSubscription(finalData);
            response?.data?.success ? successToast(toast, 'CVES Updated Successfully') : failureToast(toast, 'Failed to Update')
            setTimeout(() => {
                getMachines();
            }, 2000);
        } else {
            const payload = data ? data : params;
            console.log(payload);
            const response = await apiServices.renameSite(payload);
            response?.data?.type == "success" ? successToast(toast, 'CVES Updated Successfully') : failureToast(toast, 'Failed to Update')
            getMachines();
        }
    }

    const submitAgreement = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const response = await apiServices.uploadAgreement(data);
        if (response.data.success) {
            successToast(toast, 'Agreement uploaded Successfully')
            setLoading1(true)
            const response1 = await apiServices.getAgreements({ id: response.data.data.id, subid: response.data.data.subid });
            console.log(response1.data.data);
            setAgreementList(null)
            setTimeout(() => {
                setAgreementList(response1.data.data)
                setLoading1(false)
                getMachines()
            }, 2000);
        } else {
            failureToast(toast, 'Failed to Upload')
        }
        setUploaderDialog(false)
    }

    const submitPantryBoy = async (e) => {
        e.preventDefault();
        setAddBoyDialog(false)
        const random = Math.floor(1000 + Math.random() * 9000);
        const pantryBoyInfo1 = { ...pantryBoyInfo, keypad: random }
        let authData = { ...pantryAuthInfo, material: pantryBoyAuthInfo }
        let data = { ...pantryBoyInfo1, authorization: authData }

        if (editBoyData) {
            const response = await apiServices.addAdmin({ flag: 1, index: editBoyData.index, id: selectedMachineForM.id, info: data });
            response.status === 200 ? successToast(toast, 'Pantry Boy Updated Successfully') : failureToast(toast, 'Failed to Update')
        } else {
            const response = await apiServices.addAdmin({ flag: 0, id: selectedMachineForM.id, info: data });
            response.status === 200 ? successToast(toast, 'Pantry Boy Added Successfully') : failureToast(toast, 'Failed to Add')
        }
        setPantryBoyAuthInfo(pantryAuthSchema)
        getMachines()
    }

    const handleAuth = (e) => {
        let authTemplate = { ...pantryBoyAuthInfo, [e.target.name]: e.target.checked }
        setPantryBoyAuthInfo(authTemplate)
        console.log(authTemplate);
    }

    const handleAuth1 = (e) => {
        let authTemplate = { ...pantryAuthInfo, [e.target.name]: e.target.checked }
        setPantryAuthInfo(authTemplate)
        console.log(authTemplate);
    }

    const onPantryValue = (e) => {
        let infoTemplate = { ...pantryBoyInfo, [e.target.name]: e.target.value }
        setPantryBoyInfo(infoTemplate)
    }

    const changeSwitchMachine = (e) => {
        console.log(e.value);
        setSwitchMachine(e.value)
        setSwitchDefault()
        if (e.value.connectionMode != "disConnected" || selectedMachineForM?.app_cat == "Tapri Kettle") {
            setSwitchCheckStatus(false)
        }
    }

    const switchingChecks = async (e) => {
        console.log(e.target.checked)
        if (e.target.checked) {
            const response = await apiServices.switchMachineCheck({ check: e.target.name, id: switchMachine?.id });
            if (response?.data?.success) {
                e.target.name == 'consumption' && setConsumptionCheck(e.target.checked)
                e.target.name == 'cards' && setCardsCheck(e.target.checked)
                e.target.name == 'counter' && setCounterCheck(e.target.checked)
                e.target.name == 'pending' && setPendingCheck(e.target.checked)
            }
        }
    }

    const changeOtp = (e) => {
        const data = { "cno": selectedMachineForM?.customerCno, "otp": e.target.value, "logintype": "cno" };
        setVerifyTemp(data)
    }

    const verifySubmit = async (e) => {
        e.preventDefault();
        const response = await apiServices.verifyOtp(verifyTemp)
        if (response?.data?.success) {
            // successToast(toast,'Verification done successfully')
            hideVeriyDialog()
            updateMachinStatus()
        } else {
            failureToast(toast, 'Invalid OTP')
        }
    }

    const exportCSV = () => {
        dt.current.exportCSV()
    }

    const leftToolbarTemplate = () => (
        <>
            <Chip label={`Total ${machinePayload ? (machinePayload?.app_cat) : 'CVES'}'s : ${onboardMachines ? onboardMachines?.length : '-'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
            {
                initialValue?.userType == 'owner' && <Chip label={`${machinePayload ? (machinePayload?.app_cat) : 'CVES'}'s Available for On-Boarding : ${fgMachines ? fgMachines?.length : '-'}`} icon="pi pi-angle-double-right" className="p-mr-2 p-mb-2" />
            }
        </>
    )

    const rightToolbarTemplate = () => {
        return (
            rolePermissions?.onboardCtvm && <Button className='p-custom-btn' label="ON-BOARD CVES" icon="pi pi-plus-circle" iconPos="left" onClick={openNew} />
        )
    }

    const agreementRightToolbarTemplate = () => { return (<> {rolePermissions?.uploadAgreementActn && <Button label="Upload" icon="pi pi-upload" className="p-button-rounded p-custom-btn p-mr-2 p-mb-2" onClick={() => { openAgreeUploader(selectedMachineForM) }} />} </>) }
    const pantryBoyLeftToolbarTemplate = () => (<><Chip label={`Subscription Name : ${subscriptionDe ? subscriptionDe[0]?.billing_details?.company : '-'}`} icon="pi pi-angle-double-right" className="p-mt-2" /></>)
    const pantryBoyRightToolbarTemplate = () => (<><Button label="Add Pantry Boy" icon="pi pi-user-plus" className="p-button-rounded p-custom-btn p-mr-2 p-mb-2" onClick={openAddBoyDialog} /></>)
    const agreementLeftToolbarTemplate = () => (
        <>
            <div className='p-grid'>
                <div className='p-col-sm-4'>
                    <Chip label={`Subscription Name : ${subscriptionDe ? subscriptionDe[0]?.billing_details.company : '-'}`} icon="pi pi-angle-double-right" className="p-mt-2" />
                </div>
            </div>
        </>
    )

    const brandBodyTemplate = (rowData, props) => {
        let brandModel;
        if (rowData[props.field] == 'TKAFFE') {
            brandModel = 'TKAFFE KIOSK' + " / " + rowData.model;
        } else if (rowData[props.field] == 'TAPRI') {
            brandModel = 'TAPRI KIOSK' + " / " + rowData.model;
        } else if (rowData[props.field] == 'MERI_TAPRI') {
            brandModel = 'TAPRI KETTLE' + ' / ' + rowData.model;
        }

        return (<> <span className="p-column-title">{props.header}</span>{brandModel} </>)
    }

    const brandBodyKettleTemplate = (rowData, props) => {
        const brandModel = rowData.brand == 'MERI_TAPRI' ? 'TAPRI KETTLE' : rowData.brand;
        return (<> <span className="p-column-title">{props.header}</span> {brandModel}</>)
    }

    const subIdBodyTemplate = (rowData, props) => {
        const subId = rowData[props.field]
        const FinalSub = subId.toString().length == 9 ? subId : subId.toString().padStart(9, "0");
        return (<><span className="p-column-title">{props.header}</span>{FinalSub}</>)
    }

    const machineTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">CVES Id</span>
                <Button label={rowData.id} tooltip={`${(rowData.connectionMode == "disConnected" || rowData.connectionMode === undefined || rowData.connectionMode == "Unknown") ? `${rowData.id} is Offline` : `${rowData.id} is Online`}`} className={`p-button-rounded p-mr-2 p-mb-2 ${(rowData.connectionMode == "disConnected" || rowData.connectionMode === undefined || rowData.connectionMode == "Unknown") ? 'p-button-danger' : 'p-button-success'}`} />
            </>
        )
    }

    const agreeBodyTemplate = (rowData, props) => (<><span className="p-column-title">{rowData[props.header]}</span>{rowData.agreement.id}</>)

    const dateBodyTemplate = (rowData, props) => {
        var date = new Date(rowData[props.field]);
        const convertedDate = date.toISOString().substring(0, 10);
        return (<> {convertedDate} </>);
    }

    const openAllActions = (data) => {
        // console.log("openAllActions", rolePermissions.commercialDeleteButton, data.subscription?.package?.site);
        setSettingDialog(true)
        setSelectedMachineForM(data)
        const deleteFlag = (data.siteName != "" && rolePermissions.commercialDeleteButton && data.subscription?.package?.site == "permanent" && data.subscription.status == 'Inactive')
            || (data.siteName != "" && rolePermissions.deleteOnboardedDemo && data.subscription?.package.site == "demo")
        setDeleteFlag(deleteFlag)
    }

    const openGallery = (data) => {
        console.log(data);
        setLoading1(true)
        setImages(null)
        setSelectedMachineForM(data)
        getKycDocuments(data)
        setKycDialog(true)
    }

    const getKycDocuments = async (data) => {
        const response = await apiServices.getKycData({ "orderId": data?.subscription?.orderId });
        console.log(response?.data?.success);
        if (response?.data?.success) {
            setLoading1(false)
            console.log(response?.data?.data?.proofs)
            const val = Object.values(response?.data?.data?.proofs)
            setImages(val)
            const entries = Object.entries(response?.data?.data?.proofs);
            console.log(entries);
            // const keyss = Object.keys(response?.data?.data?.proofs)    
            // setImages(response?.data?.data?.proofs)
        } else {
            setLoading1(false)
        }
    }

    const hideSettingDialog = () => { setSettingDialog(false) }

    const changingTab = (e) => {
        setLoading(true)
        setActiveTabIndex(e.index)
        setSelectedStatus(statusOptions[0])
        // setMultiselectValue({ name: 'All' })
        if (e.index == 0) {
            setMachinePayload(null)
            getMachines({})
        } else if (e.index == 1) {
            setMachinePayload({ "app_cat": "Tapri Kettle" })
            getMachines({ "app_cat": "Tapri Kettle" })
        } else if (e.index == 2) {
            setMachinePayload({ "app_cat": "Capsule Machine" })
            getMachines({ "app_cat": "Capsule Machine" })
        }
        setGlobalFilter(null)
    }

    const sendOtp = async () => {
        setIsOtpClicked(true)
        const response = await apiServices.sendOtp({ cno: selectedMachineForM?.customerCno })
        console.log(response);
    }

    const statusTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Status</span>

                {
                    ((rowData.subscription.status == "Active" || rowData.subscription.status != "Active") && rowData?.subscription?.package?.site != "demo") &&
                    <InputSwitch checked={rowData.subscription.status == "Active" ? true : false} onChange={() => { changingStatus(rowData) }} disabled={!(!managementCheck() && rolePermissions?.subscriptionStatus)} />
                }

                {
                    rowData?.subscription?.package?.site == "demo" &&
                    <>
                        <InputSwitch checked={rowData.subscription.status == "Inactive" ? false : true} onChange={() => { changingStatus(rowData) }} disabled={!(!managementCheck() && rolePermissions?.subscriptionStatus)} />
                        <p>Demo</p>
                    </>

                    // <InputSwitch checked={rowData.subscription.status == "Active"?true:false} onChange={()=>{changingStatus(rowData)}} />
                }
            </>
        )
    }

    const statusTemplate1 = (rowData, props) => (<> <InputSwitch checked={rowData.blocked} onChange={(e) => { updatePantryStatus(e, rowData, props.rowIndex) }} /></>)

    const actionBodyTemplate = (rowData) => {

        return (
            <>
                <span className="p-column-title">Action</span>
                <div className="actions" style={{ display: "flex" }}>
                    {rolePermissions?.editOnboardCtvm && <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-warning p-mx-1" aria-label="Edit" onClick={() => editmachine(rowData)} />}

                    {/* {
                        (rolePermissions?.deleteOnboardCtvm && ( rowData.subscription?.package?.site == "demo" && rowData.siteName != "" ) || (rowData.subscription?.package.site == "permanent" && rowData.siteName != "" &&  rowData.subscription.status == 'Inactive') ) &&
                        <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-1" aria-label="Delete" onClick={() => confirmDeleteCVES(rowData)}/> 
                    }

                    { (rolePermissions?.deleteOnboardedDemo && rowData?.subscription?.package?.site == "demo") && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-1" aria-label="Delete" onClick={() => confirmDeleteCVES(rowData)}/> }
                    { (rolePermissions?.machinePantry && rowData?.subscription?.package?.site != "demo") && <Button icon="pi pi-users" title='Pantry Boy Information' className="p-button-rounded p-button-help p-mx-1" aria-label="Pantry Boy" onClick={()=>{openNewPantry(rowData)}}/> }
 */}


                    {/* { rolePermissions?.deleteOnboardCtvm && <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mx-2" aria-label="Delete" onClick={() => confirmDeleteCVES(rowData)}/> } */}
                    {/* { rolePermissions?.machinePantry && <Button icon="pi pi-users" title='Pantry Boy Information' className="p-button-rounded p-button-help" aria-label="Pantry Boy" onClick={()=>{openNewPantry(rowData)}}/> } */}

                    {/* {
                        (rowData?.subscription?.package?.site != "demo" && rolePermissions?.machineAgreement ) &&
                        <>
                            {
                                rowData.agreementStatus ? 
                                <Button icon="pi pi-file-pdf" title='View Agreement' className="p-button-rounded p-button-success p-mx-1" aria-label="View Agreement" onClick={()=>{openAgreement(rowData)}}/>
                                :
                                <Button icon="pi pi-file-pdf" title='Agreement is Pending / Please upload the Agreement' className="p-button-rounded p-button-danger p-mx-1" aria-label="View Agreement" onClick={()=>{openAgreement(rowData)}}/>
                            }
                        </>
                    } */}

                    {
                        rolePermissions?.onboardMoreSettingButton ?
                            <Button icon="pi pi-cog" title='Actions' className="p-button-rounded p-button-primary p-mx-1" aria-label="Action" onClick={() => openAllActions(rowData)} />
                            : '-'
                    }

                    {
                        (rowData?.app_cat == "Tapri Kettle" && rowData.subscription?.package.site == "permanent" && rowData.siteName != "") &&
                        <Button icon="pi pi-images" title='View KYC Documents' className="p-button-rounded p-button-help p-mx-1" aria-label="Action" onClick={() => openGallery(rowData)} />
                    }
                </div>
            </>
        );
    }

    const agreementTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title"></span>
                <div className="actions" style={{ display: "flex" }}>

                    {
                        (rowData?.subscription?.package?.site != "demo" && rolePermissions?.machineAgreement) &&
                        <>
                            {
                                rowData.agreementStatus ?
                                    <Button icon="pi pi-file-pdf" title='View Agreement' className="p-button-rounded p-button-success p-mx-2" aria-label="View Agreement" onClick={() => { openAgreement(rowData) }} />
                                    :
                                    <Button icon="pi pi-file-pdf" title='Agreement is Pending / Please upload the Agreement' className="p-button-rounded p-button-danger p-mx-2" aria-label="View Agreement" onClick={() => { openAgreement(rowData) }} />
                            }
                        </>
                    }
                </div>
            </>
        );
    }

    const actionPantryBodyTemplate = (rowData, props) => {
        return (
            <div className="actions" style={{ display: "flex" }}>
                <Button icon="pi pi-pencil" title='Edit' className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => openEditBoyDialog(rowData, props.rowIndex)} />
                <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-mr-2 p-mb-2" onClick={() => confirmDeletePantry(rowData, props.rowIndex)} />
            </div>
        );
    }

    const actionAgreeBodyTemplate = (rowData, props) => {
        return (
            <div className="actions" style={{ display: "flex" }}>
                <Button icon="pi pi-eye" title='view' className="p-button-rounded p-button-success p-mr-2 p-mb-2" onClick={() => { viewAgreement(rowData) }} />
            </div>
        );
    }

    const header = (
        <div className="table-header">

            <span className="p-input-icon-right p-m-1">
                {
                    rolePermissions?.ctvmOnboardAct &&
                    <>
                        <div className="p-inputgroup">
                            <Button className='custom-group-btn' label="STAGE" />
                            <Dropdown value={multiselectValue} onChange={onChangeStage} options={multiselectValues} optionLabel="name" placeholder="Select Filter" filter
                                className="p-m-0 multiselect-custom" />
                            {/* </div>

                        <div className="p-inputgroup"> */}
                            <Button className='custom-group-btn p-ml-2' label="STATUS" />
                            <Dropdown value={selectedStatus} onChange={onChangeStatus} options={statusOptions} optionLabel="name" placeholder="Select Status" filter
                                className="p-m-0 multiselect-custom" />
                        </div>
                    </>

                }
            </span>

            <span className="p-input-icon-left">

                <div className="p-inputgroup p-m-1">
                    <Button className='custom-group-btn' icon="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
                    {initialValue?.userType != 'invenstor' && <Button icon="pi pi-download" className='custom-group-btn p-button-rounded p-mt-2 p-mx-4' onClick={exportCSV} />}
                </div>
            </span>
        </div>
    );

    const hideKycDialog = () => { setKycDialog(false) };
    const hideProductDialog = () => { setProductDialog(false) };
    const hideEditMachineDialog = () => { setEditmachineDialog(false) };
    const hideAddBoyDialog = () => { setAddBoyDialog(false) };
    const hideDeletePantryDialog = () => { setDeletePantryDialog(false) };
    const hideAgreementDialog = () => { setAgreementDialog(false) };
    const hideUploaderDialog = () => { setUploaderDialog(false) };
    const hideStatusConfirmDialog = () => { setStatusConfirmDialog(false) };
    const hideVeriyDialog = () => { setVerifyCustomerDialog(false) }

    const settingDialogFooter = commonCloseDialogFooter('Close', hideSettingDialog)
    const kycDialogFooter = commonCloseDialogFooter('Close', hideKycDialog)
    const onboardDialogFooter = commonFormDialogFooter('Close', hideProductDialog, 'Submit', 'my-form', (fgMachines?.length > 0))
    const onboardEditDialogFooter = commonFormDialogFooter('Close', hideEditMachineDialog, 'Submit', 'edit-cves', !editLoading)
    const pantryBoyDialogFooter = commonCloseDialogFooter('Cancel', hideDialog1)
    const deleteCVESDialogFooter = commonDialogFooter('Cancel', hideCVESDialog, 'Yes, Delete it', deleteCVES)
    const addBoyFooter = commonFormDialogFooter('Cancel', hideAddBoyDialog, 'Submit', 'add-pantry-boy')
    const deletePantryDialogFooter = commonDialogFooter('Cancel', hideDeletePantryDialog, 'Ok', deletePantryBoy)
    const agreementDialogFooter = commonCloseDialogFooter('Close', hideAgreementDialog)
    const uploadAgreeDialogFooter = commonFormDialogFooter('Close', hideUploaderDialog, 'Submit', 'add-agreement')
    const deleteSwichDialogFooter = commonFormDialogFooter('Close', hideSwitchDialog, 'Switch', 'switch-form', ((consumptionCheck && cardsCheck && counterCheck && pendingCheck) && !switchCheckStatus))
    const statusDialogFooter = commonDialogFooter('Cancel', hideStatusConfirmDialog, 'OK', updateMachinStatus)

    const verifyCustDialogFooter = (
        <>
            <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={hideVeriyDialog} />
            {
                isOtpClicked && <Button label="Verify" type="submit" form='verify-cust-form' icon="pi pi-check" className="p-button-success" />
            }
        </>
    )

    // const onboardDialogFooter = (
    //     <>
    //         <Button label="Close" icon="pi pi-times" className="p-button-danger" onClick={()=>{setProductDialog(false)}} />
    //         { fgMachines?.length && <Button label="Submit" type="submit" form='my-form' icon="pi pi-plus" className="p-button-success"/> }
    //     </>
    // );

    return (

        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <Error errorDialog={errorDialog} />
                <Toast ref={toast} position='center' />
                <Toolbar className="p-mb-4 p-toolbar" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">

                            {
                                (managementCheck() || initialValue?.userType == 'fintech') ?

                                    <TabView activeIndex={activeTabIndex} onTabChange={changingTab}>
                                        <TabPanel header="CVES">

                                            <DataTable ref={dt} value={onboardMachines}
                                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} CVES's"
                                                globalFilter={globalFilter} emptyMessage="No CVES found." header={header} exportFilename={`${multiselectValue.name} On-Boarded Machines`}
                                                loading={loading}>
                                                <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                                                <Column field="id" header="CVES ID" sortable body={machineTemplate}></Column>
                                                <Column field="brand" header="Brand/Model" sortable body={brandBodyTemplate}></Column>
                                                <Column field="subid" header="Subscription ID" body={subIdBodyTemplate} sortable exportable={false}></Column>
                                                {/* <Column field="siteName" header="Name" sortable body={CommonBodyForTable} ></Column> */}
                                                <Column field="companyName" header="Acc Name/Loc" body={CommonBodyForTable} sortable></Column>
                                                <Column field="facilityName" header="Facility" body={CommonBodyForTable} exportable={managementCheck() ? false : true} sortable></Column>
                                                <Column field="keyAccManager" header="KAM" body={CommonBodyForTable} exportable={managementCheck() ? false : true} sortable></Column>
                                                {/* <Column field="facLocation" header="Location" body={CommonBodyForTable} sortable></Column> */}
                                                {
                                                    initialValue?.userType !== 'invenstor' &&
                                                    <Column field="facilityAddr" header="Facility Address" body={CommonBodyForTable} style={{ display: 'none' }} sortable></Column>
                                                }

                                                {managementCheck() && <Column field="canLabel" header="Model" body={CommonBodyForTable} sortable></Column>}
                                                {managementCheck() && <Column field="city" header="City" body={CommonBodyForTable} sortable></Column>}
                                                {managementCheck() && <Column field="state" header="State" body={CommonBodyForTable} sortable></Column>}
                                                {managementCheck() && <Column field="installationDate" header="Installation Date" body={CommonBodyForTable} ></Column>}
                                                <Column field="subscription_status" header="Subscription Status" exportable={false} body={statusTemplate} sortable></Column>
                                                {initialValue?.userType == 'invenstor' && <Column field="invester_customer_address" header="Customer Address" body={CommonBodyForTable} sortable style={{ display: 'none' }} ></Column>}
                                                {initialValue?.userType == 'invenstor' && <Column field="invester_careOf" header="Care Of" body={CommonBodyForTable} sortable style={{ display: 'none' }} ></Column>}
                                                {initialValue?.userType == 'invenstor' && <Column field="invester_install_date" header="Installation Date" body={CommonBodyForTable} sortable style={{ display: 'none' }} ></Column>}


                                            </DataTable>

                                        </TabPanel>
                                    </TabView> : <TabView activeIndex={activeTabIndex} onTabChange={changingTab} >
                                        <TabPanel header="CVES">

                                            {
                                                loading ?
                                                    <MachineOnboardDataTableSkeleton />
                                                    :
                                                    <DataTable ref={dt} value={onboardMachines}
                                                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} CVES's"
                                                        globalFilter={globalFilter} emptyMessage="No CVES found." header={header} exportFilename={`${multiselectValue.name} On-Boarded Machines`}
                                                        loading={loading}>
                                                        <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                                                        <Column field="id" header="CVES ID" sortable body={machineTemplate}></Column>
                                                        <Column field="subid" header="Subscription ID" body={subIdBodyTemplate} sortable></Column>
                                                        <Column field="companyName" header="Account Name" body={CommonBodyForTable} sortable></Column>
                                                        <Column field="Customer_Account_ID" header="Account ID" body={CommonBodyForTable} sortable></Column>
                                                        <Column field="cans" header="Canister's" body={CommonBodyForTable} sortable></Column>
                                                        <Column field="operateMode" header="Mode" body={CommonBodyForTable} sortable></Column>
                                                        <Column field="brand" header="Category / Model" sortable body={brandBodyTemplate}></Column>
                                                        {/* <Column field="siteName" header="Site Name" sortable body={CommonBodyForTable} ></Column> */}
                                                        <Column field="facilityName" header="Facility" body={CommonBodyForTable} exportable={managementCheck() ? false : true} sortable></Column>
                                                        <Column field="facilityId" header="Facility ID" body={CommonBodyForTable} exportable={managementCheck() ? false : true} style={{ overflowWrap: 'break-word', minWidth: '8rem' }} sortable></Column>
                                                        <Column field="keyAccManager" header="KAM" body={CommonBodyForTable} exportable={managementCheck() ? false : true} sortable></Column>
                                                        <Column field="facLocation" header="Location" body={CommonBodyForTable} sortable></Column>
                                                        {/* { managementCheck() && <Column field="canLabel" header="Model" body={CommonBodyForTable} sortable></Column> } */}
                                                        <Column field="city" header="City" body={CommonBodyForTable} style={{ display: 'none' }}></Column>
                                                        <Column field="state" header="State" body={CommonBodyForTable} style={{ display: 'none' }}></Column>
                                                        <Column field="installationDate" header="Installation Date" body={CommonBodyForTable} style={{ display: 'none' }}></Column>
                                                        <Column field="stageLabel" header="Stage" body={CommonBodyForTable} style={{ display: 'none' }}></Column>
                                                        <Column field="subscription.status" header="Subscription Status" body={nestedBodyForTable} sortable style={{ display: 'none' }}></Column>
                                                        <Column field="subscription_status" header="Subscription Status" exportable={false} body={statusTemplate} sortable></Column>
                                                        <Column field="facilityAddr" header="Facility Address" body={CommonBodyForTable} style={{ display: 'none' }} sortable></Column>
                                                        {/* { initialValue?.userType == 'customer' && <Column header="Agreement" body={agreementTemplate}></Column> } */}
                                                        {(initialValue?.userType !== 'customer' || initialValue?.userType !== 'accountmanager' || initialValue?.userType !== 'distributor') && <Column header="Actions" body={actionBodyTemplate}></Column>}
                                                    </DataTable>
                                            }

                                        </TabPanel>

                                        <TabPanel header="TAPRI KETTLE">
                                            <DataTable ref={dt} value={onboardMachines}
                                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Cherise Tapri's"
                                                globalFilter={globalFilter} emptyMessage="No Tapri Kettle found." header={header} exportFilename={`${multiselectValue.name} On-Boarded Machines`}
                                                loading={loading}>
                                                <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                                                <Column field="id" header="CVES ID" sortable body={machineTemplate}></Column>
                                                <Column field="subid" header="Subscription ID" body={subIdBodyTemplate} sortable></Column>
                                                <Column field="companyName" header="Account Name" body={CommonBodyForTable} sortable></Column>
                                                <Column field="Customer_Account_ID" header="Account ID" body={CommonBodyForTable} sortable></Column>
                                                <Column field="brand" header="Category" sortable body={brandBodyKettleTemplate}></Column>
                                                <Column field="facilityName" header="Facility" body={CommonBodyForTable} sortable></Column>
                                                <Column field="keyAccManager" header="KAM" body={CommonBodyForTable} sortable></Column>
                                                <Column field="facLocation" header="Location" body={CommonBodyForTable} sortable></Column>
                                                {managementCheck() && <Column field="canLabel" header="Model" body={CommonBodyForTable} sortable></Column>}
                                                {managementCheck() && <Column field="city" header="City" body={CommonBodyForTable} sortable></Column>}
                                                <Column field="city" header="City" body={CommonBodyForTable} style={{ display: 'none' }}></Column>
                                                <Column field="state" header="State" body={CommonBodyForTable} style={{ display: 'none' }}></Column>
                                                <Column field="installationDate" header="Installation Date" body={CommonBodyForTable} style={{ display: 'none' }}></Column>
                                                <Column field="stageLabel" header="Stage" body={CommonBodyForTable} style={{ display: 'none' }}></Column>
                                                <Column field="subscription.status" header="Subscription Status" body={nestedBodyForTable} sortable style={{ display: 'none' }}></Column>
                                                <Column field="subscription_status" header="Subscription Status" body={statusTemplate} sortable exportable={false}></Column>
                                                <Column field="facilityAddr" header="Facility Address" body={CommonBodyForTable} style={{ display: 'none' }} sortable></Column>
                                                {/* <Column field="subscription_status" header="Subscription Status" exportable={false} body={statusTemplate} sortable></Column>  */}
                                                {initialValue?.userType == 'customer' && <Column header="Agreement" body={agreementTemplate}></Column>}
                                                {initialValue?.userType != 'customer' && <Column header="Actions" body={actionBodyTemplate}></Column>}
                                            </DataTable>
                                        </TabPanel>

                                        <TabPanel header="CAPSULE">
                                            <DataTable ref={dt} value={onboardMachines}
                                                dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Capsule's"
                                                globalFilter={globalFilter} emptyMessage="No capsule found." header={header} exportFilename={`${multiselectValue.name} On-Boarded Machines`}
                                                loading={loading}>
                                                <Column field="srid" header="Sr.No." body={srBodyTemplate} exportable={false}></Column>
                                                <Column field="id" header="Capsule ID" sortable body={machineTemplate}></Column>
                                                <Column field="subid" header="Subscription ID" body={subIdBodyTemplate} sortable></Column>
                                                <Column field="companyName" header="Account Name" body={CommonBodyForTable} sortable></Column>
                                                <Column field="Customer_Account_ID" header="Account ID" body={CommonBodyForTable} sortable></Column>
                                                <Column field="brand" header="Category" sortable body={brandBodyKettleTemplate}></Column>
                                                <Column field="siteName" header="Site Name" sortable body={CommonBodyForTable} ></Column>
                                                <Column field="facilityName" header="Facility" body={CommonBodyForTable} sortable></Column>
                                                <Column field="keyAccManager" header="KAM" body={CommonBodyForTable} sortable></Column>
                                                <Column field="facLocation" header="Location" body={CommonBodyForTable} sortable></Column>
                                                {managementCheck() && <Column field="canLabel" header="Model" body={CommonBodyForTable} sortable></Column>}
                                                {managementCheck() && <Column field="city" header="City" body={CommonBodyForTable} sortable></Column>}
                                                <Column field="subscription.status" header="Subscription Status" body={nestedBodyForTable} sortable style={{ display: 'none' }}></Column>
                                                <Column field="subscription_status" header="Subscription Status" body={statusTemplate} exportable={false} sortable></Column>
                                                {initialValue?.userType == 'customer' && <Column header="Agreement" body={agreementTemplate}></Column>}
                                                {initialValue?.userType != 'customer' && <Column header="Actions" body={actionBodyTemplate}></Column>}
                                            </DataTable>
                                        </TabPanel>

                                    </TabView>
                            }

                        </div>
                    </div>
                </div>

                {/* ADDDASH */}
                <Dialog visible={productDialog} style={{ width: '850px' }} header={`On-Board CVES ${formAction ? editMachineData?.id : ''}`} modal className="p-fluid" footer={onboardDialogFooter} onHide={() => { setProductDialog(false) }} maximizable={true} blockScroll={true}>
                    <form onSubmit={onboardSubmit} id="my-form" className="p-fluid">
                        <h5 className='p-mt-2'>SUBSCRIPTION DETAILS</h5>
                        {/* TESTCHANGE */}
                        <div className="p-field">
                            <label htmlFor="subscription-category">Subscription Category</label>
                            <Dropdown id="subscription-category" name='site' value={subcatItem} onChange={catergorySelection} autoFocus options={dropdownItems} optionLabel="name" placeholder="Select Subscription Category" disabled={true}></Dropdown>
                            {/* disabled={true} */}
                        </div>
                        {
                            demoDialog &&
                            <>
                                <div className='p-grid'>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="demo-site">Demo Site</label>
                                            <InputText id="demo-site" name='siteName' placeholder='Demo site' defaultValue={formAction ? editMachineData?.siteName : ''} onChange={changeAddBoard} autoComplete="off" required />
                                        </div>
                                    </div>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="demo-site-location">Demo Site Location</label>
                                            <InputText id="demo-site-location" name='demositelocation' onChange={changeAddBoard} placeholder='Demo Site Location' autoComplete="off" required />
                                        </div>
                                    </div>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="demo-purpose">Demo Purpose</label>
                                            <Dropdown id="demo-purpose" name='demopurpose' value={demoPurposeItem} onChange={changeAddBoard} options={demoPurposeDropdownItems} optionLabel="name" placeholder="Select Demo Purpose" autoComplete="off" required></Dropdown>
                                        </div>
                                    </div>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="demo-duration">Demo Duration(No of Days)</label>
                                            <InputText id="demo-duration" name='demoduration' onChange={changeAddBoard} placeholder='Demo Duration' type='number' min={1} autoComplete="off" required />
                                        </div>
                                    </div>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="demo-consumption">Cup Consumption Limit(per day)</label>
                                            <InputText id="demo-consumption" name='expcupconsumption' onChange={changeAddBoard} placeholder='Cup Consumption Limit' type='number' min={0} autoComplete="off" required />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {
                            commercialDialog &&
                            <div className='p-grid'>
                                <div className='p-col-6'>
                                    <div className="p-field">
                                        <label htmlFor="demo-site">Site Name</label>
                                        <InputText id="demo-site" name='siteName' placeholder='site Name' defaultValue={''} onChange={changeAddBoard} autoComplete="off" required />
                                    </div>
                                </div>
                            </div>
                        }

                        {
                            (demoDialog || commercialDialog) &&
                            <>

                                <h5 className='p-mt-2'>CVES DETAILS</h5>

                                <div className="p-field">
                                    <label htmlFor="cat-id">Select Category </label>
                                    <Dropdown className="p-m-0 multiselect-custom" id="cat-id" value={selectedCat} onChange={selectCategory} options={applicationCategoryItems} optionLabel="name" placeholder="Select Category" disabled={formAction} filter required></Dropdown>
                                </div>

                                {
                                    !formAction &&
                                    <div className="p-field">
                                        <label htmlFor="CVES-id">Select CVES  <small>{`(Available : ${fgMachinesToOnboard?.length})`}</small>   </label>
                                        <Dropdown className="p-m-0 multiselect-custom" name='ctvm-id' id="CVES-id" value={machine} onChange={selectMachine} options={fgMachinesToOnboard} optionLabel="id" placeholder="Select CVES" disabled={formAction} filter required></Dropdown>
                                    </div>
                                }

                                {
                                    formAction &&
                                    <div className="p-field">
                                        <label htmlFor="CVES-name">CVES ID</label>
                                        <InputText id="CVES-name" defaultValue={formAction ? editMachineData?.id : ''} placeholder='CVES ID' disabled={formAction} autoComplete="off" />
                                    </div>
                                }

                                <h5 className='p-mt-2'>SERVICE TEAM DETAILS</h5>
                                <div className='p-grid'>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="sm-id">Sales and Marketing Manager</label>
                                            <Dropdown id="sm-id" value={selectedMuser} onChange={changeMarketer} options={marketingUsers} optionLabel="fullName" placeholder="Select Sales and Marketing Manager" filter required></Dropdown>
                                        </div>
                                    </div>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="km-id">Key Account Manager</label>
                                            <Dropdown id="km-id" value={selectedKAM} onChange={changeKeyAM} options={keyAccManagers} optionLabel="displayName" placeholder="Select Key Account Manager" filter required></Dropdown>
                                        </div>
                                    </div>
                                    <div className='p-col-6'>
                                        <div className="p-field">
                                            <label htmlFor="tapariman-id">Tapariman</label>
                                            <Dropdown id="tapariman-id" value={selectedTapriman} onChange={changeTapri} options={taprimans} optionLabel="fullName" placeholder="Select Tapariman" filter required></Dropdown>
                                        </div>
                                    </div>
                                </div>

                                <h5 className='p-mt-2'>ACCOUNT DETAILS</h5>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className="p-field">
                                            <label htmlFor="ct-id">Customer Type</label>
                                            <Dropdown id="ct-id" value={selectedCustomer} onChange={changeCustomer} options={customerTypeOptions} optionLabel="name" placeholder="Select Customer Type" required></Dropdown>
                                        </div>
                                    </div>
                                </div>

                                {
                                    account &&
                                    <>
                                        <div className="p-field">
                                            <label htmlFor="acc-id">Account</label>
                                            <Dropdown id="acc-id" value={selectedAccount} onChange={changeAccountD} options={accounts} optionLabel="label" placeholder="Select Account" filter required></Dropdown>
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="company-name">Comapny Name</label>
                                            <InputText id="company-name" value={company ? company.billing_details?.company : ''} placeholder='Company Name' disabled autoComplete="off" required />
                                        </div>
                                        {
                                            selectedAccount != null &&
                                            <div className="p-field">
                                                <label htmlFor="cves-own">CVES Ownership</label>
                                                {/* <InputText id="cves-own" value={ownerShipDetails?.distributor_data ? 'Distributor' : 'CIPL'} placeholder='CVES Ownership' disabled autoComplete="off" required/> */}
                                                <InputText id="cves-own" value={ownerShipDetails?.ownerShip || 'CIPL'} placeholder='CVES Ownership' disabled autoComplete="off" required />
                                            </div>
                                        }
                                        {
                                            (selectedAccount != null && ownerShipDetails?.distributor_data) &&
                                            <>
                                                <div className='p-grid'>
                                                    <div className='p-col-6'>
                                                        <div className="p-field">
                                                            <label htmlFor="dist-name">Distributor's Name</label>
                                                            <InputText id="dist-name" value={ownerShipDetails?.distributor_data?.name} placeholder='Distributors Name' disabled autoComplete="off" required />
                                                        </div>
                                                    </div>
                                                    <div className='p-col-6'>
                                                        <div className="p-field">
                                                            <label htmlFor="dist-type">Distributor's Type</label>
                                                            <InputText id="dist-type" value={ownerShipDetails?.distributor_data.userRole} placeholder='Distributors Type' disabled autoComplete="off" required />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }

                                {
                                    leads &&
                                    <>
                                        <div className='p-grid'>
                                            <div className='p-col-12'>
                                                <div className="p-field">
                                                    <label htmlFor="company-name">Comapny Name</label>
                                                    <InputText id="company-name" placeholder='Company Name' name='company' onChange={changeAddBoard} autoComplete="off" required />
                                                </div>
                                            </div>
                                            <div className='p-col-12'>
                                                <div className="p-field">
                                                    <label htmlFor="lead-id">Leads</label>
                                                    <Dropdown id="lead-id" value={selectedLeads} name='select-lead' onChange={changeAddBoard} options={allLeads} optionLabel="name" placeholder="Select Lead" filter required></Dropdown>
                                                </div>
                                            </div>
                                            {/* <div className='p-col-3'>
                                    <div className="p-field">
                                        <label htmlFor="add-lead"></label>
                                        <Button type="button" id='add-lead' label="Add Lead" icon="pi pi-user-plus" className=" p-button p-mr-2 p-mt-3" onClick={()=>{setLeadDialog(true)}} />
                                    </div>
                                </div> */}
                                        </div>
                                    </>
                                }

                                <div className='p-grid'>
                                    <div className='p-col-9'>
                                        <div className="p-field">
                                            <label htmlFor="facility-id">Facility Name</label>
                                            <Dropdown id="facility-id" value={facilityItem} options={facility} onChange={changeAddFac} optionLabel="facname" placeholder="Select Facility" required></Dropdown>
                                        </div>
                                    </div>
                                    <div className='p-col-3'>
                                        <div className="p-field">
                                            <label htmlFor="facility-id"></label>
                                            {<Button type="button" id='facility-id' label="Add Facility" icon="pi pi-user-plus" disabled={!((account && selectedAcc) || (leads && selectedLds))} className=" p-button p-mr-2 p-mt-3" onClick={openFacility} />}
                                        </div>
                                    </div>
                                </div>

                                <div className="p-field">
                                    <label htmlFor="do-id">Dispensing Option</label>
                                    <Dropdown id="do-id" value={dispensingItem} name='dis-option' onChange={changeAddBoard} options={despensingOptionDropdownList} optionLabel="name" placeholder="Select Despensing Option" required></Dropdown>
                                </div>

                                {
                                    dispensingItem?.code == 'paid' &&
                                    <div className="p-col-12">
                                        <div className="p-field">
                                            <label htmlFor="bev-set">Beverage Setting</label>
                                            <div className='p-grid'>
                                                <div className="p-col-12 p-md-2">
                                                    <div className="p-field-checkbox">
                                                        <Checkbox name="rfid" checked={paidOptions.rfid} onChange={changeAddBoard} />
                                                        <label>RFID</label>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <div className="p-field-checkbox">
                                                        <Checkbox name="nfc" checked={paidOptions.nfc} onChange={changeAddBoard} />
                                                        <label>NFC</label>
                                                    </div>
                                                </div>
                                                {/* <div className="p-col-12 p-md-2">
                                        <div className="p-field-checkbox">
                                            <Checkbox name="fingerprint" checked={paidOptions.fingerprint} onChange={changeAddBoard} />
                                            <label>Fingerprint</label>
                                        </div>
                                    </div> */}
                                                <div className="p-col-12 p-md-2">
                                                    <div className="p-field-checkbox">
                                                        <Checkbox name="qr" checked={paidOptions.qr} onChange={changeAddBoard} />
                                                        <label>Customer QR</label>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <div className="p-field-checkbox">
                                                        <Checkbox name="onlinePayment" checked={paidOptions.onlinePayment} onChange={changeAddBoard} />
                                                        <label>UPI Payment</label>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <div className="p-field-checkbox">
                                                        <Checkbox name="password" checked={paidOptions.password} onChange={changeAddBoard} />
                                                        <label>Password</label>
                                                    </div>
                                                </div>
                                                <div className="p-col-12 p-md-2">
                                                    <div className="p-field-checkbox">
                                                        <Checkbox name="allPaidOp" checked={paidOptions.all} onChange={changeAddBoard} />
                                                        <label>All</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    ((paidOptions?.rfid || paidOptions?.all) && dispensingItem?.code == 'paid') &&
                                    <div className="p-col-12">
                                        <div className="p-field">
                                            <label htmlFor="online-rfid">Online RFID</label><br />
                                            <InputSwitch id='online-rfid' name='onlineRfid' checked={onlineRfidStatus} onChange={changeAddBoard} />
                                        </div>
                                    </div>
                                }

                                {
                                    ((paidOptions.password || paidOptions?.all) && dispensingItem?.code === 'paid') &&
                                    <div className="p-col-12">
                                        <div className="p-field">
                                            <label htmlFor="setpassword">Set Password</label><br />
                                            <InputText id="setpassword" name='dispPassword' placeholder='Set Password' onChange={changeAddBoard} required />
                                        </div>
                                    </div>
                                }


                                {
                                    (paidOptions?.onlinePayment || paidOptions?.all) &&
                                    <>
                                        <div className="p-col-12">
                                            <div className="p-field">
                                                <label htmlFor="paytm">Paytm</label><br />
                                                <Dropdown id="paytm" value={paytm} onChange={(e) => { setPaytm(e.value) }} options={paymentValue} optionLabel="name" placeholder="Paytm" required></Dropdown>
                                            </div>
                                        </div>
                                        <div className='p-col-6'>
                                            <div className="p-field">
                                                <label htmlFor="CVES-cup-cost">Paytm Cup Cost (INR)</label>
                                                <InputText id="CVES-cup-cost" name='ctvmCupCost' placeholder='Paytm Cup Cost (INR)' onChange={changeAddBoard} step="0.001" defaultValue={formAction ? editMachineData?.subscription?.package?.ctvmfullcup : ''} type="number" min="0" autoComplete="off" />
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                        }

                        {
                            (demoDialog || commercialDialog) &&
                            <>
                                <h5 className='p-mt-5'>CUPAGE DETAILS</h5>
                                <div className="p-field">
                                    <label htmlFor="cup-recharge-id">Cup Recharge Option</label>
                                    <Dropdown id="cup-recharge-id" value={selectedrechargeOp} name='recharge-op' onChange={changeAddBoard} options={cupRechargeDropdownList} optionLabel="name" placeholder="Cup Recharge Option" required></Dropdown>
                                </div>
                                {
                                    selectedrechargeOp?.name == 'Prepaid' &&
                                    <div className="p-field">
                                        <label htmlFor="cup-count">No of Cups</label>
                                        <InputText id="cup-count" placeholder='Cup Count' defaultValue="" type="number" min="0" autoComplete="off" required />
                                    </div>
                                }
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className="p-field">
                                            <label htmlFor="cup-cost">Cup Cost</label>
                                            <InputNumber id="cup-cost" placeholder='Cup Cost' name='cup_cost' onChange={changeCup} defaultValue={prizing ? prizing?.cupprice?.pricehalf : ''} min="0" step="0.001" suffix=" ₹" required />
                                            {/* <InputText id="cup-cost" placeholder='Cup Cost' onChange={changeCup} defaultValue={prizing?prizing?.cupprice?.pricehalf:''} type="number" min="0" step="0.1" suffix="₹" /> */}
                                        </div>

                                    </div>
                                    {/* <div className='p-col-6'>
                        <div className="p-field">
                            <label htmlFor="CVES-cup-cost">CVES Cup Cost</label>
                            <InputNumber id="CVES-cup-cost" placeholder='CVES Cup Cost (INR)' name='cves_cup_cost' onChange={cvesChangeCup} step="0.1" defaultValue={formAction?editMachineData?.subscription?.package?.ctvmfullcup:''} min="0" suffix=" ₹" />
                        </div> 
                        </div> */}
                                </div>
                            </>
                        }

                        {
                            commercialDialog &&
                            <>
                                <h5 className='p-mt-2'>ANALYTICS DETAILS</h5>
                                <div className="p-field">
                                    <label htmlFor="conssumption-target">Consumption Target (Cups)</label>
                                    <InputText id="conssumption-target" placeholder='Consumption Target (Cups)' name='target_cups' onChange={changeAddBoard} defaultValue={formAction ? editMachineData?.subscription?.package?.target_cups : ''} type="number" min="0" autoComplete="off" required />
                                </div>

                                <div className='package-div'>
                                    <h5 className='p-mt-2'>PACKAGE DETAILS</h5>
                                    <div className="p-field">
                                        <label htmlFor="tapri-package">Tapri Package</label>
                                        <Dropdown id="tapri-package" value={packageItem} name='rentaloption' onChange={changeAddBoard} options={packageDropdownList} optionLabel="name" placeholder="Select Tapri Package" required></Dropdown>
                                    </div>

                                    {
                                        (packageItem?.code == 'buy') &&
                                        <div className="p-field">
                                            <label htmlFor="ctvmCost">CVES Cost</label>
                                            <InputText id="ctvmCost" placeholder='CVES Cost' name='ctvm_cost' onChange={changeAddBoard} type="number" min="0" autoComplete="off" required />
                                        </div>
                                    }

                                    {
                                        (packageItem?.code == 'maintenance' || packageItem?.code == 'maintenance/deposit') &&
                                        <>
                                            <div className="p-field">
                                                <label htmlFor="maintenence">Maitenance Plan</label>
                                                <Dropdown id="maintenence" name='packageName' value={maintainenceItem} onChange={changeAddBoard} options={maintenancePackages} optionLabel="name" placeholder="Select Maintenance Plan" required></Dropdown>
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="maintenence-term">Payment Maintenance Term</label>
                                                <Dropdown id="maintenence-term" name='paymentterms' value={mainTermItem} onChange={changeAddBoard} options={maintainenceterms} optionLabel="name" placeholder="Select Maintenance Plan" required></Dropdown>
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="maintenance-amount">Maintenance Amount (INR)</label>
                                                <InputText id="maintenance-amount" name='cost' onChange={changeAddBoard} placeholder='Maintenance Amount (INR)' type="number" min="0" autoComplete="off" required />
                                            </div>
                                        </>
                                    }

                                    {
                                        (packageItem?.code == 'deposit' || packageItem?.code == 'maintenance/deposit') &&
                                        <>
                                            <div className="p-field">
                                                <label htmlFor="type-of-dept">Type of Deposite</label>
                                                <Dropdown id="type-of-dept" value={depositeType} name='deposit' onChange={changeAddBoard} options={typeOfDeposite} optionLabel="name" placeholder="Select Deposite Plan" required></Dropdown>
                                            </div>
                                            <div className="p-field">
                                                <label htmlFor="upfront-dept">Upfront Deposite (INR)</label>
                                                <InputText id="upfront-dept" placeholder='Upfront Deposite (INR)' name='depositamt' onChange={changeAddBoard} type="number" min="0" autoComplete="off" required />
                                            </div>
                                        </>
                                    }

                                </div>
                            </>
                        }

                        {
                            (demoDialog || commercialDialog) &&
                            <>
                                <h5 className='p-mt-2'>BILLING DETAILS</h5>
                                <div className="p-field">
                                    <label htmlFor="billing-pattern">Billing Pattern</label>
                                    <Dropdown id="billing-pattern" name='billing_pattern' value={billingPattern} onChange={changeAddBoard} options={billingPatternOptions} optionLabel="name" placeholder="Select Billing Pattern" required></Dropdown>
                                </div>

                                {
                                    (billingPattern?.code == 'postconsumption') &&
                                    <div className="p-field">
                                        <label htmlFor="invoice-cycle">Invoice Cycle</label>
                                        <Dropdown id="invoice-cycle" name='invoiceCycle' value={invoiceCycle} onChange={changeAddBoard} options={invoiceCycleOptions} optionLabel="name" placeholder="Select Invoice Cycle" required></Dropdown>
                                    </div>
                                }
                            </>
                        }

                        {
                            commercialDialog &&
                            <>
                                <div className="p-field">
                                    <label htmlFor="extension-period">Extension Period</label>
                                    <InputText id="extension-period" name='extperiod' placeholder='Extension Period' onChange={changeAddBoard} type="number" min="0" defaultValue={prizing?.packages[0]?.extperiod} autoComplete="off" required />
                                </div>
                            </>
                        }

                        {
                            (demoDialog || commercialDialog) &&
                            <>
                                <h5 className='p-mt-2'>OFFSET DETAILS</h5>
                                <div className="p-field">
                                    <label htmlFor="bev-offset">Beverage Offset(ml)</label>
                                    <InputText id="bev-offset" name='wtr_offset' placeholder='Beverage Offset' defaultValue={defaultWtrOffset} onChange={(e) => { setDefaultWtrOffset(e.target.value) }} type="number" min="0" max={300} autoComplete="off" required />
                                </div>
                            </>
                        }
                    </form>
                </Dialog>

                {/* EDITDASH */}
                <Dialog visible={editmachineDialog} style={{ width: '850px' }} header={`Edit On-Board CVES (${formAction ? editMachineData?.id : '-'})`} modal className="p-fluid" footer={onboardEditDialogFooter} onHide={() => { setEditmachineDialog(false) }} maximizable={true} blockScroll={true}>
                    {
                        editLoading ?
                            <center>
                                <span><i className="pi pi-spin pi-spinner"></i></span>
                            </center>
                            :
                            <form onSubmit={updateOnboardSubmit} id="edit-cves" className="p-fluid">
                                <h5 className='p-mt-2'>SUBSCRIPTION DETAILS</h5>
                                <div className="p-field">
                                    <label htmlFor="subscription-category">Subscription Category</label>
                                    <Dropdown id="subscription-category" value={subcatItem} onChange={catergorySelection} autoFocus options={dropdownItems} optionLabel="name" placeholder="Select Subscription Category" disabled={formAction} ></Dropdown>
                                </div>

                                {
                                    demoDialog &&
                                    <>
                                        <div className="p-field">
                                            <label htmlFor="demo-site">Demo Site</label>
                                            <InputText id="demo-site" name='siteName' placeholder='Demo site' defaultValue={formAction ? editMachineData?.siteName : ''} onChange={changeUpdateBoard} autoComplete="off" />
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="demo-site-location">Demo Site Location</label>
                                            <InputText id="demo-site-location" name='demositelocation' onChange={changeUpdateBoard} placeholder='Demo Site Location' defaultValue={formAction ? editMachineData?.subscription?.package?.demositelocation : ''} autoComplete="off" />
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="demo-purpose">Demo Purpose</label>
                                            <Dropdown id="demo-purpose" name='demopurpose' value={demoPurposeItem} onChange={changeUpdateBoard} options={demoPurposeDropdownItems} optionLabel="name" placeholder="Select Demo Purpose" autoComplete="off"></Dropdown>
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="demo-duration">Demo Duration(No of Days)</label>
                                            <InputText id="demo-duration" name='demoduration' onChange={changeUpdateBoard} placeholder='Demo Duration' type='number' min={1} defaultValue={formAction ? editMachineData?.subscription?.package?.demoduration : ''} autoComplete="off" />
                                        </div>
                                        <div className="p-field">
                                            <label htmlFor="demo-consumption">Cup Consumption Limit(per day)</label>
                                            <InputText id="demo-consumption" name='expcupconsumption' onChange={changeUpdateBoard} placeholder='Cup Consumption Limit' type='number' defaultValue={formAction ? editMachineData?.subscription?.package?.expcupconsumption : ''} autoComplete="off" />
                                        </div>
                                    </>
                                }

                                {
                                    commercialDialog &&
                                    <div className="p-field">
                                        <label htmlFor="sub-name">Subscription Name</label>
                                        <InputText id="sub-name" name='siteName' defaultValue={formAction ? editMachineData?.siteName : ''} onChange={editOnBoardMain} placeholder='Subscription Name' autoComplete="off" />
                                    </div>
                                }

                                {
                                    (demoDialog || commercialDialog) &&
                                    <>
                                        {
                                            !formAction &&
                                            <div className="p-field">
                                                <label htmlFor="CVES-id">CVES</label>
                                                <Dropdown className="p-m-0 multiselect-custom" id="CVES-id" value={machine} onChange={selectMachine} options={fgMachines} optionLabel="id" placeholder="Select CVES" disabled={formAction} filter required></Dropdown>
                                            </div>
                                        }

                                        {
                                            formAction &&
                                            <div className="p-field">
                                                <label htmlFor="CVES-name">CVES ID</label>
                                                <InputText id="CVES-name" defaultValue={formAction ? editMachineData?.id : ''} placeholder='CVES ID' disabled={formAction} autoComplete="off" />
                                            </div>
                                        }

                                        <div className='p-grid'>
                                            <div className='p-col-12'>
                                                <div className="p-field">
                                                    <label htmlFor="sm-id">Sales and Marketing Manager</label>
                                                    <Dropdown id="sm-id" value={selectedMuser} onChange={changeMarketer} options={marketingUsers} optionLabel="fullName" placeholder="Select Sales and Marketing Manager" filter></Dropdown>
                                                </div>
                                            </div>

                                            <div className='p-col-12'>
                                                <div className="p-field">
                                                    <label htmlFor="km-id">Key Account Manager</label>
                                                    <Dropdown id="km-id" value={selectedKAM} onChange={changeKeyAM} options={keyAccManagers} optionLabel="displayName" placeholder="Select Key Account Manager" filter></Dropdown>
                                                </div>
                                            </div>

                                            <div className='p-col-12'>
                                                <div className="p-field">
                                                    <label htmlFor="tapariman-id">Tapariman</label>
                                                    <Dropdown id="tapariman-id" value={selectedTapriman} onChange={changeTapri} options={taprimans} optionLabel="fullName" placeholder="Select Tapariman" filter></Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="p-field">
                                <label htmlFor="ct-id">Customer Type</label>
                                <Dropdown id="ct-id" value={selectedCustomer} onChange={changeCustomer} options={customerTypeOptions} optionLabel="name" placeholder="Select Customer Type"></Dropdown>
                                </div> */}

                                        <div className="p-field">
                                            <label htmlFor="acc-id">Account</label>
                                            <InputText id="acc-id" defaultValue={formAction ? editMachineData?.acc_name : ''} placeholder='Account' disabled={formAction} autoComplete="off" />
                                        </div>

                                        {
                                            account &&
                                            <>
                                                <div className="p-field">
                                                    <label htmlFor="acc-id">Account</label>
                                                    <Dropdown id="acc-id" value={selectedAccount} onChange={changeAccountD} options={accounts} optionLabel="label" placeholder="Select Account" filter></Dropdown>
                                                </div>
                                                <div className="p-field">
                                                    <label htmlFor="company-name">Comapny Name</label>
                                                    <InputText id="company-name" value={company ? company.billing_details?.company : ''} placeholder='Company Name' disabled autoComplete="off" />
                                                </div>
                                            </>
                                        }
                                        {
                                            leads &&
                                            <>
                                                <div className='p-grid'>
                                                    <div className='p-col-12'>
                                                        <div className="p-field">
                                                            <label htmlFor="lead-id">Leads</label>
                                                            <Dropdown id="lead-id" value={selectedLeads} onChange={(e) => { setSelectedLeads(e.value); setSelectedLds(true) }} options={allLeads} optionLabel="name" placeholder="Select Lead" filter></Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div className='p-grid'>
                                            <div className='p-col-12'>
                                                <div className="p-field">
                                                    <label htmlFor="facility-id">Facility Name</label>
                                                    <Dropdown id="facility-id" name='facility_name' value={facilityItem} options={facility} onChange={changeUpdateBoard} optionLabel="facname" placeholder="Select Facility"></Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-field">
                                            <label htmlFor="do-id">Dispensing Option</label>
                                            <Dropdown id="do-id" value={dispensingItem} name='dispense_option' onChange={changeUpdateBoard} options={despensingOptionDropdownList} optionLabel="name" placeholder="Select Despensing Option"></Dropdown>
                                        </div>
                                        {
                                            dispensingItem?.code == 'paid' &&
                                            <div className="p-col-12">
                                                <div className="p-field">
                                                    <label htmlFor="bev-set">Beverage Setting</label>
                                                    <div className='p-grid'>
                                                        <div className="p-col-12 p-md-2">
                                                            <div className="p-field-checkbox">
                                                                <Checkbox name="rfid" checked={editPaymode?.rfid} onChange={changeUpdateBoard} />
                                                                <label>RFID</label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-2">
                                                            <div className="p-field-checkbox">
                                                                <Checkbox name="nfc" checked={editPaymode?.nfc} onChange={changeUpdateBoard} />
                                                                <label>NFC</label>
                                                            </div>
                                                        </div>
                                                        {/* <div className="p-col-12 p-md-2">
                                                <div className="p-field-checkbox">
                                                    <Checkbox name="fingerprint" checked={editPaymode?.fingerprint} onChange={changeUpdateBoard} />
                                                    <label>Fingerprint</label>
                                                </div>
                                            </div> */}
                                                        <div className="p-col-12 p-md-2">
                                                            <div className="p-field-checkbox">
                                                                <Checkbox name="qr" checked={editPaymode?.qr} onChange={changeUpdateBoard} />
                                                                <label>Customer QR</label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-2">
                                                            <div className="p-field-checkbox">
                                                                <Checkbox name="online" checked={editPaymode?.online} onChange={changeUpdateBoard} />
                                                                <label>UPI Payment</label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-2">
                                                            <div className="p-field-checkbox">
                                                                <Checkbox name="password" checked={editPaymode?.password} onChange={changeUpdateBoard} />
                                                                <label>Password</label>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-2">
                                                            <div className="p-field-checkbox">
                                                                <Checkbox name="all" checked={editPaymode?.rfid && editPaymode?.nfc && editPaymode?.fingerprint && editPaymode?.qr && editPaymode?.online && editPaymode?.password} onChange={changeUpdateBoard} />
                                                                <label>All</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {
                                            (editPaymode?.rfid && dispensingItem?.code === 'paid') &&

                                            <div className="p-col-12">
                                                <div className="p-field">
                                                    <label htmlFor="online-rfid">Online RFID</label><br />
                                                    <InputSwitch id='online-rfid' name='onlineRfid' checked={editPaymode?.onlineRfid} onChange={changeUpdateBoard} />
                                                </div>
                                            </div>
                                        }

                                        {
                                            (editPaymode?.password && dispensingItem?.code === 'paid') &&
                                            <div className="p-col-12">
                                                <div className="p-field">
                                                    <label htmlFor="setpassword">Set Password</label><br />
                                                    <InputText id="setpassword" name='dispPassword' placeholder='Set Password' defaultValue={formAction ? editMachineData?.mchmode?.paymode?.dispPassword : ''} onChange={changeUpdateBoard} required />
                                                </div>
                                            </div>
                                        }

                                        {
                                            (editPaymode?.online) &&
                                            <>
                                                <div className='p-grid'>
                                                    <div className="p-col-12">
                                                        <div className="p-field">
                                                            <label htmlFor="paytm">Paytm</label><br />
                                                            <Dropdown id="paytm" value={paytm} onChange={(e) => { setPaytm(e.value) }} options={paymentValue} optionLabel="name" placeholder="Paytm"></Dropdown>
                                                        </div>
                                                    </div>
                                                    {/* <div className='p-col-6'>
                                            <div className="p-field">
                                                <label htmlFor="CVES-cup-cost">Paytm Cup Cost (INR)</label>
                                                <InputText id="CVES-cup-cost" name='ctvmCupCost' placeholder='Paytm Cup Cost (INR)' onChange={changeUpdateBoard} step="0.001" defaultValue={formAction?editMachineData?.subscription?.package?.ctvmfullcup:''} type="number" min="0" autoComplete="off"/>
                                            </div>
                                        </div> */}

                                                    {/* This is for canister wise payments */}

                                                    {
                                                        !editMachineData?.mchmode?.canWisePayment &&
                                                        <div className='p-col-6'>
                                                            <div className="p-field">
                                                                <label htmlFor="CVES-cup-cost">Paytm Cup Cost (INR)</label>
                                                                <InputText id="CVES-cup-cost" name='ctvmCupCost' placeholder='Paytm Cup Cost (INR)' onChange={changeUpdateBoard} step="0.001" defaultValue={formAction ? editMachineData?.subscription?.package?.ctvmfullcup : ''} type="number" min="0" autoComplete="off" />
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        formAction && editMachineData?.mchmode?.canWisePayment &&
                                                        <>

                                                            {[...Array(editMachineData?.cans)].map((elementInArray, index) => (
                                                                <div className='p-col-3'>
                                                                    <div className="p-field">
                                                                        <label htmlFor={`canister${index}`} >Canister {index + 1} Cup Cost</label>
                                                                        <InputText id={`canister${index}`} name={`can${index + 1}`} placeholder={`Canister ${index + 1} Cup Cost`} defaultValue={formAction ? editMachineData?.subscription?.package?.canWiseCost?.[`can${index + 1}`] : 0} step="0.001" type="number" min="0" onChange={changeUpdateBoard} autoComplete="off" required />
                                                                    </div>
                                                                </div>
                                                            )
                                                            )}

                                                        </>
                                                    }

                                                </div>
                                            </>
                                        }

                                    </>
                                }

                                {
                                    (demoDialog || commercialDialog) &&
                                    <>
                                        <h5 className='p-mt-5'>CUPAGE DETAILS</h5>
                                        <div className="p-field">
                                            <label htmlFor="cup-recharge-id">Cup Recharge Option</label>
                                            <Dropdown id="cup-recharge-id" name='paymentterms' value={selectedrechargeOp} onChange={changeUpdateBoard} options={cupRechargeDropdownList} optionLabel="name" placeholder="Cup Recharge Option" disabled={true}></Dropdown>
                                        </div>
                                        {
                                            selectedrechargeOp?.name == 'Prepaid' &&
                                            <div className="p-field">
                                                <label htmlFor="cup-count">No of Cups</label>
                                                <InputText id="cup-count" placeholder='Cup Count' defaultValue="" type="number" min="0" autoComplete="off" />
                                            </div>
                                        }
                                        <div className='p-grid'>
                                            <div className='p-col-12'>
                                                <div className="p-field">
                                                    <label htmlFor="cup-cost">Cup Cost (INR)</label>
                                                    <InputText id="cup-cost" placeholder='Cup Cost' name='cupCost' onChange={changeUpdateBoard} defaultValue={formAction ? editMachineData?.subscription?.package?.fullcup : ''} type="number" min="0" step="0.001" autoComplete="off" disabled={initialValue.userType == "owner" ? false : true} />
                                                    {/* <InputText id="cup-cost" placeholder='Cup Cost' defaultValue={formAction?editMachineData?.subscription?.package?.fullcup:''} type="number" min="0" required /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    commercialDialog &&
                                    <>
                                        <h5 className='p-mt-2'>ANALYTICS DETAILS</h5>
                                        <div className="p-field">
                                            <label htmlFor="conssumption-target">Consumption Target (Cups) / Month</label>
                                            <InputText id="conssumption-target" placeholder='Consumption Target (Cups)' name='target_cups' onChange={changeUpdateBoard} defaultValue={formAction ? editMachineData?.subscription?.package?.target_cups : ''} type="number" min="0" autoComplete="off" />
                                        </div>

                                        <div className='package-div'>
                                            <h5 className='p-mt-2'>PACKAGE DETAILS</h5>
                                            <div className="p-field">
                                                <label htmlFor="tapri-package">Tapri Package</label>
                                                <Dropdown id="tapri-package" name='rentaloption' value={packageItem} onChange={changeUpdateBoard} options={packageDropdownList} optionLabel="name" placeholder="Select Tapri Package" disabled></Dropdown>
                                            </div>

                                            {
                                                (packageItem?.code == 'buy') &&
                                                <div className="p-field">
                                                    <label htmlFor="ctvmCost">CVES Cost</label>
                                                    <InputText id="ctvmCost" placeholder='CVES Cost' name='ctvm_cost' onChange={changeUpdateBoard} defaultValue={formAction ? editMachineData?.subscription?.package?.ctvm_cost : ''} type="number" min="0" autoComplete="off" />
                                                </div>
                                            }

                                            {
                                                (packageItem?.code == 'maintenance' || packageItem?.code == 'maintenance/deposit') &&
                                                <>
                                                    <div className="p-field">
                                                        <label htmlFor="maintenence">Maitenance Plan</label>
                                                        <Dropdown id="maintenence" name='packageName' value={maintainenceItem} onChange={changeUpdateBoard} options={maintenancePackages} optionLabel="name" placeholder="Select Maintenance Plan" required></Dropdown>
                                                        {/* prizing?.packages */}
                                                    </div>
                                                    <div className="p-field">
                                                        <label htmlFor="maintenence-term">Payment Maintenance Term</label>
                                                        <Dropdown id="maintenence-term" name='paymentterms' value={mainTermItem} onChange={changeUpdateBoard} options={maintainenceterms} optionLabel="name" placeholder="Select Maintenance Plan" required></Dropdown>
                                                    </div>
                                                    <div className="p-field">
                                                        <label htmlFor="maintenance-amount">Maintenance Amount (INR)</label>
                                                        <InputText id="maintenance-amount" name='cost' onChange={changeUpdateBoard} placeholder='Maintenance Amount (INR)' defaultValue={formAction ? editMachineData?.subscription?.package?.maintenance?.cost : ''} type="number" min="0" required autoComplete="off" />
                                                    </div>
                                                </>
                                            }

                                            {
                                                (packageItem?.code == 'deposit' || packageItem?.code == 'maintenance/deposit') &&
                                                <>
                                                    <div className="p-field">
                                                        <label htmlFor="type-of-dept">Type of Deposit edit</label>
                                                        <Dropdown id="type-of-dept" name='deposit' value={depositeType} onChange={changeUpdateBoard} options={typeOfDeposite} optionLabel="name" placeholder="Select Deposite Plan"></Dropdown>
                                                    </div>
                                                    <div className="p-field">
                                                        <label htmlFor="upfront-dept">Upfront Deposite (INR)</label>
                                                        <InputText id="upfront-dept" placeholder='Upfront Deposite (INR)' defaultValue={formAction ? editMachineData?.subscription?.package?.depositamt : ''} name='depositamt' onChange={changeUpdateBoard} type="number" min="0" autoComplete="off" />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </>
                                }


                                {
                                    (demoDialog || commercialDialog) &&
                                    <>
                                        <h5 className='p-mt-2'>BILLING DETAILS</h5>
                                        <div className="p-field">
                                            <label htmlFor="billing-pattern">Billing Pattern</label>
                                            <Dropdown id="billing-pattern" name='billing_pattern' value={billingPattern} onChange={changeUpdateBoard} options={billingPatternOptions} optionLabel="name" placeholder="Select Billing Pattern" disabled={true}></Dropdown>
                                        </div>

                                        {
                                            (billingPattern?.code == 'postconsumption') &&
                                            <div className="p-field">
                                                <label htmlFor="invoice-cycle">Invoice Cycle</label>
                                                <Dropdown id="invoice-cycle" name='invoiceCycle' value={invoiceCycle} onChange={changeUpdateBoard} options={invoiceCycleOptions} optionLabel="name" placeholder="Select Invoice Cycle"></Dropdown>
                                            </div>
                                        }
                                    </>
                                }

                                {
                                    (commercialDialog) &&
                                    <>
                                        <div className="p-field">
                                            <label htmlFor="extension-period">Extension Period</label>
                                            <InputText id="extension-period" name='extperiod' placeholder='Extension Period' onChange={changeUpdateBoard} type="number" min="0" defaultValue={formAction ? editMachineData?.subscription?.package?.extperiod : ''} autoComplete="off" />
                                        </div>
                                    </>
                                }

                            </form>
                    }

                </Dialog>

                {/* <EditCVES editmachineDialog={editmachineDialog} editMachineData={editMachineData} setEditmachineDialog={setEditmachineDialog} /> */}
                <AddFacility facilityDialog={facilityDialog} setFacilityDialog={setFacilityDialog} selectedAccount={selectedAccount} getMachines={getMachines} getFac={getFac} selectedLeads={selectedLeads} setSavedfacility={setSavedfacility} />

                <Dialog visible={verifyCustomerDialog} style={{ width: '550px' }} header="OTP Verification" modal footer={verifyCustDialogFooter} onHide={hideVeriyDialog}>

                    {
                        !isOtpClicked &&
                        <div className='p-fluid'>
                            <div className='p-grid'>
                                <div className='p-col-8'>
                                    <div className="p-field">
                                        {/* <label htmlFor="otp">Enter OTP</label> */}
                                        <InputText value={selectedMachineForM?.customerCno} placeholder="Enter Mobile Number" disabled />
                                    </div>
                                </div>
                                <div className='p-col-4'>
                                    <Button className='p-custom-btn p-mt-2' label="Send OTP" onClick={sendOtp} />
                                </div>
                            </div>
                        </div>
                    }

                    {
                        isOtpClicked &&

                        <div className="confirmation-content">
                            <center>
                                <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                                <b><span>An OTP has been sent on moblie number {selectedMachineForM?.customerCno}.</span></b> <br /><br />
                            </center>

                            <form onSubmit={verifySubmit} id="verify-cust-form" className="p-fluid p-m-4">
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <div className="p-field">
                                            <label htmlFor="otp">Enter OTP</label>
                                            <InputText id="otp" name='otp' placeholder="Enter OTP" maxLength={4} onChange={changeOtp} autoFocus required />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    }

                </Dialog>

                <Dialog visible={statusConfirmDialog} style={{ width: '550px' }} header="Confirm" modal footer={statusDialogFooter} onHide={() => { setStatusConfirmDialog(false) }}>
                    <div className="confirmation-content">
                        <center>
                            <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                            {(subStatus == 'Active' || subStatus == 'Demo') ? <b><span>Are you sure you want Inactive CVES? </span></b> : <b><span>Are you sure you want to Activate CVES? </span></b>}
                        </center>
                    </div>
                </Dialog>

                <Dialog visible={pantryDialog} style={{ width: '750px' }} header={`Pantry Boy Information (${selectedMachineForM?.id} / ${selectedMachineForM?.subid})`} modal className="p-fluid" footer={pantryBoyDialogFooter} onHide={hideDialog1} maximizable={true} blockScroll={true}>
                    <Toolbar className="p-mb-4 p-toolbar" left={pantryBoyLeftToolbarTemplate} right={pantryBoyRightToolbarTemplate}></Toolbar>
                    <div className="p-grid table-demo">
                        <DataTable value={pantryBoyDetails} paginator className="p-datatable-customers" rows={10} dataKey="id" rowHover selection={selectedCustomers} onSelectionChange={(e) => setSelectedCustomers(e.value)}
                            globalFilter={globalFilter1} emptyMessage="No Pantry Boy Details found." >
                            <Column header="Sr.No" body={srBodyTemplate}></Column>
                            <Column field="name" header="Admin" sortable body={bodyTemplate}></Column>
                            <Column field="keypad" header="Passcode" sortable body={bodyTemplate}></Column>
                            <Column field="blocked" header="Blocked / UnBlocked" sortable body={statusTemplate1}></Column>
                            <Column header="Action" body={actionPantryBodyTemplate}></Column>
                        </DataTable>
                    </div>
                </Dialog>

                <Dialog visible={addBoyDialog} style={{ width: '850px' }} header={`${editBoyData ? 'Edit' : 'Add'} New Pantry Boy (${selectedMachineForM?.id} / ${selectedMachineForM?.subid})`} modal footer={addBoyFooter} onHide={() => { setAddBoyDialog(false) }} maximizable={true} blockScroll={true}>
                    <form onSubmit={submitPantryBoy} id="add-pantry-boy" className="p-fluid">
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label>Full Name</label>
                                    <InputText type="text" id="info" name='name' placeholder='Full Name' defaultValue={editBoyData ? editBoyData.name : ''} onChange={onPantryValue} autoFocus required autoComplete='off' />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label>Email Id</label>
                                    <InputText id="info" type="email" name="email" placeholder='Email ID' defaultValue={editBoyData ? editBoyData.email : ''} onChange={onPantryValue} required autoComplete='off' />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="cno">Contact Number</label>
                                    <InputText id="cno" name='cno' type="text" maxLength='10' defaultValue={editBoyData ? editBoyData.cno : ''} onChange={onPantryValue} placeholder='Contact Number' required autoComplete='off' />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="strt-date">Start Date</label>
                                    <InputText id="strt-date" name='startDate' defaultValue={editBoyData ? editBoyData.startDate : ''} type="date" onChange={onPantryValue} placeholder='Start Date' required />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="end-date">End Date</label>
                                    <InputText id="end-date" name='endDate' type="date" defaultValue={editBoyData ? editBoyData.endDate : ''} onChange={onPantryValue} min={pantryBoyInfo?.startDate} placeholder='End Date' required />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="start-int">Start Interval</label>
                                    <InputText id="start-int" name='startInter' type="time" defaultValue={editBoyData ? editBoyData.startInter : ''} onChange={onPantryValue} placeholder='Start Interval' required />
                                </div>
                            </div>
                            <div className='p-col-6'>
                                <div className="p-field">
                                    <label htmlFor="end-int">End Interval</label>
                                    <InputText id="end-int" name='endInter' type="time" defaultValue={editBoyData ? editBoyData.endInter : ''} onChange={onPantryValue} placeholder='End Interval' required />
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="bev-set">Beverage Setting</label>
                                    <div className='p-grid'>
                                        <div className="p-col-12 p-md-4">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="default" checked={pantryBoyAuthInfo.default} onChange={handleAuth} />
                                                <label>Default</label>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="cup_wise" checked={pantryBoyAuthInfo.cup_wise} onChange={handleAuth} />
                                                <label>Cup Wise</label>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-4">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="quantity" checked={pantryBoyAuthInfo.quantity} onChange={handleAuth} />
                                                <label>Quantity</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-col-12">
                                <div className="p-field">
                                    <label htmlFor="bev-set">Authorization</label>
                                    <div className='p-grid'>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="refilling" checked={pantryAuthInfo.refilling} onChange={handleAuth1} />
                                                <label >Refilling</label>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="price" checked={pantryAuthInfo.price} onChange={handleAuth1} />
                                                <label>Pricing</label>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="cup" checked={pantryAuthInfo.cup} onChange={handleAuth1} />
                                                <label>Cup Setting</label>
                                            </div>
                                        </div>
                                        <div className="p-col-12 p-md-3">
                                            <div className="p-field-checkbox">
                                                <Checkbox name="cleaning" checked={pantryAuthInfo.cleaning} onChange={handleAuth1} />
                                                <label>Auto Cleaning</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Dialog>

                <Dialog visible={deleteCVESDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCVESDialogFooter} onHide={hideCVESDialog}>
                    <div className="confirmation-content">
                        <center>
                            <i className="pi pi-exclamation-triangle p-error" style={{ fontSize: '4rem' }} /><br /><br />
                            <b><span>Are you sure you want to delete CVES?</span></b> <br /><br />
                            <span>You wont be able to revert.</span>
                        </center>
                    </div>
                </Dialog>

                <Dialog visible={deletePantryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePantryDialogFooter} onHide={() => { setDeletePantryDialog(false) }}>
                    <div className="confirmation-content">
                        <center>
                            <i className="pi pi-exclamation-triangle p-error p-mr-3 " style={{ fontSize: '4rem' }} /><br />
                            <span className='p-mt-2'><b>Are you sure you want to delete the selected pantry boy?</b></span>
                        </center>
                    </div>
                </Dialog>

                <Dialog visible={switchDialog} style={{ width: '550px' }} header={`Switch CVES (${selectedMachineForM?.id} / ${selectedMachineForM?.subid})`} modal footer={deleteSwichDialogFooter} onHide={hideSwitchDialog} className="p-fluid">
                    <form onSubmit={saveSwitchMachine} id="switch-form">
                        <div className="p-field">
                            <label htmlFor="switch-by">Switch By</label>
                            <Dropdown id="switch-by" value={switchMachine} onChange={changeSwitchMachine} autoFocus options={fgMachines} optionLabel="id" placeholder="Select CVES" filter required></Dropdown>
                            <p className='p-mt-2 p-error'>{switchMachine?.connectionMode == "disConnected" && '*CVES is Offline'} </p>

                            {
                                (switchMachine != null && (switchMachine?.connectionMode != "disConnected" || selectedMachineForM?.app_cat == "Tapri Kettle")) &&

                                <>
                                    <div className="field-checkbox p-m-2">
                                        <Checkbox inputId="consumption" name="consumption" value="consumption" checked={consumptionCheck} onChange={(e) => { switchingChecks(e) }} />
                                        <label htmlFor="consumption"> CVES Consumption Data</label>
                                    </div>
                                    <div className="field-checkbox p-m-2">
                                        <Checkbox inputId="cards" name="cards" value="cards" checked={cardsCheck} onChange={(e) => { switchingChecks(e) }} />
                                        <label htmlFor="cards"> Cards on CVES</label>
                                    </div>
                                    <div className="field-checkbox p-m-2">
                                        <Checkbox inputId="counter" name="counter" value="counter" checked={counterCheck} onChange={(e) => { switchingChecks(e) }} />
                                        <label htmlFor="counter"> CVES Counter</label>
                                    </div>
                                    <div className="field-checkbox p-m-2">
                                        <Checkbox inputId="pending" name="pending" value="pending" checked={pendingCheck} onChange={(e) => { switchingChecks(e) }} />
                                        <label htmlFor="pending"> CVES Updated</label>
                                    </div>
                                </>
                            }
                        </div>
                    </form>
                </Dialog>

                <Dialog visible={agreementDialog} style={{ width: '850px' }} header={`${initialValue?.userType == 'customer' ? 'View' : 'Upload'}  Agreement (${selectedMachineForM?.id} / ${selectedMachineForM?.subid})`} modal footer={agreementDialogFooter} onHide={() => { setAgreementDialog(false) }} className='p-fluid' maximizable={true} blockScroll={true}>

                    <Toolbar className="p-mb-4 p-toolbar" left={agreementLeftToolbarTemplate} right={agreementRightToolbarTemplate}></Toolbar>
                    {
                        loading1 ?
                            <center><h5><i className="pi pi-spin pi-spinner" /></h5></center> :
                            <div className="p-grid crud-demo">
                                <div className="card">
                                    <DataTable value={agreementList} className="datatable-responsive" responsiveLayout="scroll" emptyMessage="No data found.">
                                        <Column field="code" header="Sr. No." body={srBodyTemplate} ></Column>
                                        <Column field="name" header="Agreement Id" body={agreeBodyTemplate}></Column>
                                        <Column field="Date" header="Date" body={dateBodyTemplate} ></Column>
                                        <Column field="quantity" header="Action" body={actionAgreeBodyTemplate}></Column>
                                    </DataTable>
                                </div>
                            </div>
                    }
                </Dialog>

                <Dialog visible={uploaderDialog} style={{ width: '550px' }} header={`Upload Agreement (${selectedMachineForM?.id} / ${selectedMachineForM?.subid})`} modal footer={uploadAgreeDialogFooter} onHide={() => { setUploaderDialog(false) }} maximizable={true} blockScroll={true}>
                    <form onSubmit={submitAgreement} encType="multipart/form-data" id="add-agreement" className="p-fluid">

                        <InputText type="hidden" name='id' value={selectedMachineForM?.id} required />
                        <InputText type="hidden" name='subid' value={selectedMachineForM?.subid} required />
                        <InputText type='hidden' name='Uploaded_By' value={useid?.userid} />

                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label>Agreement Id</label>
                                    <InputText type="text" id="info" name='agreementId' placeholder='Agreement Id' autoFocus required autoComplete='off' />
                                </div>
                            </div>
                            <div className='p-col-12'>
                                <div className="p-field">
                                    <label>Upload Agreement</label>
                                    <InputText type="file" id="agreement_file" name='File' accept="application/pdf,application/vnd.ms-excel" required />
                                </div>
                            </div>

                            <InputText type="hidden" name="agreementStatus" value={onStatusChange} required />
                            <div className="p-col-12 p-md-4">
                                <div className="p-field-checkbox">
                                    <Checkbox inputId="checkOption1" checked={onStatusChange} onChange={(e) => { setOnStatusChange(e.target.checked) }} required />
                                    <label htmlFor="checkOption1">Signed</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </Dialog>

                <Dialog visible={settingDialog} header={`Actions :  ${selectedMachineForM?.id}`} modal onHide={hideSettingDialog} footer={settingDialogFooter} maximizable={true} blockScroll={true}>
                    <div className="button-demo" style={{ textAlign: 'center' }}>
                        <div className="card p-m-0">

                            {
                                (deleteFlag) &&
                                <Button icon="pi pi-trash" title='Delete' className="p-button-rounded p-button-danger p-m-1" aria-label="Delete" onClick={() => confirmDeleteCVES(selectedMachineForM)} />
                            }
                            {(rolePermissions?.machinePantry && selectedMachineForM?.subscription?.package?.site != "demo") && <Button icon="pi pi-users" title='Pantry Boy Information' className="p-button-rounded p-button-help p-m-1" aria-label="Pantry Boy" onClick={() => { openNewPantry(selectedMachineForM) }} />}

                            {
                                rolePermissions?.onboardSwitchBtn && <Button icon="pi pi-sliders-h" title='Switch' className="p-button-rounded p-button-warning p-m-1" aria-label="Switch" onClick={() => { openSwitchDialog(selectedMachineForM) }} />
                            }
                        </div>
                    </div>
                </Dialog>

                <AddLead leadDialog={leadDialog} setLeadDialog={setLeadDialog} getLeads={getLeads} />

                <Dialog visible={kycDialog} style={{ width: '850px' }} header={`KYC Details (${selectedMachineForM?.id})`} modal onHide={() => { setKycDialog(false) }} footer={kycDialogFooter} maximizable={true} blockScroll={true}>
                    <div className='card p-m-0'>
                        {
                            loading1 ?
                                <center><h5><i className="pi pi-spin pi-spinner" /></h5></center>
                                :
                                <>
                                    {
                                        images ?
                                            <Galleria value={images} numVisible={5} circular style={{ width: '100%' }}
                                                showItemNavigators showItemNavigatorsOnHover showIndicators
                                                showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
                                            :
                                            <center><h5 className='p-error p-m-0'>Not Found</h5></center>
                                    }
                                </>
                        }

                    </div>
                </Dialog>

            </div>
        </div>
    )

}

