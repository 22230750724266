import axios from 'axios';
import { getErpService, postErpService, putErpService, postIotService, getIotService, postFlutterService, getFlutterService, getErpMediaService, getZohoService, postFlutterServiceUpdate } from './ServiceTunnel';

const saved = localStorage.getItem("user");
const initialValue = JSON.parse(saved);

const FormAppOptions = {
    headers: {
        "Authorization": initialValue?.app_data?.token,
        "Content-Type": "multipart/form-data; boundary=''",
        'Accept': 'application/json',
        // 'Accept': '*/*',        
    }
};

const pdfOptions = {
    headers: {
        "responseType": 'blob',
        "x-access-token": initialValue?.idToken,
        "Content-type": "application/json; charset=UTF-8"
    }
}



export default class ApiServices {

    ////////////////////////////////////LOGIN SERVICES//////////////////////////////////

    userAuthentication = (data) => { return postErpService('/authenticate', data) }
    userVerification = (data) => { return postErpService('/verifyOtpForLogin', data) }
    getUserFilter = () => { return getErpService('/filters/getUsersFilter') }
    AuthenticateERPUser = (data) => { return postErpService('/authenticate', data) }
    authenticateAppUser = (data1) => { return postFlutterService('/api/auth/machine/login', { ...data1, secret_key: process.env.REACT_APP_FLUTTER_SECRETE_KEY }) }
    getAppOTP = (data) => { return postFlutterService('/api/auth/getUserOtp', data) }


    getOtp = () => {
        const options = {
            headers: {
                "x-access-token": initialValue?.idToken || 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWJqZWN0IjoiKzkxOTgzNDQxMDc1NiIsImlhdCI6MTY2NTk3ODc1MiwiZXhwIjoxNzUyMzc4NzUyfQ.HNtrPfSzdh_QdB5igaNT8Km8T9dYtTcpK3g9uExPpO0',
                "Content-type": "application/json; charset=UTF-8"
            }
        };

        return getErpService('/admin/getOTP', options)
    }

    userInfo = (data, tokenData) => {
        const options = { headers: { "x-access-token": tokenData || initialValue.idToken, "Content-type": "application/json; charset=UTF-8" } };
        return postErpService('/userInfo', { "userid": data }, options)
    }

    ////////////////////////////////////INVENTORY SERVICES//////////////////////////////////


    getInventoryData = (data) => { return postErpService('/inventory/getInventoryStats', data) }
    getMaterialByCustomer = (data) => { return getErpService(`/admin/materials?customer=${data.customerid}`) }
    getMaterialByCustomer1 = (data) => { return getErpService(`/admin/materials?customer=${data.customerid}&subid=${data.subid}`) }
    getAssignedBatch = (data) => { return postErpService('/admin/getAssignedBatch', { ...data, userType: initialValue?.userType }) }
    getBulkAssignBatch = (data) => { return postErpService('/ecom/getBulkAssignBatch', data) }
    getInventoryAssignedBatch = (data) => { return postErpService('/inventory/getAssignedBatch', data) }
    getMaterialOrders = (data) => { return postErpService('/ecom/getMaterialOrders', data) }
    removeMaterialOrder = (data) => { return postErpService('/ecom/removeMaterialOrder', { ...data, "userType": initialValue?.userType, "userid": initialValue?.userid }) }
    getbulkOrders = (data) => { return postErpService('/ecom/getbulkOrders', data) }
    orderFetch = (data) => { return postErpService('/ecom/orderFetch', data) }
    getBatchByMaterialSKU = (data) => { return postErpService('/filters/getBatchByMaterialSKU', data) }
    getWareHouseBarcode = (data) => { return postErpService('/ecom/wareHouseBarcode', data) }
    updateBulkOrder = (data) => { return postErpService('/ecom/updateBulkOrder', data) }
    addAssignBatch = (data) => { return postErpService('/admin/addAssignBatch', { ...data, "assignby": initialValue.userid, "cno": initialValue.cno }) }
    addBulkAssignBatch = (data) => { return postErpService('/ecom/addBulkAssignBatch', { ...data, "assignby": { "userid": initialValue?.userid, "name": initialValue?.name } }) }
    getSearchedOrder = (data) => { return getErpService(`/ecom/searchOrder?count=100&chars=${data}`) }
    returnMaterial = (data) => { return postErpService('/admin/returnMaterial', data) }
    updateAssignedBatch = (data) => { return postErpService('/admin/updateAssignedBatch', data) }
    deleteAssignedBatch = (data) => { return postErpService('/admin/deleteAssignedBatch', data) }
    sendOtp = (data) => { return postErpService('/user/sendOtp', data) }
    verifyOtp = (data) => { return postErpService('/user/verifyOtp', data) }
    searchAssignedBatch = (data) => { return getErpService(`/filters/searchAssignedBatch?count=50&index=0&chars=${data}`) }
    getAllPackage = () => { return getErpService('/admin/getPackage') }
    addPackage = (data) => { return postErpService('/admin/addPackage', data) }
    updatePackage = (data) => { return postErpService('/admin/updatePackage', data) }
    deletePackage = (data) => { return postErpService('/admin/deletePackage', data) }
    getGroups = () => { return getErpService('/inventory/getgroup') }
    getItems = () => { return getErpService('/inventory/getitem') }
    getcontacts = () => { return getErpService('/inventory/getcontact') }
    additem = (data) => { return postErpService('/inventory/additem', data) }
    updateitem = (data) => { return postErpService('/inventory/updateitem', data) }
    deleteitem = (data) => { return postErpService('/inventory/deleteitem', data) }
    acceptOrder = (data) => { return putErpService(`/ecom/orderAccept/${data?.orderId}`, {}) }
    viewOrder = (data) => { return putErpService(`/ecom/orderViewed/${data}`, {}) }
    orderDetails = (data) => { return postErpService('/ecom/orderInfo', data) }
    deletebulkOrders = (data) => { return postErpService('/ecom/remove/bulk/Order', data) }


    ////////////////////////////////////POST INVOICE SERVICES//////////////////////////////////

    getPostInvoices = () => { return postErpService('/machine/getSubscriptions', { "d_id": ["self"] }) }
    getUserReduced = () => { return getErpService('/user/usersReduced') }
    getInoviesById = (data) => { return postErpService('/orderCtvm/getInoviceById', data) }
    removeInoviceOrder = (data) => { return postErpService('/orderCtvm/removeInoviceOrder', { ...data, "userType": initialValue?.userType, "userid": initialValue?.userid }) }
    getCustomerAgreement = (data) => { return postErpService('/erp/agreement/zoho/getList', data) }
    getZohoToken = (data) => { return postErpService('/erp/zoho/zohoAuthToken', data, pdfOptions) }
    getZohoAgreements = (url, token) => { return getZohoService(url, token) }
    getAllBillingStats = () => { return postErpService('/erp/payment/get') }

    ////////////////////////////////////MIS SERVICES//////////////////////////////////

    findMisUsageStats = (data) => { return postIotService('/analytics/misUsageStats', data) }
    getMisPerformance = (data) => { return postIotService('/analytics/getmisPerformance', data) }
    getMisInventory = (data) => { return postErpService('/inventory/getMisInventoryStats', data) }
    getDistributorsStats = () => { return postErpService('/user/getDistributorStats', { "userType": initialValue.userType }) }
    getBulkInventory = () => { return postErpService('/inventory/getBulkInventory', { "userType": initialValue.userType }) }
    getDistributorMachinesStats = () => { return postIotService('/cmachine/getDistributorMachinesStats', { "userType": initialValue.userType }) }
    getFacilityByUser = () => { return postErpService('/filters/getFacilityByUser', { "userType": initialValue.userType }) }
    getSubscription = () => { return postErpService('/filters/getSubscription', { "userType": initialValue.userType, "userid": initialValue.userid }) }
    accountWisePerformance = (data) => { return postIotService('/cmachine/accountWisePerformance', { ...data, "userType": initialValue.userType, "userid": initialValue.userid }) }
    accountWiseConsumption = (data) => { return postIotService('/cmachine/accountWiseConsumption', data) }
    canisterWiseConsumption = (data) => { return postIotService('/cmachine/canisterWiseUsage', data) }
    dayWiseConsumption = (data) => { return postIotService('/cmachine/dayWisePeformance', data) }
    monthWisePeformance = (data) => { return postIotService('/cmachine/monthWisePeformance', data) }
    getFeedbackData = (data) => { return postIotService('/iot/cves/feedback', data) }
    getAllFeedbackData = (data) => { return postIotService('/iot/cves/feedback/find', data) }
    addFeedbackData = (data) => { return postIotService('/iot/cves/feedback', data) }
    updateFeedbackData = (data) => { return postIotService('/iot/cves/feedback/update', data) }
    addFeedbackCategory = (data) => { return postIotService('/iot/create/feedbackcategory', data) }
    getFeedbackCategory = (data) => { return postIotService('/iot/get/feedbackcategory', data) }

    getInstallationReport = (data) => { return postIotService('/cmachine/installation/report', data) }
    getInstalledReport = (data) => { return postIotService('/cmachine/get/installation/report', data) }

    getPullOutChart = (data) => { return postIotService('/cmachine/cves/pullout/report', data) }
    getPullOutReport = (data) => { return postIotService('/cmachine/get/cves/pullout/report', data) }

    ////////////////////////////////////ORDER SERVICES//////////////////////////////////

    getCTVMOrders = () => { return getErpService('/orderCtvm/getCTVMOrders') }
    getPlacedCTVMOrders = () => { return getErpService('/orderCtvm/getPlacedCTVMOrders') }
    getFinishedgoodsCTVM = () => { return getIotService('/cmachine/finishedgoodsCTVM') }
    setOrderViewed = (data) => { return postErpService('/orderCtvm/orderViewed', data) }
    getEncrytedNumber = (data) => { return postIotService('/cmachine/getEncrytedNumber', data) }
    removeMachineOrder = (data) => { return postErpService('/orderCtvm/removeMachineOrder', { ...data, "userType": initialValue?.userType, "userid": initialValue?.userid }) }
    getAllBeverageProducts = () => { return getFlutterService('/api/ownership/user/combos') }
    getBeverageProducts = (data) => { return postFlutterService('/api/ownership/user/combos', { ...data, "userType": initialValue?.userType }) }
    cartRefillData = (data) => { return postFlutterService('/api/refill/cart', data) }
    getCouponCodes = (data) => { return postFlutterService('/api/refill/coupons', data) }
    applyCouponCode = (data) => { return postFlutterService('/api/refill/cart/apply/coupon', data) }
    createRefillOrder = (data) => { return postFlutterService('/api/refill/order/create', data) }
    transactionSucceed = (data) => { return postFlutterService('/api/transaction/success', data) }
    getMyOrders = (data) => { return postFlutterService('/api/refill/orders', data) }
    getCtvmMyOrders = (data) => { return postFlutterService('/api/ownership/plans/order/list', data) }
    getOwnerShipPlans = (data) => { return postFlutterService('/api/ownership/plans', data) }
    sendOtpToCustomer = (data) => { return postFlutterService('/api/auth/request/otp', data) }
    verifyOtpToCustomer = (data) => { return postFlutterService('/api/auth/verify/otp', data) }
    saveCustomerData = (data) => { return postFlutterService('/api/orderctvm/signup', data) }
    addTickets = (data) => { return postFlutterService('/api/support/create', data, FormAppOptions) }
    validateUser = (data) => { return postFlutterService('/api/auth/request/validateUser', data) }
    getCreditLimit = (data) => { return postFlutterService('/api/auth/spentCreditLimit', data) }
    getRanges = () => { return getFlutterService('/api/ranges') }
    saveCustAddress = (data, appOptions) => { return postFlutterService('/api/address/create', data, appOptions) }
    getCustAddress = (appOptions) => { return getFlutterService('/api/address', appOptions) }
    deleteCustAddr = (data, appOptions) => { return postFlutterService('/api/address/delete', data, appOptions) }
    saveCtvmOrderData = (data) => { return postFlutterService('/api/ownership/plans/order', data) }
    saveKettleStore = (data) => { return postFlutterService('/api/kettle/store', data) }
    getMachineOrderProducts = (data) => { return postFlutterService('/api/ownership/combos', { ...data, "userType": initialValue?.userType }) }
    getKettleRefill = (data) => { return postFlutterService('/api/kettle/refills', data) }
    getKettleCartSummary = (data) => { return postFlutterService('/api/kettle/cart', data) }
    saveKettleOrder = (data) => { return postFlutterService('/api/kettle/order', data) }
    getSpecialiteas = (data) => { return postFlutterService('/api/ownership/specility/list', data) }
    refillOrderTrack = (data) => { return postFlutterService('/api/refill/orders/tracker', data) }
    refillOrderStats = (data) => { return postFlutterService('/api/refill/orders/stats', data) }
    cvesOrderStats = (data) => { return postFlutterService('/api/ownership/orders/stats', data) }


    ////////////////////////////////////OVERVIEW SERVICES//////////////////////////////////

    getHeaderImages = () => { return axios.get('assets/demo/data/header-img.json').then((res) => res.data.data) }
    unlockCanister = (data) => { return postIotService('/cmachine/unlockCanister', { ...data, "userid": initialValue.userid, "userName": initialValue.name, "lastName": initialValue?.lastName }) }
    // unlockCanister = (data) =>{ return postIotService('/cmachine/unlockCanister',{...data,"userid":initialValue.userid,"userName":initialValue.name, "lastName" : initialValue?.lastName}) }
    validateBarcode = (data) => { return postIotService('/cmachine/validateBarcode', data) }
    setCalibrationData = (data1) => { return postIotService('/cmachine/setCalibrationData', { ...data1, "userid": initialValue.userid }) }
    setCanisterLevel = (data) => { return postIotService('/cmachine/setCanLevel', { ...data, "userid": initialValue.userid, "userName": initialValue.name, "lastName": initialValue?.lastName }) }
    removeCanisterMaintenance = (data) => { return postIotService('/cmachine/removeMaintaince', data) }
    signAgreement = (data) => { return postErpService('/erp/agreement/signed', data) }
    updateConsumptionClicks = (data) => { return postIotService('/cmachine/ModifyConsumptionClicks', { ...data, "userid": initialValue.userid }) }

    ////////////////////////////////////PERFORMANCE SERVICES//////////////////////////////////

    getPerformance = (data) => { return postIotService('/cmachine/getPerformance', data) }
    getPerformanceByAccMan = (data) => { return postIotService('/cmachine/getPerformanceByAccMan', data) }

    ////////////////////////////////////TASK SERVICES//////////////////////////////////

    getTasks = () => { return postErpService('/crm/getalltask', { d_id: ["self"], userType: initialValue.userType }) }
    assignTask = (data) => { return postErpService('/crm/assigntask', data) }
    updateTask = (data) => { return postErpService('/crm/updatetask', data) }
    removetask = (data) => { return postErpService('/crm/removetask', data) }

    ////////////////////////////////////TICKET SERVICES//////////////////////////////////

    ticketCatergory = (data) => { return postErpService('/tracking/ticketCatergory', data) }
    getAppCategories = (data) => { return postFlutterService('/api/support/category/list', data) }
    getTickets = (data) => { return postErpService('/tracking/getTickets', data) }
    ticketPerformance = (data) => { return postErpService('/tracking/ticketPerformance', data) }
    getTicketStats = (data) => { return postErpService('/tracking/getTicketStats', data) }

    ////////////////////////////////////TRANSACTION SERVICES//////////////////////////////////

    getTransactions = (data) => { return postIotService('/transaction/getTransactions', { ...data, "userType": initialValue.userType }) }
    getTransactrionStats = (data) => { return postIotService('/transaction/getTransactionStats', { ...data, "userType": initialValue.userType }) }
    getTransactionStatsByFilter = (data) => { return postIotService('/transaction/getTransactionStatsByFilter', { ...data, "userType": initialValue.userType }) }
    transactionsByUser = (data) => { return postIotService('/transaction/transactionsByUser', data) }
    getPaytmAcc = () => { return postIotService('/transaction/getPaytmAc', { "userType": initialValue?.userType }) }
    getPaytmAccount = (data) => { return postIotService('/transaction/getPaytmAc', data) }
    refundManual = (data) => { return postIotService('/transaction/refundManual', { ...data, "userType": initialValue.userType }) }
    getFonepayTransactionList = (data) => { return postIotService('/iot/fonepay/transactions/details', { ...data, "userType": initialValue.userType, "userid": initialValue.userid }) }
    getFonepayMerchantList = (data) => { return postIotService('/iot/get/fonePayMerchant', data) }
    getFoneMerchants = (data) => { return postIotService('/iot/get/fonePayMerchant', data) }
    addFoneMerchant = (data) => { return postIotService('/iot/create/fonePayMerchant', data) }
    updateFoneMerchant = (data) => { return postIotService('/iot/update/fonePayMerchant', data) }
    deleteFoneMerchant = (data) => { return postIotService('/iot/delete/fonePayMerchant', data) }

    getPhoneMerchants = (data) => { return postIotService('/iot/get/phonePay/merchant', data) }
    addPhoneMerchant = (data) => { return postIotService('/iot/create/phonePay/merchant', data) }
    updatePhoneMerchant = (data) => { return postIotService('/iot/update/phonePay/merchant', data) }
    deletePhoneMerchant = (data) => { return postIotService('/iot/delete/phonePay/merchant', data) }

    getPhonePayTransactions = (data) => { return postIotService('/iot/get/phonepay/transactions', data) }



    ////////////////////////////////////USER SERVICES//////////////////////////////////


    getFacility = (data) => { return postErpService('/user/getFacility', data) }

    updateUser = (data) => { return postErpService('/user/updateUser', data) }
    getCountry = () => { return getIotService('/cmachine/country') }
    getState = (data) => { return postIotService('/cmachine/state', data) }
    getCity = (data) => { return postIotService('/cmachine/city', data) }
    deleteUser = (data) => { return postErpService('/user/removeUser', data) }
    assignDistributor = (data) => { return postErpService('/user/assignDistributor', data) }
    verifyUser = (data) => { return postErpService('/user/verifyUser', data) }
    addEmployee = (data) => { return postIotService('/cmachine/addEmployee', data) }
    removeEmployee = (data) => { return postIotService('/cmachine/removeEmployee', data) }
    addVirtualCards = (data) => { return postIotService('/virtual/addVirtualCards', data) }
    deleteVirtualCards = (data) => { return postIotService('/virtual/deleteVirtualCards', data) }
    getuserById = (data) => { return postErpService('/user/getUserById', data) }
    deleteLead = (data) => { return postErpService('/crm/removeLead', data) }
    updateLead = (data) => { return postErpService('/crm/updateLead', data) }
    updateProfileInfo = (data) => { return postErpService('/user/updateProfileInfo', data) }
    updateVirtualCards = (data) => { return postIotService('/virtual/updateVirtualCards', data) }
    getCustomerAssignedBatch = (data) => { return postErpService('/admin/getCustomerAssignedBatch', data) }
    getPincodeDetails = async (data) => { return await axios.get(`https://api.postalpincode.in/pincode/${data}`) }
    getAddressFromlatLong = async (data) => { return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${data.currentLatitude},${data.currentLongitude}&key=AIzaSyDg2-wgJkWZC_soGA1GK91r4vRBfwgFyTQ`) }
    getCityLatLong() { return axios.get(`assets/rowData/cityLatLong.json`).then(res => res.data.data) }
    getTechnomanTrackings = (data) => { return postErpService('/tracking/getTrackings', data) }
    getUsers = () => { return postErpService('/user/getUsers', { d_id: ["self"], userType: initialValue.userType }) }
    getUsersByCTVM = () => { return postIotService('/cmachine/usersByCTVM', { customer: initialValue.userid, d_id: initialValue.d_id, userType: initialValue.userType, userid: initialValue.userid }) }
    updateEmployee = (data) => { return postIotService('/cmachine/updateEmployee', { ...data, editbydetails: { customer: initialValue.userid, userType: initialValue.userType } }) }
    updateProfilePicture = (data) => { return postErpService('/user/updateProfilePicture', { ...data, userid: initialValue.userid }) }
    getVirtualCards = (data1) => { return postIotService('/virtual/getVirtualCards', { ...data1, userType: initialValue.userType }) }
    updateBulkEmployee = (data) => { return postIotService('/cmachine/rfidBulkRecharge', data) }
    addRfidTransactions = (data) => { return postIotService('/virtual/rfidRechargeTxn', data) }
    updateRfidTransactions = (data) => { return postIotService('/virtual/updateRfidTxn', data) }
    getRfidTransactions = (data) => { return postIotService('/virtual/getRfidTxn', data) }
    getUsersForBilling = () => { return getIotService('/cmachine/activeUsers?subscriptionType=permanent') }
    getMachineByFacid = (data) => { return postIotService('/cmachine/getMachinesByFacility', data) }
    getActiveZohoUser = (data) => { return postErpService('/user/activeZohoCustomer', data) }
    editUserCno = (data) => { return postErpService('/user/update/customer/cno', data) }
    cves_userInfo = (data) => { return postErpService('/erp/get/user/info', data) }
    reuseBarcode = (data) => { return postErpService('/inventory/reuse/barcode', data) }

    createUser = (data) => {
        const data1 = data.addedBy ? data : { ...data, addedBy: { owner: initialValue.userid } };
        return postErpService('/user/createUser', data1)
    }

    getLeads = (data) => {
        const data1 = { userType: initialValue.userType, userid: initialValue.userid }
        return postErpService('/crm/getCustomerData', { ...data, ...data1 })
    }

    ////////////////////////////////////FOG SERVICES//////////////////////////////////

    getFogTasks = (data) => { return postErpService('/erp/technoman/gettask', data) }

    ////////////////////////////////////NOTIFICATIONS SERVICES//////////////////////////////////

    getNotifications = (data) => { return postFlutterService('/api/iot/customer/settings', data) }
    updateNotifications = (data) => { return postFlutterService('/api/iot/customer/settings/update', data) }
    getNotCategories = (data) => { return postFlutterService('/api/notification/catergory/list', data) }
    addNotCategories = (data) => { return postFlutterService('/api/notification/catergory/create', data) }
    addNotSubCategories = (data) => { return postFlutterService('/api/notification/subcategory/create', data) }
    getSubCategories = (data) => { return postFlutterService('/api/notification/subcategory/list', data) }


    ////////////////////////////////////MACHINE SERVICES//////////////////////////////////

    
    
    findMachines = (data) => {
        const data1 = { "customer": initialValue?.userid, "d_id": initialValue?.d_id, "userType": initialValue?.userType, "userid": initialValue?.userid }
        const payload = data ? { ...data, ...data1 } : data1
        return postIotService('/cmachine/findMachines', payload)
    }

  



    checkEmployeeTab = (loggedData) => { return postIotService('/cmachine/findMachines', { "customer": loggedData.userid, "d_id": loggedData.d_id, "userType": loggedData.userType, "userid": loggedData.userid }) }

    employeeTabAccess = (loggedData) => { return postIotService('/cmachine/tab/access', { "userType": loggedData.userType, "userid": loggedData.userid }) }

    getMchArray = () => { return postIotService('/cmachine/getMchArray', { "customer": initialValue?.userid, "d_id": initialValue?.d_id, "userType": initialValue?.userType, "userid": initialValue?.userid }) }
    getUserStats = (data1) => { return postIotService('/cmachine/usageStats1', { ...data1, userId: initialValue.userid }) }
    getUserStatsForMeriTapri = (data1) => { return postIotService('/cmachine/usageStats1', { ...data1 }) }
    getBulkMachines = () => { return postIotService('/cmachine/getBulkMachines', { "userType": initialValue.userType, "userid": initialValue.userid }) }
    addBulkMachine = (data1) => { return postIotService('/cmachine/addBulkMachine', { ...data1, addedBy: { "userid": initialValue.userid, "username": initialValue.name } }) }
    updateBulkMachine = (data1) => { return postIotService('/cmachine/editBulkMachine', { ...data1, updatedBy: { "userid": initialValue.userid, "username": initialValue.name } }) }
    updateMachineData = (data1) => { return postIotService('/cmachine/renameMachine', { ...data1, editedBy: { "userid": initialValue.userid, "username": initialValue.name } }) }
    getMaterialType = () => { return getErpService('/admin/materialTypes') }

    getMaterialColor = (data = {}) => { return postErpService('/admin/material/type/color', data) }

    getMachineConsumption = (data) => { return postIotService('/cmachine/machineConsumption', data) }
    getUsageStatByFilter = (data) => { return postIotService('/cmachine/usageStatsByFilter', data) }
    getMachineLocations = (data = {}) => { return postIotService('/cmachine/getMachineLocations', data) }
    getMachineCoordinates = (data = {}) => { return postIotService('/cmachine/getMachineLocation1', data) }

    getCvesLocations = (data = {}) => { return postIotService('/iot/location/cves/', data) }


    getActiveCustomerCount = (data = {}) => { return postErpService('/user/activeCustomerCount', data) }
    


    getUserReduced = () => { return getErpService('/user/usersReduced') }
    getCalibrationData = (data) => { return postIotService('/user/usersReduced', data) }
    setCalibrationData = (data) => { return postIotService('/cmachine/setCalibrationData', data) }
    getSubscriptionsByCTVM = (data) => { return postErpService('/machine/getSubscriptionsByCTVM', data) }
    getSubscriptionHistory = (data) => { return postIotService('/iot/get/subscription/history/', data) }
    setBackupData = (data) => { return postIotService('/cmachine/getBackupData', data) }
    setRestoreData = (data) => { return postIotService('/cmachine/setRestoreData', data) }
    getLatestVersions = () => { return getIotService('/cmachine/latestVersions?updateFor=App') }
    getVersions = () => { return getIotService('/cmachine/latestVersions') }
    setAppUpdate = (data) => { return postIotService('/cmachine/setAppUpdate', data) }
    updateSimData = (data) => { return postIotService('/cmachine/updateSimCard', data) }
    triggerAppUpdate = (data) => { return postIotService('/cmachine/triggerFOTA', data) }
    exitTheApp = (data) => { return postIotService('/cmachine/exitApp', data) }
    unlockDoor = (data) => { return postIotService('/cmachine/unlockDoor', data) }
    setMachineTemperature = (data) => { return postIotService('/cmachine/setMachineTemp', data) }
    getMachineTemperature = (data) => { return postIotService('/cmachine/getMachineTemp', data) }
    setWaterInlet = (data) => { return postIotService('/cmachine/setWtrInlet', data) }
    getWaterInlet = (data) => { return postIotService('/cmachine/getWtrInlet', data) }
    reloadTheApp = (data) => { return postIotService('/cmachine/reloadApp', data) }
    unregisterMachine = (data) => { return postIotService('/cmachine/unregisterMachine', { "id": data, "customer": initialValue.userid }) }
    removeCapsuleMachine = (data) => { return postIotService('/cmachine/removeMachine', { ...data, "customer": initialValue.userid }) }
    updateMachineStage = (data) => { return postIotService('/cmachine/updateMachineStage', data) }
    removeRestoreData = (data) => { return postIotService('/cmachine/removeRestoreData', { customer: initialValue.userid, deletedBy: "", id: data.id, _id: data._id }) }
    getHistoricalCtvmStats = (data) => { return postIotService('/analytics/getHistoricalCtvmStats', data) }
    updateSubscriptionStatus = (data1) => { return postIotService('/cmachine/subscriptionStatus', { ...data1, editbydetails: { customer: initialValue.userid, name: initialValue.name, userType: initialValue.userType } }) }
    updateSubscriptionStatusTest = (data1) => { return postIotService('/cmachine/updateSubscriptionStatusTest', { ...data1, editbydetails: { customer: initialValue.userid, name: initialValue.name, userType: initialValue.userType } }) }
    getUsersByType = (data) => { return postErpService('/user/getUsersByType', data) }
    getPaytmUser = () => { return postIotService('/cmachine/paytmUsers', { "userType": initialValue?.userType, "userid": initialValue?.userid }) }
    deleteSite = (data) => { return postIotService('/cmachine/deleteSite', { customer: initialValue.userid, deletedBy: initialValue.name, id: data.id, removeDemoUser: true, type: "machines" }) }
    deleteSubscription = (data) => { return postIotService('/bulkMachines/deleteSubscription', { customer: initialValue.userid, deletedBy: initialValue.name, id: data.id, removeDemoUser: true, type: "machines" }) }
    addAdmin = (data) => { return postIotService('/cmachine/addAdmin', data) }
    getAgreements = (data) => { return postErpService('/getAgreementList', data) }
    getAllAgreements = (data) => { return postErpService('/listagreements', data) }
    getAgreeComments = (data) => { return postErpService('/erp/agreement/comment/list', data) }
    addAgreeComments = (data) => { return postErpService('/erp/agreement/comment/create', data) }
    deleteAgreeComments = (data) => { return postErpService('/erp/agreement/comment/remove', data) }
    getCVESStats = (data) => { return postIotService('/cmachine/getCVESStats', data) }
    agreementsList = (data) => { return postErpService('/AgreementList', data) }
    getCustomerData = () => { return postErpService('/crm/getCustomerData', { "d_id": ["self"], "userType": initialValue.userType }) }
    getCustomerData1 = () => { return postErpService('/crm/getCustomerData', { "params": { name: 1, userid: 1, location: 1 }, "userType": initialValue.userType, "userid": initialValue.userid }) }
    addFacility = (data) => { return postErpService('/user/addFacility', data) }
    updateFacility = (data) => { return postErpService('/user/updateFacility', data) }
    deleteFacilityData = (data) => { return postErpService('/user/deleteFacilty', data) }
    getFacility = (data) => { return postErpService('/user/getFacility', data) }
    getallFacility = (data) => { return postErpService('/user/getallFacility', data) }
    getActiveFacility = (data) => { return postErpService('/user/active/customer/facility', data) }
    getModelPricing = (data) => { return postErpService('/machine/getModelPricing', data) }
    addNewSite = (data) => { return postIotService('/cmachine/newSite', data) }
    renameSite = (data) => { return postIotService('/cmachine/renameSite', data) }
    renameSubscription = (data) => { return postIotService('/bulkMachines/renameSubscription', data) }
    getCountry = () => { return getIotService('/cmachine/country') }
    getState = (data) => { return postIotService('/cmachine/state', data) }
    getCity = (data) => { return postIotService('/cmachine/city', data) }
    addLead = (data) => { return postErpService('/crm/addLead', data) }
    switchMachineCheck = (data) => { return postIotService('/cmachine/switchMachineCheck', data) }
    switchMachine = (data) => { return postIotService('/cmachine/switchMachine', data) }
    deletePantryBoy = (data) => { return postIotService('/cmachine/deleteAdmin', data) }
    getPaytmAcc = (data) => { return postIotService('/transaction/getPaytmAc', data) }
    getLogFiles = (data) => { return postIotService('/cmachine/getLogFiles', data) }
    getEmployee = (data) => { return postIotService('/cmachine/getEmployee', data) }
    getEmployeeConsumption = (data) => { return postIotService('/cmachine/getEmployeeConsumption', data) }
    employeeConsumption = (data) => { return postIotService('/cmachine/employeeConsumption', data) }
    getQrdispense = (data) => { return postIotService('/cmachine/getQrdispense', data) }
    updateCapsuleMachineStage = (data) => { return postIotService('/capsuleMachine/updateCapsuleMachineStage', data) }
    capsuleMachineToIOT = () => { return postErpService('/capsulemachines/capsuleMachineToIOT', { "d_id": ["self"], "userType": initialValue.userType, "userid": initialValue.userid }) }
    getMachines = () => { return postErpService('/inventory/machines', { "d_id": ["self"], "userType": initialValue.userType, "userid": initialValue.userid }) }
    removeLogFile = (data) => { return postIotService('/cmachine/deleteCtvmFile', data) }
    cancelCtvmFile = (data) => { return postIotService('/cmachine/cancelCtvmFile', data) }
    fetchLogFile = (data) => { return postIotService('/cmachine/fetchLogFile', data) }
    empConsumptionCount = (data) => { return postIotService('/cmachine/empConsumptionCount', data) }
    getTransactionStats = (data) => { return postIotService('/transaction/getTransactionStats', data) }
    allMachineConsumption = (data) => { return postIotService('/cmachine/ctvmConsumption', data) }
    // getIoMachines = () => { return postIotService('/bulkMachines/findCTVM', { "customer": initialValue.userid, "d_id": initialValue.d_id, "userType": initialValue.userType, "userid": initialValue.userid }) }
    getIoMachines = () => { return postIotService('/bulkMachines/get/cves/', { "customer": initialValue.userid, "d_id": initialValue.d_id, "userType": initialValue.userType, "userid": initialValue.userid }) }

    getFintechCves = () => { return postIotService('/iot/fintech/cves/list/', { "customer": initialValue.userid, "d_id": initialValue.d_id, "userType": initialValue.userType, "userid": initialValue.userid }) }

    getIoCves = () => { return postIotService('/bulkMachines/get/cves/', { "customer": initialValue.userid, "d_id": initialValue.d_id, "userType": initialValue.userType, "userid": initialValue.userid }) }
    getIoStats = () => { return postIotService('/cmachine/getMchCount', { "customer": initialValue.userid, "d_id": initialValue.d_id, "userType": initialValue.userType, "userid": initialValue.userid }) }
    getCtvmPerformanceStat = (data) => { return postIotService('/cmachine/ctvmPerFormance', data) }
    getMonthWisePerformance = (data) => { return postIotService('/cmachine/performanceStats', data) }
    getActiveUsers = () => { return getIotService('/cmachine/activeUsers') }
    activeCVESUsers = (data) => { return postIotService('/cmachine/activeCVESUsers', data) }
    getAccountWisePerformance = (data) => { return postIotService('/cmachine/accountWisePerformance', data) }
    getAllERPMachines = () => { return getErpService('/machine/machines') }
    getAppCategory = () => { return getErpService('/admin/getAppCat') }
    getDeviceTypes = () => { return getErpService('/admin/getDeviceType') }
    getDeviceModels = () => { return getErpService('/admin/getDeviceModel') }
    addAppCategory = (data) => { return postErpService('/admin/addAppCat', data) }
    deleteAppCategory = (data) => { return postErpService('/admin/deleteAppCat', data) }
    updateAppCategory = (data) => { return postErpService('/admin/updateAppCat', data) }
    addDeviceTypes = (data) => { return postErpService('/admin/addDeviceType', data) }
    deleteDeviceType = (data) => { return postErpService('/admin/deleteDeviceType', data) }
    addDeviceModel = (data) => { return postErpService('/admin/addDeviceModel', data) }
    updateDeviceModel = (data) => { return postErpService('/admin/updateDeviceModel', data) }
    deleteDeviceModel = (data) => { return postErpService('/admin/deleteDeviceModel', data) }
    addMachine = (data) => { return postErpService('/admin/addMachine', data) }
    updateMachine = (data) => { return postErpService('/admin/updateMachine', data) }
    deleteMachine = (data) => { return postErpService('/admin/deleteMachine', data) }
    getMaterialTypes = () => { return getErpService('/admin/materialTypes') }
    addMaterialType = (data) => { return postErpService('/admin/addMaterialType', data) }
    addMaterialBatch = (data) => { return postErpService('/admin/addMaterialBatch', data) }
    deleteMaterialBatch = (data) => { return postErpService('/admin/deleteMaterialBatch', data) }
    searchMaterialBatch = (data) => { return postErpService('/filters/searchMaterialBatch', data) }
    deleteMaterialType = (data) => { return postErpService('/admin/deleteMaterialType', data) }
    getBarcodesByBatch = (data) => { return postErpService('/filters/getBarcodesByBatch', data) }
    generateBarcodes = (data) => { return postErpService('/admin/generateBarcode', data) }
    updateMaterial = (data) => { return postErpService('/admin/updateMaterial', data) }
    removeMaterial = (data) => { return postErpService('/admin/removeMaterial', data) }
    materialDetails = (data) => { return postErpService('/inventory/barcode/details/', data) }
    updateMaterialType = (data) => { return postErpService('/admin/updateMaterialType', data) }
    updateMaterialBatch = (data) => { return postErpService('/admin/updateMaterialBatch', data) }
    switchBarcodes = (data) => { return postErpService('/admin/switchBarcodes', data) }
    switchDemoBarcodes = (data) => { return postErpService('/admin/switchDemoBarcodes', data) }
    updateBarcodeStage = (data) => { return postErpService('/admin/update/barcode/stage', data) }
    allocateBarcode = (data) => { return postErpService('/admin/update/warehouse/barcode/stage', data) }
    activeDemoUsers = () => { return getIotService('/cmachine/activeDemoUsers') }
    getLiveData = (data) => { return postIotService('/cmachine/getLiveData', data) }
    getCardConsumptionByDate = (data) => { return postIotService('/cmachine/getCardConsumptionByDate', data) }
    usageByLocation = (data) => { return postIotService('/cmachine/usageByLocation', { ...data, "userType": initialValue?.userType }) }
    addCapsuleMachine = (data1) => { return postIotService('/cmachine/addMachine', { ...data1, "customer": initialValue.userid }) }
    findCapsuleMachines = () => { return postIotService('/capsuleMachine/findCapsuleMachines', { "customer": initialValue.userid, "d_id": ["self"], "userType": initialValue.userType, "userid": initialValue.userid }) }
    newCapsuleMachineSite = (data) => { return postIotService('/capsuleMachine/newCapsuleMachineSite', data) }
    
    getMaterialBatch = (data) => { return getErpService(`/admin/getMaterialBatch?count=${data?.count}&index=${data?.index}`) }

    getProductionBatch = (data) => { 
        return getErpService(`/admin/production/batch/?count=${data?.count}&index=${data?.index}&chars=${data?.chars ? data?.chars: ''}`) }


    sendAgreement = (data) => { return getErpMediaService(`/sendAgreement?id=${data.id}&subid=${data.subid}&_id=${data._id}`, { responseType: 'arraybuffer' }, data) }
    sendNewAgreement = (data) => { return getErpMediaService(`/sendAgreement?_id=${data._id}`, { responseType: 'arraybuffer' }) }
    deleteAgreement = (data) => { return getErpMediaService(`/deleteAgreement?_id=${data._id}`) }
    getKycData = (data) => { return postErpService('/orderctvm/kettleKYC', data) }
    getRaisedTickets = (data) => { return postFlutterService('/api/support/getList', data) }
    getMchByAccount = (data) => { return postIotService('/cmachine/getMchArray', data) }
    getOrderList = (data) => { return postFlutterService('/api/ownership/plans/placed/order/list', data) }
    getKettleExcecution = (data) => { return postIotService('/iot/kettle/commands', data) }
    triggerRfidCards = (data) => { return postIotService('/cmachine/triggerRfidcards', data) }
    getProductMaster = (data) => { return postErpService('/erp/get/productmaster', data) }
    getBarcodeData = (data) => { return postErpService('/inventory/usedBarcode', data) }
    updateBackupData = (data) => { return postIotService('/cmachine/update/backup/data', data) }
    sendProductionBatchEmail = (data) => { return postErpService('/admin/production/batch/email', data) }
    getAllAppVersions = (data) => { return postIotService('/cmachine/get/app/version', data) }

    ////////////////////////////////////APP MANAGEMENT//////////////////////////////////

    getOwnershipPlan = (data) => { return postFlutterService(`/api/iot/ownership/plans`, data) };
    getDetailsofOwnerShip = (data) => { return postFlutterService(`/api/iot/ownership/plans/detail`, data) };
    updateOwnershipPlan = (data) => { return postFlutterServiceUpdate(`/api/iot/ownership/plans/update`, data) };
    createOwnershipPlan = (data) => { return postFlutterServiceUpdate(`/api/iot/ownership/plans/create`, data) };
    getRangeId = (data) => { return postFlutterService(`/api/admin/list/custom`, data) };
    deleteownershipPlan = (data) => { return postFlutterService(`/api/iot/ownership/plans/delete`, data) };
    getSubscriptionPack = (data) => { return postFlutterService(`/api/iot/subscription/plans`, data) };
    createSubscriptionPack = (data) => { return postFlutterServiceUpdate(`/api/iot/subscription/plans/create`, data) };
    updateSubscriptionPack = (data) => { return postFlutterServiceUpdate(`/api/iot/subscription/plans/update`, data) };
    deleteSubscriptionPack = (data) => { return postFlutterServiceUpdate(`/api/iot/subscription/plans/delete`, data) };
    getrefillslist = (data) => { return postFlutterService(`/api/iot/ownership/combos`, data) };
    detailsOfRefill = (data) => { return postFlutterService("/api/iot/ownership/combos/detail", data) };
    createRefillList = (data) => { return postFlutterServiceUpdate(`/api/iot/ownership/combos/create`, data) };
    updateRefillsList = (data) => { return postFlutterServiceUpdate(`/api/iot/ownership/combos/update`, data) };
    deleteRefillList = (data) => { return postFlutterServiceUpdate(`/api/iot/ownership/combos/delete`, data) };
    getPacksList = (data) => { return postFlutterService(`/api/iot/ownership/packs`, data) }
    getSinglePack = (data) => { return postFlutterService(`/api/iot/ownership/packs/detail`, data) };
    createPacksList = (data) => { return postFlutterServiceUpdate(`/api/iot/ownership/packs/create`, data) };
    updatePacksList = (data) => { return postFlutterServiceUpdate(`/api/iot/ownership/packs/update`, data) };
    deletePacksList = (data) => { return postFlutterServiceUpdate(`/api/iot/ownership/packs/delete`, data) };

    // FAQ
    addFaq = (data) => { return postFlutterService('/api/create/faq', data) }
    updateFaq = (data) => { return postFlutterService('/api/update/faq', data) }
    deleteFaq = (data) => { return postFlutterService('/api/delete/faq', data) }
    getFaq = (data) => { return postFlutterService('/api/get/faq', data) }

    // Training Videos
    getVideos = (data) => { return postFlutterService('/api/get/traning/videos', data) }
    addVideos = (data) => { return postFlutterService('/api/create/traning/videos', data) }
    updateVideos = (data) => { return postFlutterService('/api/update/traning/videos', data) }
    deleteVideo = (data) => { return postFlutterService('/api/delete/traning/videos', data) }

    // Cves Actions

    getCVESReports = (data) => { return postErpService('/erp/get/cves/report', data) }

    // CVES Monitor
    canisterUnloackData = (data) => { return postIotService('/iot/canister/unlock/graph', data) }
    getCanUnlockdata = (data) => { return postIotService('/iot/canister/unlock/find', data) }
    getLevelChartData = (data) => { return postIotService('/iot/level/calibrate/graph', data) }
    getDetailedLevel = (data) => { return postIotService('/iot/level/calibrate/find', data) }
    getSensorChartData = (data) => { return postIotService('/iot/kettle/sensors/graph', data) }
    getDetailedSensor = (data) => { return postIotService('/iot/kettle/sensors/find', data) }
    machineBarcodeData = (data) => { return postErpService('/inventory/dayWiseBarcodeUsed', data) }
    detailedBarcodeList = (data) => { return postErpService('/inventory/usedBarcodeList', data) }
    consumptionClicksData = (data) => { return postIotService('/iot/consumption/clicks/graph', data) }
    detailedClicksData = (data) => { return postIotService('/iot/consumption/clicks/find', data) }
    getHeaterChartData = (data) => { return postIotService('/iot/heater/maintainace/graph', data) }
    getDetailedHeater = (data) => { return postIotService('/iot/heater/maintainace/find', data) }


    // More
    getSalesTarget = (data) => { return postIotService('/iot/sales/target/find', data) }
    createSalesTarget = (data) => { return postIotService('/iot/sales/target/create', data) }
    getAllVersions = (data) => { return postIotService('/iot/get/versioninfo', data) }
    addVersionsData = (data) => { return postIotService('/iot/create/versioninfo', data) }
    updateVersionsData = (data) => { return postIotService('/iot/update/versioninfo', data) }
    deleteVersionsData = (data) => { return postIotService('/iot/delete/versioninfo', data) }

    // Support Knowledge base

    getIssueCategories = (data) => { return postIotService('/support/get/issueCategory', data) }
    addIssueCategory = (data) => { return postIotService('/support/create/issueCategory', data) }
    updateIssueCategory = (data) => { return postIotService('/support/update/issueCategory', data) }
    deleteIssueCategory = (data) => { return postIotService('/support/delete/issueCategory', data) }

    getContactDetails = (data) => { return postIotService('/support/get/contactDetails', data) }
    addContactDetails = (data) => { return postIotService('/support/create/contactDetails', data) }
    updateContactDetails = (data) => { return postIotService('/support/update/contactDetails', data) }
    deleteContactDetails = (data) => { return postIotService('/support/delete/contactDetails', data) }

    getEmailSample = (data) => { return postIotService('/support/get/emailsample', data) }
    addEmailSample = (data) => { return postIotService('/support/create/emailsample', data) }
    updateEmailSample = (data) => { return postIotService('/support/update/emailsample', data) }
    deleteEmailSample = (data) => { return postIotService('/support/delete/emailsample', data) }

    getRfidSetup = (data) => { return postIotService('/support/get/rfid/setup', data) }
    addRfidSetup = (data) => { return postIotService('/support/create/rfid/setup', data) }
    updateRfidSetup = (data) => { return postIotService('/support/update/rfid/setup', data) }

    getWifiConnect = (data) => { return postIotService('/support/get/wificonnect/process', data) }
    addWifiConnect = (data) => { return postIotService('/support/create/wificonnect/process', data) }
    updateWifiConnect = (data) => { return postIotService('/support/update/wificonnect/process', data) }
    deleteWifiConnect = (data) => { return postIotService('/support/delete/wificonnect/process', data) }


    allUsers = (data) => { return postErpService('/user/CVESUsers', data) }


    // End of Support base

    RegisterMachine = (data1) => {
        const data2 = { "assignedto": initialValue.userType, "customer": initialValue.userid, "d_id": initialValue.d_id[0] }
        return postIotService('/cmachine/registerMachine', { ...data1, ...data2 })
    }

    addBulkCtvm = (data1) => {
        const data2 = { "assignedto": initialValue.userType, "customer": initialValue.userid, "d_id": initialValue.d_id }
        return postIotService('/bulkMachines/addCTVM', { ...data1, ...data2 })
    }

    uploadAgreement = (data) => {
        const options = { headers: { "x-access-token": initialValue.idToken, "Content-Type": "multipart/form-data" } }
        return postErpService('/uploadAgreement', data, options)
    }

    registerCapsulemachine = (data1) => {
        const data2 = { "assignedto": "owner", "customer": initialValue.userid, "d_id": "self" }
        return postIotService('/capsuleMachine/registerCapsulemachine', { ...data1, ...data2 })
    }

    unregisterCapsuleMachine = (data1) => {
        const data2 = { "customer": initialValue.userid }
        return postIotService('/capsuleMachine/unregisterCapsuleMachine', { ...data1, ...data2 })
    }

    deleteCapsule = (data1) => {
        const data2 = { "customer": initialValue.userid, removeDemoUser: true, type: "machines" }
        return postIotService('/capsuleMachine/deleteCapsuleMachineSite', { ...data1, ...data2 })
    }

    getCapsules = () => {
        const data = { "customer": initialValue.userid, "d_id": ["self"], "userType": initialValue.userType, "userid": initialValue.userid }
        return postIotService('/capsuleMachine/findCapsuleMachines', data)
    }

    getMaterials = (data) => {

        try {

            if (data?.chars) {

                if (data?.materialName) {
                    return getErpService(`/filters/searchMaterials?count=${data.count}&index=${data.index}&chars=${data.chars}&materialName=${data.materialName}`);
                } else {
                    return getErpService(`/filters/searchMaterials?count=${data.count}&index=${data.index}&chars=${data.chars}`);
                }

            } else {

                if (data?.materialName && data?.barcodeStatus) {
                    return getErpService(`/admin/materials?count=${data.count}&index=${data.index}&materialName=${data?.materialName}&barcodeStatus=${data?.barcodeStatus}`);
                } else if (!data?.materialName && data?.barcodeStatus) {
                    return getErpService(`/admin/materials?count=${data.count}&index=${data.index}&barcodeStatus=${data?.barcodeStatus}`);
                } else if (data?.materialName && !data?.barcodeStatus) {
                    return getErpService(`/admin/materials?count=${data.count}&index=${data.index}&materialName=${data?.materialName}`);
                } else if (!data?.materialName && !data?.barcodeStatus) {
                    return getErpService(`/admin/materials?count=${data.count}&index=${data.index}`);
                }
            }

        } catch (error) {
            return error.response;
        }
    }

    getMachineForDropDown = async () => {

        const data = { "customer": initialValue.userid, "d_id": initialValue.d_id, "userType": initialValue.userType, "userid": initialValue.userid }

        try {
            const response = await postIotService('/cmachine/findMachines', data);
            const response1 = await getErpService('/user/usersReduced')

            const userred = response1?.data?.data?.map((x) => { return x.userid })
            let arr = []
            // response?.data?.data.map((x) => { if (userred.includes(x.customer)) { arr.push(x) } })
            response?.data?.data.forEach((x) => { if (userred.includes(x.customer)) { arr.push(x) } })
            const result = arr.filter((m) => { return m.siteName !== '' });

            const resultedMachine = result.map((x) => {
                // const customer = x.customer
                const subid = x.subid ? x.subid : 'CIPL';
                const address = response1.data.data.find(o => o.userid === x.customer)
                return {
                    ...x,
                    displayLabel: x.id + ' / ' + subid + ' / ' + address?.billing_details?.company,
                    address: address?.addr
                };
            });

            return resultedMachine;

        } catch (error) {
            return error.response;
        }
    }

    deleteCvesReport = async (data) => {
        // console.log("data ==>", data);
        return postErpService('/erp/delete/cves/report', { ...data })
    }


    // ====== Density Report ===========

    getDensityReport = (data) => { return postErpService('/erp/get/density/report', data) }

    sendDensityReport = (data) => { return getErpMediaService(`/erp/send/density/report?_id=${data._id}&token=${initialValue?.idToken}`, { responseType: 'arraybuffer', }) }

    uploadDensityReport = (data) => {
        const options = { headers: { "x-access-token": initialValue.idToken, "Content-Type": "multipart/form-data" } }
        return postErpService('/erp/upload/density/report', data, options)
    }

    deleteDensityReport = (data) => { return postErpService('/erp/delete/density/report', data) }

    CvesSettingUpdate = (data) => { return postIotService('/cmachine/update/cves/settings', data) }

    cvesUsers = (data) => { return postIotService('/cmachine/CVESUsers', data) }


    getVersionsList = () => { return getIotService('/admin/getVersion') }

    deleteCvesVersion = (data) => { return postIotService('/admin/deleteVersion', data) }

    uploadCvesVersion = (data, config) => {
        const options = { ...config, headers: { "x-access-token": initialValue.idToken, "Content-Type": "multipart/form-data" } }
        return postIotService('/admin/upload/version', data, options)
    }

    // =================Pending Commands==================

    getPendingCommands = (data) => {
        return postIotService('/cmachine/get/pending/commands', data);
    }

    updatePendingCommands = (data) => {
        return postIotService('/cmachine/update/pending/commands', data);
    }


    // New Analytics Page


    onboardedMachines = (data) => {
        const data1 = { "userType": initialValue?.userType, "userid": initialValue?.userid }
        const payload = data ? { ...data, ...data1 } : data1
        return postIotService('/iot/onboarded/cves/', payload)
    }

    totalCupConsumption = (data) => {
        const data1 = { "userType": initialValue?.userType, "userid": initialValue?.userid }
        const payload = data ? { ...data, ...data1 } : data1
        return postIotService('/iot/consumption/cves/', payload)
    }

    raiseRefundRequest = (data) => { return postIotService('/transaction/raise/refund', data)  }

    viewRaisedRequestDetails = (data) => {
        return postIotService('/transaction/view/refund/request', data);
    }

    // MIS PAGE

    getMISReport = (data = {}) => {
        return postIotService('/iot/get/mis/record', data)
    }

    createMISReport = (data = {}) => {
        return postIotService('/iot/create/mis/record', data)
    }


}
